import react, { Fragment, useEffect, useState } from "react"
import { Col, Button, Form, Modal } from 'react-bootstrap';
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { API_BASE_URL } from "../../constants/envConstants";
import S3_FILE_HELPER from "../../constants/S3FileUploadHelper";
import http from "../../helper/Interceptors"

const ServiceAppModal = ({ isModalOpen, onCloseModal }) => {

    const userInfo = useSelector((state) => state?.user?.userInfo)
    const appList = userInfo?.companies || []
    const [companyImages, setCompanyImages] = useState([])

    const { GET_ALL_IMAGES_PUBLIC_URL } = S3_FILE_HELPER()

    const handleUpdateAppName = (appInfo) => {
        const apiPath = API_BASE_URL + "apexrest/v1/user"
        const formData = {
            "current_company": appInfo?.company_id
        }
        http.post(apiPath, formData)?.then((res) => {
            if (res?.status === 200) {
                toast.success("App changed to " + appInfo?.company_name)
                setTimeout(() => {
                    window.location?.reload()
                }, 1000)
            }
        })?.catch((err) => {
            toast.error("failed to change app ")
        })
    }

    const getImagePath = async (companyId) => {
        const result = await GET_ALL_IMAGES_PUBLIC_URL(companyId)
        if (!!result && result.length > 0) return result[result?.length - 1]?.publicPath
    }

    useEffect(() => {
        // fetching images 
        let imagesArrUsers = [];
        appList.forEach(async (i) => {
            const res = await getImagePath(i?.company_id);
            let commentObj = {
                companyId: i?.company_id,
                publicPath: res
            }
            imagesArrUsers = [...imagesArrUsers, commentObj]
            setCompanyImages(imagesArrUsers)
        });

    }, [appList])

    return (
        <Fragment>
            <Modal show={isModalOpen} onHide={() => onCloseModal()}>
                <Modal.Header closeButton>
                    <Modal.Title as="h5">
                        Companies
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="px-3 pt-4 pb-5">
                    <div className="grid2">
                        {appList?.map((key) => {
                            let companyLogo = companyImages?.find((image) => image?.companyId === key?.company_id)?.publicPath
                            return (
                                <Fragment key={key?.company_id}>
                                    <div className={`serviceCard ${key?.company_id === userInfo?.current_company ? "active" : ""}`} onClick={() => handleUpdateAppName(key)}>
                                        <div className="imgBlock">
                                            <img src={!!companyLogo ? companyLogo : "https://www.market-research-companies.in//images/default.jpg"} />
                                        </div>
                                        <div className="contentBlock">
                                            <h6>
                                                {key?.company_name}

                                            </h6>
                                        </div>
                                    </div>
                                </Fragment>
                            )
                        })}
                    </div>
                </Modal.Body>


            </Modal>
        </Fragment>
    )
}

export default ServiceAppModal