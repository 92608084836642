import { CommaFormattedNumber, nFormatter } from "../pages/ProjectPage/projectConstants"
import AWS from 'aws-sdk';
import { S3_ACCESS_KEY, S3_REGION, S3_SECRET_ACCESS_KEY } from "./envConstants";

export const GOOGLE_MAP_KEY = "AIzaSyA0zGwl0eU6xj8uLCytl5DKV7tYvrG2iM4"
export const AUTH_CLIENT_EMAIL = "api@corporaterealestate.com"
export const AUTH_CLIENT_PASSWORD = "de741f1ccb00gbnlwhf9w86t9mxLJqqoswe5wFqki80vDc4P7u"
export const SELECT_PLACEHOLDER = "-- Select --"
export const CENCUS_API_KEY = "f0677a61128c2e306ba41bc157654cbbbbf557e0"
export const WEBSITE_URL = "https://app.hyprespace.com/"

export const changeURLLastString = (url, replaceKey) => {
    if (!url) return
    const splitUrl = url.split("/")
    const lastWordPos = url.split("/").length - 1
    const lastStr = splitUrl[lastWordPos]
    return url.replace(lastStr, replaceKey)
}
export const CHANGE_URL_STRING = (url, replaceKey, replaceValue) => {
    if (!url) return
    return url.replace(replaceKey, replaceValue)
}

export const getCookie = (cname) => {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export const setCookie = (cname, cvalue, exdays) => {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    //setting a domain name in cookie if project is running on dev or production
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/; secure;";
}

export const deleteCookie = (name) => {
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

export const ValidateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};


export const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];
export const monthShortNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];

export const DATE_FORMAT = (date) => {
    //day,month and year calculation
    try {
        const dateObj = new Date(date);
        const month = dateObj.getUTCMonth() + 1;
        const day = dateObj.getUTCDate();
        const year = dateObj.getUTCFullYear();

        //hour,minute and second calculation with am or pm
        let hours = dateObj.getHours();
        let minutes = dateObj.getMinutes();
        let minutesTwoDigit = minutes.toString().length === 1 ? "0" + minutes : minutes
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? 0 + minutes : minutes;

        const monthShort = monthShortNames[month - 1]
        const monthLong = monthNames[month - 1]
        const monthTwoDigit = month.toString().length === 1 ? "0" + month : month
        const dateTwoDigit = day.toString().length === 1 ? "0" + day : day

        return { day, dateTwoDigit, month, monthTwoDigit, year, hours, minutes, minutesTwoDigit, ampm, monthShort, monthLong }

    } catch {
        return {}
    }
}

export const API_DATE_FORMAT = (dateInfo) => {
    if (!dateInfo) return null
    const dateFormat = DATE_FORMAT(dateInfo)
    return (dateFormat?.year + "-" + dateFormat?.monthTwoDigit + "-" + dateFormat?.dateTwoDigit)

}

export const GET_LAT_LONG_FROM_ADDRESS = async (address) => {
    if (!window.google) return
    if (!address) return
    let lat = ""
    let long = ""
    var geocoder = new window.google.maps.Geocoder();
    await geocoder.geocode({ 'address': address }, function (results, status) {
        if (status == window.google.maps.GeocoderStatus.OK) {
            lat = results[0].geometry.location.lat();
            long = results[0].geometry.location.lng();
        }
    });
    return { lat, long }
}

export const arePointsNear = (checkPoint, centerPoint, km) => {
    var ky = 40000 / 360;
    var kx = Math.cos(Math.PI * centerPoint.lat / 180.0) * ky;
    var dx = Math.abs(centerPoint.lng - checkPoint.lng) * kx;
    var dy = Math.abs(centerPoint.lat - checkPoint.lat) * ky;
    return Math.sqrt(dx * dx + dy * dy) <= km;
}

// http://jsfiddle.net/zd1fsh2t/3/

export const getUserIPAddress = async () => {
    let ipAddress = ""
    await fetch('https://ipinfo.io/json?token=eb3e56a07dad1a')
        .then(response => response.json())
        .then(data => {
            ipAddress = data?.ip
        }).catch((e) => {
            console.error(e)
        });
    return ipAddress
}

export const getUserAgent = () => {
    let userAgent = navigator.userAgent;
    return userAgent;
    let browserName;
    if (userAgent.match(/chrome|chromium|crios/i)) {
        browserName = "chrome";
    } else if (userAgent.match(/firefox|fxios/i)) {
        browserName = "firefox";
    } else if (userAgent.match(/safari/i)) {
        browserName = "safari";
    } else if (userAgent.match(/opr\//i)) {
        browserName = "opera";
    } else if (userAgent.match(/edg/i)) {
        browserName = "edge";
    } else {
        browserName = "No browser detection";
    }
    return browserName
}

export const replaceSpecialCharacter = (str) => {
    return str.replace(/[^A-Z0-9]+/ig, "_");
}

export const fileToBase64 = async (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = (e) => reject(e)
    })

export const formatFileSize = (bytes, decimalPoint) => {
    if (!bytes) return '0 Bytes';
    var k = 1000,
        dm = decimalPoint || 2,
        sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const FORMATTED_DATE_UI = (dateInfo, timeFormat = false, yearTwoDigit = false) => {
    if (!dateInfo) return "";
    let dateFormat = DATE_FORMAT(dateInfo)
    let TimeFormat = dateFormat?.hours + ":" + dateFormat?.minutesTwoDigit + " " + dateFormat?.ampm

    if (!!timeFormat) return dateFormat?.monthTwoDigit + "/" + dateFormat?.dateTwoDigit + "/" + dateFormat?.year + " " + TimeFormat;
    if (!!yearTwoDigit) return dateFormat?.monthTwoDigit + "/" + dateFormat?.dateTwoDigit + "/" + dateFormat?.year?.toString().slice(2);
    return dateFormat?.monthTwoDigit + "/" + dateFormat?.dateTwoDigit + "/" + dateFormat?.year
}
export const GET_SUM_REDUCED_VALUE = (filteredResult, keyName, format = true) => {
    let value = filteredResult?.reduce((acc, curr) => {
        if (!curr) return 0
        let current = !!curr[keyName] ? curr[keyName] : 0
        return acc + current
    }, 0)
    if (!!format) return nFormatter(value)
    return value
}
export const formattedValue = (objInfo, numberCommaFormatted = false) => {
    let value = objInfo?.value || ""

    if (objInfo?.type === "DOUBLE" && ["Area_per_Seat__c", "Usable_Area__c", "Area__c", "Occupancy_Area__c"]?.includes(objInfo?.name)) {
        return value ? CommaFormattedNumber(value) : "na"
    }

    if (objInfo?.type === "PERCENT") return !!value ? parseFloat(value)?.toFixed(2) + "%" : "";

    if (objInfo?.type === "DATE") return FORMATTED_DATE_UI(value)
    if (objInfo?.type === "DOUBLE") {
        if (numberCommaFormatted) {
            return CommaFormattedNumber(value)
        } else {
            return nFormatter(value)
        }
    }
    if (objInfo?.type === "CURRENCY") {
        return CommaFormattedNumber(value)
    }
    return value
}

export const GROUP_BY_KEY = (list, key) => list.reduce((hash, obj) => ({ ...hash, [obj[key]]: (hash[obj[key]] || []).concat(obj) }), {})

export const GET_GRID_LIMIT_BY_SCREEN_HEIGHT = () => {
    let yHeight = window.screen.height;
    if (yHeight < 800) return 20
    if (yHeight > 800 & yHeight < 1100) return 10
    if (yHeight > 1100 & yHeight < 1900) return 20
    if (yHeight > 1900 & yHeight < 2900) return 30
    if (yHeight > 2900 & yHeight < 3500) return 40
    return 10
}

export const GET_DISTANCE_BASED_ON_LAT_LNG = (lat1, lon1, lat2, lon2, unit) => {
    //:::    lat1, lon1 = Latitude and Longitude of point 1 (in decimal degrees)  :::
    //:::    lat2, lon2 = Latitude and Longitude of point 2 (in decimal degrees)  :::
    //:::    unit = the unit you desire for results                               :::
    //:::           where: 'M' is statute miles (default)                         :::
    //:::                  'K' is kilometers                                      :::
    //:::                  'N' is nautical miles     
    var radlat1 = Math.PI * lat1 / 180
    var radlat2 = Math.PI * lat2 / 180
    var theta = lon1 - lon2
    var radtheta = Math.PI * theta / 180
    var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
        dist = 1;
    }
    dist = Math.acos(dist)
    dist = dist * 180 / Math.PI
    dist = dist * 60 * 1.1515
    if (unit == "K") { dist = dist * 1.609344 }
    if (unit == "N") { dist = dist * 0.8684 }
    return dist

}

export const GET_MAP_ZOOM_BASED_ON_RADIUS = (radius) => {
    let zoomLevel = 11;
    if (radius > 0) {
        let radiusElevated = radius + radius / 2;
        let scale = radiusElevated / 500;
        zoomLevel = 16 - Math.log(scale) / Math.log(2);
    }

    let finalZoom = Math.ceil((zoomLevel) / 1.4);

    if (finalZoom <= 0) return 1
    return finalZoom
}

export const HANDLE_LOGOUT_USER = () => {
    deleteCookie("Authorization")
    deleteCookie("SessionId")
    window && window.localStorage?.removeItem("user_info")
    window && window.localStorage?.removeItem("oldUrl")
    window.open(window.location.origin, "_self")
}

export const GET_DISTANT_MATRIX_VIA_MAP = (buildingRecords, employeeData) => {
    if (!window.google.maps) return
    let buildingAddress = buildingRecords?.map((key) => key?.Address__c)
    let uniquePostalCode = [...new Set(employeeData)]

    const service = new window.google.maps.DistanceMatrixService(); // instantiate Distance Matrix service
    const matrixOptions = {
        origins: uniquePostalCode, // technician locations
        destinations: buildingAddress, // customer address
        travelMode: 'DRIVING',
        unitSystem: window.google.maps.UnitSystem.IMPERIAL
    };
    // Call Distance Matrix service
    return service.getDistanceMatrix(matrixOptions, callback);

    // Callback function used to process Distance Matrix response
    function callback(response, status) {
        if (status !== "OK") {
            console.error("res", response, status)
            // alert("Error with distance matrix", response, status);
            return {};
        }
        return response
        // setDistantMetricsData(response)

    }

}

export const PRINT_METRIX_RESPONSE_ASYNC = async (response) => {
    const a = await response;
    return a
};

export const SECOND_TO_H_M_S = (d) => {
    // d is second
    let stringMinus = String(d)?.replace("-", "")
    d = Number(stringMinus);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? " H, " : " H, ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " min " : " mins ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " sec" : " sec") : "";
    return hDisplay + mDisplay + sDisplay
}
export const SECOND_TO_H_M_S_VALUE = (d) => {
    // d is second
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? " H, " : " H, ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " min " : " mins ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " S" : " S") : "";
    return { h, m, s }
}

export const GET_MILES = (meters, numberOnly = false) => {
    if (numberOnly) return (meters * 0.000621371192).toFixed(2)
    return ((meters * 0.000621371192).toFixed(2) + " mi");
}
export function uniqueBy(arr, key, $some = false) {
    if (key instanceof Array) {
        if ($some) {
            return key.reduce(uniqueBy, arr);
        } else {
            const fnUnique = (obj) =>
                key.reduce((a, k) => `${a}-${obj[k]}`, '');
            return Object.values(arr.reduce((a, v) => {
                const key = fnUnique(v)
                return a[key] === undefined ? (a[key] = v, a) : a;
            }, {}));
        }
    }
    return Object.values(arr.reduce((a, v) => (a[v[key]] === undefined ? (a[v[key]] = v, a) : a), {}));
}
export function spliceIntoChunks(arr, chunkSize) {
    const res = [];
    while (arr.length > 0) {
        const chunk = arr.splice(0, chunkSize);
        res.push(chunk);
    }
    return res;
}

export function DELAY(t, v) {
    return new Promise(resolve => setTimeout(resolve, t, v));
}


export const COUNT_BY = (arr, fn) =>
    arr.map(typeof fn === 'function' ? fn : val => val[fn]).reduce((acc, val) => {
        acc[val] = (acc[val] || 0) + 1;
        return acc;
    }, {});


export const GET_UNIQUE_OBJECTS_BY_ID = (objects) => {
    const uniqueObjects = [];
    objects.forEach((obj) => {
        const index = uniqueObjects.findIndex((o) => o.Id === obj.Id);

        if (index === -1) {
            uniqueObjects.push(obj);
        }
    });

    return uniqueObjects;
}

export const DETECT_FILE_TYPE = (url) => {
    if (!url) return "unknown"
    const fileExtensions = {
        image: ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff'],
        pdf: ['pdf'],
        csv: ['csv'],
        xlsx: ['xlsx'],
        ppt: ['ppt'],
        zip: ['zip'],
        doc: ['doc', 'docx']
    };

    const matches = url?.split(".")[url?.split(".").length - 1]

    if (matches) {
        const fileExtension = matches.toLowerCase();

        for (const type in fileExtensions) {
            if (fileExtensions[type].includes(fileExtension)) {
                return type;
            }
        }
    }

    return 'unknown';
}

export const CONVERT_ALL_FIELD_TO_SINGLE_OBJ = (fieldsArr = []) => {
    let fullInfo = fieldsArr.map((key) => {
        return {
            [key?.name]: key?.value
        }
    })
    let final = Object.assign({}, ...fullInfo)
    return final
}