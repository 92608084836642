export const SET_PROJECT_LIST_DATA = "SET_PROJECT_LIST_DATA"
export const SET_PROJECT_FILTERED_LIST_DATA = "SET_PROJECT_FILTERED_LIST_DATA"
export const SET_PROJECT_FILTER_STATE = "SET_PROJECT_FILTER_STATE"
export const SAVE_KENDO_GRAPH_CHART = "SAVE_KENDO_GRAPH_CHART"
export const STREET_VIEW_SHOW = "STREET_VIEW_SHOW"
export const SET_PROJECT_VIEW_TYPE = "SET_PROJECT_VIEW_TYPE"
export const CLEAR_ALL_SLIDER_VALUE = "CLEAR_ALL_SLIDER_VALUE"
export const SET_SLIDER_VALUE_FIRST = "SET_SLIDER_VALUE_FIRST"
export const SET_SLIDER_VALUE_SECOND = "SET_SLIDER_VALUE_SECOND"
export const SET_SLIDER_VALUE_THIRD = "SET_SLIDER_VALUE_THIRD"
export const SET_GLOBAL_SLIDER_VALUE = "SET_GLOBAL_SLIDER_VALUE"
export const STREET_VIEW_SHOW_HIDE = "STREET_VIEW_SHOW_HIDE"
export const OPEN_MAP_VIEW_INSIDE_LIST = "OPEN_MAP_VIEW_INSIDE_LIST"
export const SET_CLUSTER_SEARCHED_POINTS = "SET_CLUSTER_SEARCHED_POINTS"
export const CHANGE_OLD_ACTIVE_PLACE_TYPE = "CHANGE_OLD_ACTIVE_PLACE_TYPE"
export const SET_CUSTOM_FILTERS_OPTIONS = "SET_CUSTOM_FILTERS_OPTIONS"
export const SET_ADVANCED_FILTERS_OPTIONS = "SET_ADVANCED_FILTERS_OPTIONS"
export const IS_STREET_VIEW_AVAILABLE = "IS_STREET_VIEW_AVAILABLE"
export const EXCEL_EXPORT_LIST = "EXCEL_EXPORT_LIST"
export const IS_MAP_CLUSTER_VISIBLE = "IS_MAP_CLUSTER_VISIBLE"
export const SET_PLACE_TYPE = "SET_PLACE_TYPE"
export const SET_ADD_NEW_CHART = "SET_ADD_NEW_CHART"
export const RELOAD_SPREADSHEET_DATA = "RELOAD_SPREADSHEET_DATA"
export const CHANGE_SPREADSHEET_DATA_ARR = "CHANGE_SPREADSHEET_DATA_ARR"
export const SET_USER_FILTERED_LIST = "SET_USER_FILTERED_LIST"
export const SET_ACTIVE_USER_FILTER_DROPDOWN = "SET_ACTIVE_USER_FILTER_DROPDOWN"
export const TOGGLE_FILTER_SLIDER = "TOGGLE_FILTER_SLIDER"
export const SET_ACTIVE_COMMENT_ID = "SET_ACTIVE_COMMENT_ID"
export const SET_ACTIVE_CLAUSE_INFO = "SET_ACTIVE_CLAUSE_INFO"

export const CREATE_PROJECT_MODAL = "CREATE_PROJECT_MODAL"
export const OPEN_SURVEY_TEMPLATE_MODAL = "OPEN_SURVEY_TEMPLATE_MODAL"
export const OPEN_REQUIREMENT_SURVEY_MODAL = "OPEN_REQUIREMENT_SURVEY_MODAL"
export const OPEN_SITE_SELECTION_MODAL = "OPEN_SITE_SELECTION_MODAL"
export const OPEN_GANTT_CHART_LIST_VIEW = "OPEN_GANTT_CHART_LIST_VIEW"