import { KEY_DATE_MAP_VIEW_OPEN, OPEN_EDIT_KEY_DATES_MODAL, SET_FILTERED_KEY_DATES_LIST_DATA, SET_KEY_DATES_CUSTOM_FILTERS, SET_KEY_DATES_LIST_DATA, SET_KEY_DATES_TOGGLE_FILTER_SLIDER } from "../action-types/keyDatesAction.types"

const initialState = {
    listData: [], // original without modified list data array
    filteredListResult: [], // after filter apply list data result
    customFilters: {}, //selected custom filter 
    toggleFilterSlider: false, // open and hide filter slider
    editKeyDateModal: {},
    keyDateMapView: {}
}

const keyDatesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_KEY_DATES_LIST_DATA:
            return {
                ...state,
                listData: action.data
            }
        case SET_FILTERED_KEY_DATES_LIST_DATA:
            return {
                ...state,
                filteredListResult: action.data
            }

        case SET_KEY_DATES_CUSTOM_FILTERS:
            return {
                ...state,
                customFilters: action.customFilters
            }

        case SET_KEY_DATES_TOGGLE_FILTER_SLIDER:
            return {
                ...state,
                toggleFilterSlider: action?.isActive
            }
        case OPEN_EDIT_KEY_DATES_MODAL:
            return {
                ...state,
                editKeyDateModal: action?.objInfo
            }
        case KEY_DATE_MAP_VIEW_OPEN:
            return {
                ...state,
                keyDateMapView: action?.data
            }

        default:
            return state
    }

}

export default keyDatesReducer