import { Checkbox, Drawer, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, Skeleton } from '@mui/material';
import React, { useContext, Fragment, useState, useEffect } from 'react';
import { Card, Collapse, Tab, Tabs } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { API_BASE_URL, API_FETCH_COMPLETE, API_FETCH_START } from '../../constants/envConstants';
import { ConfigContext } from '../../contexts/ConfigContext';
import { ToggleFilterSlider } from '../../redux/actions/dataGridActions';
import http from "../../helper/Interceptors"
import { GET_FIELD_INFO_BY_URL } from '../../pages/DynamicDataGrid/KendoGridConstants';
import image2 from "../../assets/images/profilePictures/2.jpg"

import avatar3 from '../../assets/images/user/avatar-3.jpg';
import ProjectCommentView from '../../pages/ProjectDetail/CommentView';
import { API_DATE_FORMAT, FORMATTED_DATE_UI } from '../../constants/commonFunc';
import S3_FILE_HELPER from '../../constants/S3FileUploadHelper';
import DataNotFoundMessage from './kendo/DataNotFound';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export const ALL_COMMENTS = "All";
export const SENDER_COMMENT = "Sender"
export const PROJECT_COMMENTS = "Project"
export const BUILDING_COMMENTS = "Building"
export const LEASE_COMMENTS = "Lease"
export const KEY_DATE_COMMENTS = "Key Date"
export const STRATEGIC_COMMENTS = "Strategic Plan"
export const COMMENT_BY_TYPE = "Type"
export const COMMENT_BY_DATE = "Date"
export const REQUIREMENTS_COMMENTS = "Requirements"
export const SITE_SELECTION_COMMENTS = "Site Selection"
export const FINANCIAL_ANALYSIS_COMMENTS = "Financial Analysis"
export const GC_BIDS_COMMENTS = "GC Bids"
export const GOAL_COMMENTS = "Target"

const names = [
    ALL_COMMENTS,
    COMMENT_BY_TYPE,
    COMMENT_BY_DATE,
    SENDER_COMMENT,
    PROJECT_COMMENTS,
    BUILDING_COMMENTS,
    LEASE_COMMENTS,
    KEY_DATE_COMMENTS,
    STRATEGIC_COMMENTS,
    REQUIREMENTS_COMMENTS,
    SITE_SELECTION_COMMENTS,
    FINANCIAL_ANALYSIS_COMMENTS,
    GC_BIDS_COMMENTS
];

const CommentWidgetSidebar = ({ isOpen, onClose, groupBy = ALL_COMMENTS, showDefault = true, projectId = "" }) => {
    const configContext = useContext(ConfigContext);
    const { dispatch } = configContext;
    const dispatchRedux = useDispatch()
    const toggleFilterSlider = useSelector((state) => state?.kendoDataGrid?.toggleFilterSlider)
    const currentUser = useSelector((state) => state?.user?.userInfo)

    const [accordionKey, setAccordionKey] = useState(!!showDefault ? 1 : null)

    const [searchedComments, setSearchedComments] = useState("")
    const [groupComment, setGroupComment] = useState([groupBy])
    const [comments, setComments] = useState([])
    const [filteredComments, setFilteredComments] = useState([])
    const [loading, setLoading] = useState(API_FETCH_START)
    const [apiReload, setApiReload] = useState(false)
    const { GET_ALL_IMAGES_PUBLIC_URL } = S3_FILE_HELPER()
    const userInfo = useSelector((state) => state?.user?.userInfo)
    const currentCompany = userInfo?.current_company;
    const [commentUsers, setCommentUsers] = useState([])



    const ToggleSlider = (isActive) => {
        dispatchRedux(ToggleFilterSlider(isActive))
    }

    const groupByKey = (list, key) => list.reduce((hash, obj) => ({ ...hash, [obj[key]]: (hash[obj[key]] || []).concat(obj) }), {})

    const getAllComments = () => {
        setLoading(API_FETCH_START)
        let apiPath = "";
        if (!!projectId) {
            apiPath = API_BASE_URL + "apexrest/v1/records?p1=Target_Record_Id__c&object=Comment__c&v1=" + (!!projectId ? projectId : "") + "&order_by=CreatedDate desc";
        } else {
            apiPath = API_BASE_URL + "apexrest/v1/records?object=Comment__c&order_by=CreatedDate desc"
        }
        http.get(apiPath).then((res) => {
            if (res?.status === 200) {
                setComments(res?.data)
            }
        }).catch((err) => {
            console.error("failed to load comments", err)

        }).finally(() => {
            setLoading(API_FETCH_COMPLETE)
        })
    }

    const handleFilteredSearchResult = () => {
        let finalArr = comments

        if (!groupComment || groupComment.length === 0) {
            finalArr = {
                "DEFAULT": comments
            }
        }
        if (!!groupComment && groupComment.length > 0) {
            if (groupComment.includes(ALL_COMMENTS)) {

                finalArr = {
                    "DEFAULT": comments
                }
            } else {
                if (groupComment.includes(COMMENT_BY_TYPE)) {
                    finalArr = groupByKey(comments, "Target_Record_Object_API_Name__c")
                }
                if (groupComment.includes(COMMENT_BY_DATE)) {

                    const groups = comments?.reverse().reduce((groups, game) => {
                        const date = game.CreatedDate.split('T')[0];
                        if (!groups[date]) {
                            groups[date] = [];
                        }
                        groups[date].push(game);
                        return groups;
                    }, {});

                    let commentsByDate = Object.keys(groups).map((date) => {
                        return {
                            [date]: groups[date]
                        };
                    });

                    finalArr = Object.assign(...commentsByDate, {})
                    console.log("final arr date", finalArr)

                }
                if (groupComment.includes(SENDER_COMMENT)) {
                    finalArr = groupByKey(comments, "User__c")
                }
                if (groupComment.includes(PROJECT_COMMENTS)) {
                    let filtered = comments?.filter((key) => key?.Target_Record_Object_API_Name__c === "Project__c")
                    let groupedData = groupByKey(filtered, "Target_Record_Name__c")
                    finalArr = groupedData
                }
                if (groupComment.includes(BUILDING_COMMENTS)) {
                    let filtered = comments?.filter((key) => key?.Target_Record_Object_API_Name__c === "Building__c")
                    let groupedData = groupByKey(filtered, "Target_Record_Name__c")
                    finalArr = groupedData
                }
                if (groupComment.includes(LEASE_COMMENTS)) {
                    let filtered = comments?.filter((key) => key?.Target_Record_Object_API_Name__c === "Lease__c")
                    let groupedData = groupByKey(filtered, "Target_Record_Name__c")
                    finalArr = groupedData
                }
                if (groupComment?.includes("Strategic Plan")) {
                    let filtered = comments?.filter((key) => key?.Target_Record_Object_API_Name__c === "Strategic_Plan__c")
                    let groupedData = groupByKey(filtered, "Target_Record_Name__c")
                    finalArr = groupedData
                }
                if (groupComment?.includes("Key Date")) {
                    let filtered = comments?.filter((key) => key?.Target_Record_Object_API_Name__c === "Key_Date__c")
                    let groupedData = groupByKey(filtered, "Target_Record_Name__c")
                    finalArr = groupedData
                }
                if (groupComment?.includes(REQUIREMENTS_COMMENTS)) {
                    let filtered = comments?.filter((key) => key?.Target_Record_Object_API_Name__c === "Requirement_Survey__c")
                    let groupedData = groupByKey(filtered, "Target_Record_Name__c")
                    finalArr = groupedData
                }
                if (groupComment?.includes(SITE_SELECTION_COMMENTS)) {
                    let filtered = comments?.filter((key) => key?.Target_Record_Object_API_Name__c === "Landlord_Survey__c")
                    let groupedData = groupByKey(filtered, "Target_Record_Name__c")
                    finalArr = groupedData
                }
                if (groupComment?.includes(GOAL_COMMENTS)) {
                    let filtered = comments?.filter((key) => key?.Target_Record_Object_API_Name__c === "Target__c")
                    let groupedData = groupByKey(filtered, "Target_Record_Name__c")
                    finalArr = groupedData
                }
                // if (groupComment?.includes(GC_BIDS_COMMENTS)) {
                //     let filtered = comments?.filter((key) => key?.Target_Record_Object_API_Name__c === "Key_Date__c")
                //     let groupedData = groupByKey(filtered, "Target_Record_Name__c")
                //     finalArr = groupedData
                // }

            }

        }

        if (!!searchedComments) {
            let searchedArr = comments?.filter((key) => key?.Body__c?.toLowerCase().indexOf(searchedComments?.toLowerCase()) !== -1)
            finalArr = groupByKey(searchedArr, "Target_Record_Name__c")
        }

        // if (currentUser?.current_company !== "a0vDp000000kyoWIAQ") {
        //     finalArr = []
        // }

        console.log("final search", finalArr)

        setFilteredComments(finalArr)
    }




    const getImagePath = async (comment) => {
        let userId = comment?.User__c
        if (!userId) return image2
        const result = await GET_ALL_IMAGES_PUBLIC_URL(currentCompany + "/" + userId)
        if (!!result && result.length > 0) return result[0]?.publicPath
        return image2
    }


    useEffect(() => {

        let imagesArrUsers = [];

        comments.forEach(async (i) => {
            const res = await getImagePath(i);
            let commentObj = {
                userId: i?.User__c,
                publicPath: res
            }
            imagesArrUsers = [...imagesArrUsers, commentObj]
            setCommentUsers(imagesArrUsers)
        });

    }, [comments])


    useEffect(() => {
        if (isOpen) {
            getAllComments()
        }
    }, [isOpen])

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setGroupComment(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };


    const getGroupLabel = (keyName) => {

        if (!keyName || keyName === "undefined") return "Guest"
        if (keyName === "DEFAULT") return "All"
        if (keyName === "Project") return "Projects"
        if (keyName === "Building") return "Building"
        if (keyName === "Lease") return "Lease"

        if (groupComment?.includes(COMMENT_BY_DATE) && !searchedComments) {
            return !!keyName ? FORMATTED_DATE_UI(keyName) : "NA"
        }
        if (groupComment?.includes(COMMENT_BY_TYPE) && !searchedComments) {
            return keyName?.replace("__c", "")?.replace("_", " ")
        }
        if (groupComment?.includes(SENDER_COMMENT) && !searchedComments) {
            return comments?.find((key) => key?.User__c === keyName)?.User_Full_Name__c
        }
        if (keyName) {
            return comments?.find((key) => key?.Target_Record_Name__c === keyName)?.Target_Record_Name__c
        }
    }

    useEffect(() => {
        handleFilteredSearchResult()
    }, [searchedComments, groupComment, comments])

    useEffect(() => {

        setGroupComment([groupBy])
    }, [groupBy])

    useEffect(() => {
        if (apiReload) {
            getAllComments()
        }

    }, [apiReload])

    const getColorCode = (value) => {
        return ""
        if (value === 'Building__c') {
            return 'orangeColor'
        }
        else if (value === 'Lease__c') {
            return 'blueColor'
        }
        else if (value === 'Key_Date__c') {
            return 'greenColor'
        }
        else if (value === 'Project__c') {
            return 'yellowColor'
        }
        else if (value === '') {
            return 'blackColor'
        }
    }
    const getImageBykey = (value) => {
        if (value === 'Building__c') {
            return 'icon-grid orange'
        }
        else if (value === 'Lease__c') {
            return 'icon-file-text blue'
        }
        else if (value === 'Key_Date__c') {
            return 'icon-calendar green'
        }
        else if (value === 'Project__c') {
            return 'icon-briefcase yellow'
        }
        else if (value === 'budget__c') {
            return 'icon-package black'
        }
    }

    return (
        <Fragment>

            <div className="style-block" >
                <div className="pane-content p-0">
                    {!projectId &&
                        <div className='search-fields mb-4 d-flex gap-10  p-3 pb-0 pt-0'>
                            <input className='form-control' placeholder='Search'
                                value={searchedComments}
                                onChange={(e) => setSearchedComments(e?.target?.value)}
                                style={{ height: "38px" }}
                            />
                            <FormControl fullWidth>
                                <InputLabel id="demo-multiple-checkbox-label">Group by</InputLabel>
                                <Select
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    value={groupComment}
                                    onChange={handleChange}
                                    input={<OutlinedInput label="Group by" />}
                                    renderValue={(selected) => selected.join(', ')}
                                    MenuProps={MenuProps}
                                    style={{ maxHeight: 280 }}

                                >
                                    {names.map((name) => (
                                        <MenuItem key={name} value={name}>
                                            <ListItemText primary={name} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    }

                    <Fragment>
                        <div className="task-comment" style={{ maxHeight: 500 }}>
                            <ul className="media-list p-0">


                                {!!projectId &&
                                    <ProjectCommentView projectId={projectId} onCommentAdd={() => setApiReload(true)} />
                                }

                                {(!!filteredComments && Object.keys(filteredComments).length > 0) &&
                                    <Fragment>
                                        {Object.keys(filteredComments).map((key, keyIndex) => {
                                            let objIndex = keyIndex + 1
                                            const commentsList = filteredComments[key]
                                            return (
                                                <Fragment>
                                                    {commentsList?.length > 0 &&
                                                        <Card className="mt-0 mb-3">
                                                            <Card.Header>
                                                                <Card.Title as="h5">
                                                                    <Link
                                                                        to="#"
                                                                        onClick={() => setAccordionKey(accordionKey !== objIndex ? objIndex : 0)}
                                                                        aria-controls={`accordin${objIndex}`}
                                                                        aria-expanded={accordionKey === objIndex}
                                                                    >
                                                                        {getGroupLabel(key)}
                                                                    </Link>
                                                                </Card.Title>
                                                            </Card.Header>
                                                            <Collapse in={accordionKey === objIndex}>
                                                                <div id={`accordin${objIndex}`}>
                                                                    <Card.Body className='pb-0 commentBlock'>
                                                                        {commentsList?.length > 0 &&
                                                                            <Card.Text className='mb-0'>
                                                                                <div className='commentsTableGrid'>
                                                                                    <div>
                                                                                        <h6>Type</h6></div>
                                                                                    <div><h6>Record</h6></div>
                                                                                    <div><h6>Comment</h6></div>
                                                                                    <div><h6>By</h6></div>
                                                                                    <div><h6>On</h6></div>
                                                                                </div>

                                                                                {commentsList?.length > 0 && commentsList?.map((comment) => {
                                                                                    const commentUserInfo = comment?.Body__c ? comment?.Body__c?.split("</p>") : comment?.Body__c
                                                                                    if (commentUserInfo.length === 0) return ""
                                                                                    var userImage = commentUsers?.find((key) => key?.userId === comment?.User__c)?.publicPath
                                                                                    return (

                                                                                        <Fragment key={comment?.comment_id}>

                                                                                            <div className='commentsTableGrid' style={{ borderBottom: "1px solid #dedede" }}>

                                                                                                <div className='commentSection'>
                                                                                                    {comment?.Target_Record_Object_Name__c}
                                                                                                </div>

                                                                                                <div className='commentSection'>
                                                                                                    <a href={`/${GET_FIELD_INFO_BY_URL(comment?.Target_Record_Object_API_Name__c)?.detailUrl}/${comment?.Target_Record_Id__c}`}
                                                                                                        target="_blank"
                                                                                                        rel="noreferrer"
                                                                                                    >
                                                                                                        {comment?.Target_Record_Name__c}
                                                                                                    </a>

                                                                                                </div>

                                                                                                <div className='commentSection'>
                                                                                                    <p className='text-gray textFix gridCommentText' dangerouslySetInnerHTML={{ __html: commentUserInfo[0] }} />
                                                                                                </div>

                                                                                                <div className='commentSection'>
                                                                                                    <img className="media-object img-radius comment-img"
                                                                                                        src={!!userImage ? userImage : image2} alt="profile"
                                                                                                        style={{ height: 40, width: 40, marginRight: 15 }} />

                                                                                                    {comment?.User_Full_Name__c}
                                                                                                </div>
                                                                                                <div className='commentSection'>
                                                                                                    {FORMATTED_DATE_UI(comment?.CreatedDate, true)}
                                                                                                </div>

                                                                                            </div>



                                                                                        </Fragment>
                                                                                    )
                                                                                })}



                                                                            </Card.Text>
                                                                        }

                                                                    </Card.Body>
                                                                </div>
                                                            </Collapse>
                                                        </Card>
                                                    }


                                                    {commentsList?.length === 0 && loading === API_FETCH_COMPLETE &&
                                                        <DataNotFoundMessage message={"No comments found"} />
                                                    }
                                                </Fragment>


                                            )
                                        })}
                                    </Fragment>
                                }


                                {((loading === API_FETCH_COMPLETE) && !!filteredComments && Object.keys(filteredComments).length === 0) &&
                                    <h5 className="media-heading txt-primary text-center mt-5">
                                        No comments found
                                    </h5>
                                }

                                {loading === API_FETCH_START &&
                                    <div className="col-md-12">
                                        <Skeleton height={50} />
                                        <Skeleton height={50} />
                                        <Skeleton height={50} />
                                    </div>
                                }


                            </ul>


                        </div>
                    </Fragment>
                </div>
            </div>


        </Fragment >
    );
};

export default CommentWidgetSidebar;
