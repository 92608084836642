import { DATA_GRID_DEFAULT_STATE } from "../../constants/kendoDataGridConstants"
import { SET_ACTIVE_USER_FILTER_DROPDOWN, SET_LEASES_ADVANCED_FILTERS_OPTIONS, SET_LEASES_CUSTOM_FILTER_OPTION, SET_LEASES_FILTERED_LIST_DATA, SET_LEASES_FILTER_STATE, SET_LEASES_LIST_DATA, SET_USER_FILTERED_LIST } from "../action-types/leasesAction.types"

const initialState = {
    leasesList: [],
    filteredListResult: [],
    filterState: DATA_GRID_DEFAULT_STATE,
    customFilters: {},
    advancedFilters: {
        logic: 'and',
        filters: []
    },
    activeUserFilterDrodpdown: "",
    toggleFilterSlider: false,
    userSavedFilterList: []
}

const leasesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_LEASES_LIST_DATA:
            return {
                ...state,
                leasesList: action.data
            }
        case SET_LEASES_FILTERED_LIST_DATA:
            return {
                ...state,
                filteredListResult: action.data
            }

        case SET_LEASES_FILTER_STATE:
            return {
                ...state,
                filterState: action.filterState
            }
        case SET_LEASES_CUSTOM_FILTER_OPTION:
            return {
                ...state,
                customFilters: action.customFilters
            }
        case SET_LEASES_ADVANCED_FILTERS_OPTIONS:
            return {
                ...state,
                advancedFilters: action.advanceFilter
            }
        case SET_ACTIVE_USER_FILTER_DROPDOWN:
            return {
                ...state,
                activeUserFilterDrodpdown: action?.dropdownValue
            }
        case SET_USER_FILTERED_LIST:
            return {
                ...state,
                userSavedFilterList: action?.arr
            }

        // case EXCEL_EXPORT_LIST:
        //     return {
        //         ...state,
        //         excelExport: action.isExport
        //     }

        // case SET_USER_FILTERED_LIST:
        //     return {
        //         ...state,
        //         userSavedFilterList: action?.arr
        //     }
        // case SET_ACTIVE_USER_FILTER_DROPDOWN:
        //     return {
        //         ...state,
        //         activeUserFilterDrodpdown: action?.dropdownValue
        //     }
        // case TOGGLE_FILTER_SLIDER:
        //     return {
        //         ...state,
        //         toggleFilterSlider: action?.isActive
        //     }

        default:
            return state
    }

}

export default leasesReducer