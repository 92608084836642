import { combineReducers } from '@reduxjs/toolkit';
import { reducer as formReducer } from 'redux-form';
import importReducer from '../redux/reducer/ImportReducer';
import userReducer from '../redux/reducer/loginReducer';
import projectReducer from '../redux/reducer/ProjectReducer';
import { globalSearchReducer } from '../redux/reducer/globalSearchReducer';
import projectDetailReducer from '../redux/reducer/ProjectDetailReducer';
import leasesReducer from '../redux/reducer/leasesReducer';
import buildingReducer from '../redux/reducer/buildingReducer';
import expenseReducer from '../redux/reducer/expensesReducer';
import fileReducer from '../redux/reducer/fileReducer';
import chartDetailReducer from '../redux/reducer/chartDetailReducer';
import userNotificationReducer from '../redux/reducer/userNotificationReducer';
import kendoDataGridReducer from '../redux/reducer/dataGridReducer';
import keyDatesReducer from '../redux/reducer/keyDatesReducer';
import budgetTrackingReducer from '../redux/reducer/budgetTrackingReducer';
import compositeReducer from '../redux/reducer/compositeReducer';
import distanceMatrix from '../redux/reducer/distanceMatrixReducer';
import commonReducer from '../redux/reducer/CommonReducer';
import strategyReducer from '../redux/reducer/strategyReducer';

const reducers = combineReducers({
  form: formReducer,
  user: userReducer,
  project: projectReducer,
  leases: leasesReducer,
  buildings: buildingReducer,
  expenses: expenseReducer,
  files: fileReducer,
  importWizard: importReducer,
  globalSearch: globalSearchReducer,
  projectDetail: projectDetailReducer,
  chartDetail: chartDetailReducer,
  notification: userNotificationReducer,
  kendoDataGrid: kendoDataGridReducer,
  keyDates: keyDatesReducer,
  budgetTracking: budgetTrackingReducer,
  compositeView: compositeReducer,
  distanceMatrix: distanceMatrix,
  global: commonReducer,
  strategy: strategyReducer
});

export default reducers;
