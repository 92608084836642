import { EXCEL_EXPORT_DATA_GRID, MULTIPLE_EDIT_ENABLE_DATA_GRID, RELOAD_API_DATA_GRID, SET_DATA_GRID_ADVANCED_FILTERS, SET_DATA_GRID_CUSTOM_FILTERS, SET_DATA_GRID_FILTER_STATE, SET_DATA_GRID_LIST_DATA, SET_DATA_GRID_TOGGLE_FILTER_SLIDER, SET_DATA_GRID_USER_FILTERED_LIST, SET_FIELD_COLUMNS_OPTIONS_LIST, SET_FILTERED_DATA_GRID_LIST_DATA, SET_GRID_ACTIVE_USER_FILTER_DROPDOWN, SET_SAVED_SCHEMA_BY_USER, SET_SCHEMA_DUAL_LIST_FILTER_MODAL, SET_SCHEMA_DUAL_LIST_ORDER_MODAL, SET_VIEWABLE_LIST_COLUMNS } from "../action-types/dataGridAction.types";

export const SetFieldColumnsOptionsList = (data) => {
    return {
        type: SET_FIELD_COLUMNS_OPTIONS_LIST,
        data
    }
}

export const SetDataGridListDataAction = (data) => {
    return {
        type: SET_DATA_GRID_LIST_DATA,
        data
    }
}

export const SetFilteredDataGridListDataAction = (data) => {
    return {
        type: SET_FILTERED_DATA_GRID_LIST_DATA,
        data
    }
}

export const SetDataGridFilterStateAction = (filterState) => {
    return {
        type: SET_DATA_GRID_FILTER_STATE,
        filterState
    }
}

export const SetDataGridCustomFilters = (customFilters) => {
    return {
        type: SET_DATA_GRID_CUSTOM_FILTERS,
        customFilters
    }
}

export const SetDataGridAdvancedFilters = (advancedFilters) => {
    return {
        type: SET_DATA_GRID_ADVANCED_FILTERS,
        advancedFilters
    }
}
export const ToggleFilterSlider = (isActive) => {
    return {
        type: SET_DATA_GRID_TOGGLE_FILTER_SLIDER,
        isActive
    }
}
export const SetUserFiltersList = (arr) => {
    return {
        type: SET_DATA_GRID_USER_FILTERED_LIST,
        arr
    }
}

export const SetActiveUserFilterDropDown = (dropdownValue) => {
    return {
        type: SET_GRID_ACTIVE_USER_FILTER_DROPDOWN,
        dropdownValue
    }
}

export const CreateExcelSheetFromList = (isExport) => {
    return {
        type: EXCEL_EXPORT_DATA_GRID,
        isExport
    }
}

export const MultipleEditEnableAction = (isActive) => {
    return {
        type: MULTIPLE_EDIT_ENABLE_DATA_GRID,
        isActive
    }
}

export const ReloadApiDataList = (isReload) => {
    return {
        type: RELOAD_API_DATA_GRID,
        isReload
    }
}
export const SetSavedSchemaByUserList = (data) => {
    return {
        type: SET_SAVED_SCHEMA_BY_USER,
        data
    }
}

export const SetDataGridDualListOrderModal = (isModalOpen) => {
    return {
        type: SET_SCHEMA_DUAL_LIST_ORDER_MODAL,
        isModalOpen
    }
}

export const SetDataGridFilterOrderModal = (isModalOpen) => {
    return {
        type: SET_SCHEMA_DUAL_LIST_FILTER_MODAL,
        isModalOpen
    }
}

export const SetViewableListColumns = (data) => {
    return {
        type: SET_VIEWABLE_LIST_COLUMNS,
        data
    }
}