import {SET_GLOBAL_SEARCH_RESULTS} from "../action-types/gloabslSearchAction.types";

const initialState = {
    searchResults: undefined
}

export  const globalSearchReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_GLOBAL_SEARCH_RESULTS:
            return {
                ...state,
                searchResults: action.payload,
            }
        default:
            return state
    }
}

export default globalSearchReducer
