import { Fragment, useEffect, useMemo, useRef, useState } from "react"

import { toast } from "react-toastify";

import avatar1 from '../../assets/images/user/avatar-1.jpg';
import avatar2 from '../../assets/images/user/avatar-2.jpg';
import avatar3 from '../../assets/images/user/avatar-3.jpg';
import { GET_ALL_PROJECT_COMMENTS_BY_ID, SAVE_PROJECT_COMMENTS_BY_ID } from "../../constants/apiPaths";
import { changeURLLastString } from "../../constants/commonFunc";
import { API_BASE_URL, API_FETCH_COMPLETE, API_FETCH_START, RESTRICTED_ACCESS } from "../../constants/envConstants";
import http from "../../helper/Interceptors"
import InlineEditor from '@ckeditor/ckeditor5-build-inline';
import AllCkEditor from "../../components/CK-Editor/AllCkEditor";
import { useDispatch, useSelector } from "react-redux";
import { ReloadApiDataList } from "../../redux/actions/dataGridActions";
import { SetReloadNotificationApi } from "../../redux/actions/CommonActions";


const ProjectCommentView = ({ projectId, onCommentAdd }) => {


    const dispatch = useDispatch()
    const [inputComment, setInputComment] = useState("")
    const [comments, setComments] = useState([])
    const [loading, setLoading] = useState(API_FETCH_START)
    const [usersList, setUsersList] = useState([])
    const [activeUserList, setActiveUsersList] = useState([])
    const [showInputBox, setShowInputBox] = useState(false)
    const [notActiveUserName, setNotActiveUserName] = useState("")
    const commentRef = useRef()
    const currentUser = useSelector((state) => state?.user?.userInfo)
    const VIEW_MODE = (!!currentUser?.access_level && currentUser?.access_level?.length > 0) ? currentUser?.access_level?.includes(RESTRICTED_ACCESS) : false



    const handleSaveComment = () => {
        if (!inputComment) {
            toast.error("Please enter comment first")
            return
        }

        let finalComment = inputComment

        finalComment = finalComment?.replace("<p>", "")
        finalComment = finalComment?.replace("</p>", "")
        finalComment = finalComment?.replace(/"/g, "'")

        // const apiPath = changeURLLastString(SAVE_PROJECT_COMMENTS_BY_ID + "1", projectId)
        const apiPath = API_BASE_URL + "apexrest/v1/record"
        const formData = {
            "attributes": {
                "type": "Comment__c"
            },
            "Target_Record_Id__c": projectId,
            "Body__c": finalComment
        }
        http.post(apiPath, formData)
            .then((res) => {
                if (res?.status === 200) {
                    toast.success("Comment added")
                    setInputComment("")
                    dispatch(ReloadApiDataList(true))
                    dispatch(SetReloadNotificationApi(true))
                    onCommentAdd()
                    // getAllCommentsByProjectId()
                }
            }).catch((err) => {
                console.error("err", err)
                toast.error("failed to update comment")
            }).finally(() => {

            })
    }



    const getUsersList = () => {
        const apiPath = API_BASE_URL + "apexrest/v1/records?object=User__c"
        http.get(apiPath).then((res) => {
            if (res?.status === 200) {
                setUsersList(res?.data)
            }
        }).catch((err) => {
            console.error("failed to load comments", err)
        })
    }

    const handleSelectUsersName = (selectedUser) => {
        setActiveUsersList([...activeUserList, selectedUser])
        const replacePara = inputComment?.replace("</p>", "")?.replace("<p>", "")
        const splittedSymbolArr = replacePara?.split("@")

        if (splittedSymbolArr.length > 0) {
            const enteredUserName = splittedSymbolArr[splittedSymbolArr.length - 1]
            const matchString = "@" + enteredUserName;
            const replaceString =
                `<a href='https://${selectedUser?.Id ? selectedUser?.Id : selectedUser?.Full_Name__c}'>
                    ${selectedUser?.Full_Name__c}
                 </a>`;
            setInputComment(inputComment?.replace(matchString, replaceString))
        }
    }

    const handleAddNotExistUser = () => {
        const replace = notActiveUserName
        const formattedObj = { Full_Name__c: replace, userNotRegistered: true }
        handleSelectUsersName(formattedObj)
        setShowInputBox(false)
    }



    useEffect(() => {
        getUsersList()
    }, [])


    const filteredUsers = useMemo(() => {
        const replacePara = inputComment?.replace("</p>", "")?.replace("<p>", "")
        const splittedSymbolArr = replacePara?.split("@")
        if (!!splittedSymbolArr && splittedSymbolArr.length > 0) {
            const enteredUserName = splittedSymbolArr[splittedSymbolArr.length - 1]
            if (!!enteredUserName) {
                return usersList?.filter((key) => key?.Full_Name__c.toLowerCase()?.includes(enteredUserName?.toLowerCase()))
            }
        }
        return usersList
    }, [inputComment])


    const SHOW_USERS_SUGGESTION = useMemo(() => {
        //checking inside @ exist and ignoring 
        let formatted = inputComment && inputComment.replace(/<a[^>]*>(.*?)<\/a>/g, "");
        return formatted?.includes("@")
    }, [inputComment])


    return (
        <Fragment>
            <div className="task-comment">


                {!VIEW_MODE &&
                    <Fragment>
                        <div className="d-flex mb-4">
                            <div className="form-control p-1">
                                <AllCkEditor
                                    editor={InlineEditor}
                                    data={inputComment}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                        setInputComment(data)
                                    }}
                                    onBlur={(editor) => {
                                        // console.log( 'Blur.', editor );
                                    }}
                                    onFocus={(editor) => {
                                        // console.log( 'Focus.', editor );
                                    }}
                                />

                            </div>
                            <div className="input-group-append">
                                <button className="btn btn-primary mb-0" type="button"
                                    onClick={handleSaveComment}>
                                    <i className="feather icon-message-square mr-0 pl-1 pr-1" style={{ fontSize: 20 }} />
                                </button>
                            </div>
                        </div>

                        {!!inputComment && SHOW_USERS_SUGGESTION && usersList?.length > 0 &&
                            <div class="autocom-box">
                                <Fragment>
                                    {filteredUsers?.map((key) => {
                                        return (
                                            <Fragment>
                                                <li onClick={() => handleSelectUsersName(key)}>
                                                    <img className="media-object img-radius comment-img mr-3"
                                                        src={avatar3}
                                                        style={{ width: 25 }}
                                                        alt="avatar" />
                                                    {key?.Full_Name__c}
                                                </li>
                                            </Fragment>
                                        )
                                    })}
                                </Fragment>
                                {!showInputBox &&
                                    <li className="btn btn-primary mt-2" onClick={() => setShowInputBox(true)}>
                                        + Tag External User
                                    </li>
                                }
                                {showInputBox &&
                                    <li>
                                        <div className="input-group mb-1">
                                            <input type="text" className="form-control"
                                                placeholder="email"
                                                value={notActiveUserName}
                                                onChange={(e) => setNotActiveUserName(e?.target?.value)}
                                            />

                                            <div className="input-group-append">
                                                <button className="btn btn-primary btn-icon" type="button"
                                                    onClick={handleAddNotExistUser}>
                                                    <i className="feather icon-plus" />
                                                </button>
                                            </div>
                                        </div>
                                    </li>
                                }
                            </div>
                        }
                    </Fragment>
                }


            </div>
        </Fragment>
    )
}

export default ProjectCommentView