import { USER_LOGIN_SUCCESS } from "../action-types/userAction.types"

const initialState = {
    userInfo: {}
}

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case USER_LOGIN_SUCCESS:
            return {
                ...state,
                userInfo: action.payload,
            }
        default:
            return state
    }
}

export default userReducer