import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  AUTH_CLIENT_PASSWORD,
  DELAY,
  setCookie,
} from "../../constants/commonFunc";
import {
  API_BASE_URL,
  CLIENT_ID,
  CLIENT_SECRET,
} from "../../constants/envConstants";
import http from "../../helper/Interceptors";
import { GET_INCLUDED_FIELDS } from "../../pages/DynamicDataGrid/KendoGridConstants";
import { SetMetaDataSchemaFields } from "../../redux/actions/CommonActions";

const USER_HELPER = () => {
  const dispatch = useDispatch();
  const schemaOptions = useSelector((state) => state?.global?.schemaFields);
  const userInfo = useSelector((state) => state?.user?.userInfo)
  const appList = userInfo?.companies || []
  const CURRENT_USER_ID = userInfo?.id;
  const CURRENT_COMPANY_ID = userInfo?.current_company;

  const handleCookieCreate = async (values) => {
    const testUserName = "api@corporaterealestate.com";
    const testPassword = AUTH_CLIENT_PASSWORD;

    const apiPath = `${API_BASE_URL}oauth2/token?client_id=${CLIENT_ID}&client_secret=${CLIENT_SECRET}
    &grant_type=password&username=${testUserName}&password=${testPassword}`;

    http
      .post(apiPath)
      .then((res) => {
        if (res.status === 200) {
          const apiRes = res?.data;
          setCookie("Authorization", "Bearer " + apiRes?.access_token, 1);
        }
      })
      .catch((err) => {
        toast.error("Invalid credentials. Please verify and retry.");
      });
  };

  const handleCreateJWT = async (values) => {
    const apiPath = "https://api.hyprespace.com/auth/token";
    const headers = {
      authorization: "47812964-41b4-4004-a61d-2ed50cb2088c",
      "Content-Type": "application/json",
    };

    const formData = {
      sub: values?.username,
    };
    // we are using fetch here so it wont use any other header request like authorization or any other thing
    try {
      return await fetch(apiPath, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(formData),
      })
        .then(function (res) {
          return res.json();
        })
        .then(function (data) {
          const jwtToken = data?.token;
          if (!!jwtToken) {
            handleGetAccessToken(jwtToken, values);
          } else {
            toast.error("Failed to create JWT");
          }
        })
        ?.catch((err) => {
          toast.error("Failed to create JWT");
        });
    } catch (error) {
      toast.error("Failed to create JWT");
    }
  };

  const handleGetAccessToken = (jwtToken, values) => {
    const apiPath = API_BASE_URL + "oauth2/token";
    const formData = {
      grant_type: "urn:ietf:params:oauth:grant-type:jwt-bearer",
      assertion: jwtToken,
      "content-type": "application/x-www-form-urlencoded",
    };
    try {
      fetch(apiPath, {
        method: "POST",
        body: new URLSearchParams(formData),
      })
        .then(function (res) {
          return res.json();
        })
        .then(function (data) {
          const apiRes = data;
          if (!!apiRes?.access_token) {
            setCookie("Authorization", "Bearer " + apiRes?.access_token, 3);
          } else {
            // when no access token
            toast.error(
              "Failed to get access token " + apiRes?.error_description
            );
          }
        })
        ?.catch((err) => {
          toast.error("Failed to get access token");
        });
    } catch (err) {
      toast.error("Failed to get access token");
    }
  };

  const getUserInfo = () => {
    const apiPath = API_BASE_URL + "apexrest/v1/user";
    return http
      .get(apiPath)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.error("error", err);
        return err;
      });
  };

  const getAPISchemaData = () => {
    return http
      .get(API_BASE_URL + "apexrest/v1/metadata/schema")
      .then((res) => {
        if (res?.status === 200) {
          dispatch(SetMetaDataSchemaFields(res?.data));
          return res?.data;
        }
      })
      .catch((err) => {
        console.error("failed to fetch schema");
      });
  };

  const GET_USER_SAVED_LAYOUT = (TABLE_COLUMN_NAME, type, subtype) => {
    let subTypeParam = !!subtype ? "&sub_type=" + subtype : "";
    const apiPath =
      API_BASE_URL +
      "apexrest/v1/user/configuration?type=" +
      type +
      subTypeParam;
    return http
      .get(apiPath)
      .then((res) => {
        if (res?.status === 200) {
          let apiData = res?.data;
          if (apiData && apiData.length > 0) {
            return apiData[0]?.json_text;
          } else {
            //return the default saved layout
            return GET_INCLUDED_FIELDS(TABLE_COLUMN_NAME)?.includedGridColumns;
          }
        }
      })
      .catch((err) => {
        console.error("Err mapping get configuration", err);
      });
  };

  const GET_SCHEMA_FIELDS = async (databaseKeyName) => {
    if (
      schemaOptions.length === 0 &&
      ["Lease__c", "Project__c", "Building__c", "Key_Date__c"]?.includes(
        databaseKeyName
      )
    ) {
      const result = await getAPISchemaData();

      const filtered = result?.find((key) => key?.name === databaseKeyName);
      return filtered?.fields || [];
    } else {
      const filtered = schemaOptions?.find(
        (key) => key?.name === databaseKeyName
      );
      if (!filtered || !filtered?.fields) {
        // when page reload and meta schema does not work
        const apiPath =
          API_BASE_URL +
          "apexrest/v1/metadata/schema?object=" +
          databaseKeyName;
        return await http
          .get(apiPath)
          .then((res) => {
            if (res?.status === 200) {
              const apiData = res?.data;
              if (!!apiData && apiData?.length > 0) {
                const fieldsOptions = apiData[0]?.fields;
                //setting in global state for future
                dispatch(SetMetaDataSchemaFields(apiData));
                return fieldsOptions;
              }
            }
          })
          .catch((err) => {
            console.error("failed to fetch schema");
          });
      } else {
        return filtered?.fields || [];
      }
    }
  };

  return {
    handleCookieCreate,
    getUserInfo,
    handleCreateJWT,
    getAPISchemaData,
    GET_SCHEMA_FIELDS,
    GET_USER_SAVED_LAYOUT,
    CURRENT_USER_ID,
    CURRENT_COMPANY_ID

  };
};

export default USER_HELPER;
