import axios from "axios";
import { getCookie } from "../constants/commonFunc";


const onRequest = (config) => {
    //setting all headers
    let accessToken = getCookie("Authorization");
    let sessionId = getCookie("SessionId")
    config.headers = Object.assign({
        "Authorization": accessToken,
        "RX-Session": sessionId,
        "Content-Type": "application/json"
    }, config.headers);
    return config;
}

const onRequestError = (error) => {
    // console.error(`[request error] [${JSON.stringify(error)}]`);
    return Promise.reject(error);
}

const onResponse = (response) => {
    // console.info(`[response] [${JSON.stringify(response)}]`);
    return response;
}

const onResponseError = (error) => {
    if (error?.response) {
        if (error?.response?.status === 401) {
            window.open(window.location.origin, "_self")
        }
        if (error?.response?.status === 400 && error?.response?.data?.message === "INVALID_SESSION") {
            window.open(window.location.origin, "_self")
        }
    }
    return Promise.reject(error);
}


axios.interceptors.request.use(onRequest, onRequestError);
axios.interceptors.response.use(onResponse, onResponseError);


export default {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    delete: axios.delete
}
