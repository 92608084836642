import {
  BUILDING_ICON,
  CONFIGURATION_ICON,
  GOAL_ICONS,
  HOME_ICON,
  IMPORT_WIZARD_ICON,
  KEY_DATE_ICON,
  KEY_DATE_MANAGEMENT_ICON,
  LEASE_ICON,
  LOGOUT_ICON,
  MY_ACCOUNT_ICON,
  OCCUPANCY_ICON,
  OPTIMIZATION_ICON,
  PROJECT_ICON,
  STRATEGIC_ICON,
  TEMPLATE_ICON,
  THEME_ICON,
} from "./config/IconConstant";

const SidebarMenu = {
  items: [
    {
      id: "forms-tables",
      title: "",
      type: "group",
      icon: "icon-group",
      children: [
        {
          id: "forms",
          title: "Home",
          type: "item",
          url: "/home",
          icon: HOME_ICON,
        },
        {
          id: "goals",
          title: "Goals",
          type: "item",
          url: "/goals",
          icon: GOAL_ICONS,
          class: "bruteforceList",
        },

        {
          id: "financial-optimization",
          title: "Optimization",
          type: "item",
          url: "/optimization",
          icon: OPTIMIZATION_ICON,
        },

        {
          id: "bruteforceList",
          title: "Strategic Plans",
          type: "item",
          url: "/strategicplans",
          icon: STRATEGIC_ICON,
          class: "bruteforceList",
        },


        {
          id: "keydatesManagement",
          title: "Key Date Management",
          type: "item",
          url: "/keydatemanagement",
          icon: KEY_DATE_MANAGEMENT_ICON,
          class: "keydatemanagement",
        },

        {
          id: "Buildings",
          title: "Buildings",
          type: "item",
          url: "/buildings",
          icon: BUILDING_ICON,
          class: "buildings",
        },
        // {
        //     id: 'Occupancy',
        //     title: 'Occupancy',
        //     type: 'item',
        //     url: '/occupancy',
        //     icon: OCCUPANCY_ICON,
        //     class: 'Occupancy'
        // },

        {
          id: "leases",
          title: "Leases",
          type: "item",
          url: "/leases",
          icon: LEASE_ICON,
          class: "leases",

          // children: [
          //     {
          //         id: 'leasesList',
          //         title: 'List',
          //         type: 'item',
          //         url: '/leases'
          //     },
          //     // {
          //     //     id: 'keydates',
          //     //     title: 'Key Dates',
          //     //     type: 'item',
          //     //     url: '/keydates'
          //     // },
          //     // {
          //     //     id: 'keydatesManagement',
          //     //     title: 'Key Date Management',
          //     //     type: 'item',
          //     //     url: '/keydatemanagement'
          //     // },
          //     // {
          //     //     id: 'expenses',
          //     //     title: 'Expenses',
          //     //     type: 'item',
          //     //     url: '/expenses'
          //     // },

          // ]
        },
        {
          id: "keydates",
          title: "Key Dates",
          type: "item",
          url: "/keydates",
          icon: KEY_DATE_ICON,
          class: "keydates",
        },

        {
          id: "projects",
          title: "Projects",
          type: "item",
          icon: PROJECT_ICON,
          url: "/projects",
          class: "projects",

          // children: [

          //     {
          //         id: 'button',
          //         title: 'List',
          //         type: 'item',
          //         url: '/projects'
          //     },
          //     // {
          //     //     id: 'button2',
          //     //     title: 'Financial Analysis',
          //     //     type: 'item',
          //     //     url: '/financialanalysis'
          //     // },
          //     {
          //         id: 'button3',
          //         title: 'Budget',
          //         type: 'item',
          //         url: '/budgettracking',
          //         // children: [
          //         //     {
          //         //         id: 'purchaseOrders',
          //         //         title: 'Purchase Orders',
          //         //         type: 'item',
          //         //         url: '/purchaseorders'
          //         //     },
          //         //     {
          //         //         id: 'changeOrders',
          //         //         title: 'Change Orders',
          //         //         type: 'item',
          //         //         url: '/changeorders'
          //         //     },
          //         //     {
          //         //         id: 'invoices',
          //         //         title: 'Invoices',
          //         //         type: 'item',
          //         //         url: '/invoices'
          //         //     },
          //         // ]
          //     },
          //     {
          //         id: 'button',
          //         title: 'Financial Analysis',
          //         type: 'item',
          //         url: '/financial-analysis'
          //     },

          // ]
        },
        // {
        //     id: 'financial-ananlysis',
        //     title: 'Financial Analysis',
        //     type: 'item',
        //     icon: 'feather icon-bar-chart',
        //     url: '/financial-analysis',

        // },
        // {
        //     id: 'budget',
        //     title: 'Budget',
        //     type: 'item',
        //     url: '/budgettracking',
        //     icon: 'feather icon-package',
        //     class: 'budgettracking'
        // },
        // {
        //     id: 'marketFavor',
        //     title: 'Market Favor',
        //     type: 'item',
        //     url: '/marketfavor',
        //     icon: 'feather icon-trending-up',
        //     class: 'marketFavor'
        // },
        // {
        //     id: 'marketclock',
        //     title: 'Market Clock',
        //     type: 'item',
        //     url: '/marketclock',
        //     icon: 'feather icon-clock',
        //     class: 'marketClock'
        // },
        // {
        //     id: 'files',
        //     title: 'Files',
        //     type: 'item',
        //     url: '/files',
        //     icon: 'feather icon-file',
        // },
        // {
        //     id: 'import',
        //     title: 'Import Wizard',
        //     type: 'item',
        //     url: '/import',
        //     icon: IMPORT_WIZARD_ICON,
        // },
        // {
        //     id: 'theme',
        //     title: 'Theme',
        //     type: 'item',
        //     url: '/theme',
        //     icon: THEME_ICON,
        // },
        // {
        //   id: "templateManage",
        //   title: "Templates",
        //   type: "item",
        //   url: "/templates",
        //   icon: TEMPLATE_ICON,
        // },
        // {
        //   id: "configuration",
        //   title: "Configuration",
        //   type: "item",
        //   url: "/configuration",
        //   icon: CONFIGURATION_ICON,
        // },
        // {
        //     id: 'MainSetting',
        //     title: 'Settings',
        //     type: 'item',
        //     url: '/settings',
        //     icon: 'feather icon-settings',
        // },
        // {
        //   id: "settings",
        //   title: "My Account",
        //   type: "item",
        //   url: "/userprofile",
        //   icon: MY_ACCOUNT_ICON,
        // },
        // {
        //     id: 'SettingsProfile',
        //     title: 'Admin',
        //     type: 'item',
        //     url: '/settings',
        //     icon: 'feather icon-settings',
        // },
        // {
        //   id: "logout",
        //   title: "Logout",
        //   type: "item",
        //   url: "/",
        //   icon: LOGOUT_ICON,
        //   external: true,
        // },
      ],
    },
  ],
};

export default SidebarMenu;
