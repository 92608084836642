import React, { useContext, useState, useEffect } from 'react';
import { ListGroup } from 'react-bootstrap';
import { NavLink, useLocation, withRouter, useParams } from 'react-router-dom';

import NavIcon from '../NavIcon';
import NavBadge from '../NavBadge';

import { ConfigContext } from '../../../../../contexts/ConfigContext';
import * as actionType from '../../../../../store/actions';
import useWindowSize from '../../../../../hooks/useWindowSize';
import { deleteCookie, HANDLE_LOGOUT_USER } from '../../../../../constants/commonFunc';
import { getColorCodeClassByParamDetail } from '../../../../../pages/ProjectPage/projectConstants';

const NavItem = ({ layout, item, match }) => {
  const params = useParams();
  const configContext = useContext(ConfigContext);
  const location = useLocation();
  const url = location.pathname;
  const windowSize = useWindowSize();
  const { dispatch } = configContext;
  const { budgetColor,projectColor,keyDateColor,leaseColor,buildingColor } = configContext.state;


 

  const [getParam, setGetParams] = useState(params[0]);


  const getColorCodeClassByParam = () => {
    if (getParam === '/buildings' || getParam.includes('/building/')) {
      return 'orangeColorLeftBorder';
    } else if (getParam === '/leases' || getParam.includes('/lease/')) {
      return 'blueColorLeftBorder';
    } else if (getParam === '/keydates' || getParam.includes('/keydate/')) {
      return 'greenColorLeftBorder';
    } else if (getParam === '/budgettracking' || getParam.includes('/budgettracking/')) {
      return 'blackColorLeftBorder';
    } else if (getParam === '/projects' || getParam.includes('/project/')) {
      return 'yellowColorColorLeftBorder';
    }
  };



  const handleRedirect = (url, title) => {
    if (url === '/') {
      HANDLE_LOGOUT_USER();
    } else {
      window.open(url, '_blank');
    }
  };




  let itemTitle = item.title;
  if (item.icon) {
    itemTitle = <span className="pcoded-mtext">{item.title}</span>;
  }

  let itemTarget = '';
  if (item.target) {
    itemTarget = '_blank';
  }

  let subContent;
  if (item.external) {
    subContent = (
      <a
        onClick={() => handleRedirect(item?.url, itemTitle)}
        className="cp"
        target="_blank"
        rel="noopener noreferrer"
      >
        <NavIcon items={item} />
        {itemTitle}
        <NavBadge items={item} />
      </a>
    );
  } else {
    subContent = (
      <NavLink to={item.url} className={`nav-link `} exact={true} target={itemTarget}>
        <NavIcon items={item} />
        {itemTitle}
        <NavBadge items={item} />
      </NavLink>
    );
  }

  let mainContent = '';
  if (layout === 'horizontal') {
    mainContent = (
      <ListGroup.Item as="li" bsPrefix=" " onClick={() => dispatch({ type: actionType.NAV_CONTENT_LEAVE })}>
        {subContent}
      </ListGroup.Item>
    );
  } else {
    if (windowSize.width < 992) {
      mainContent = (
        <ListGroup.Item
          as="li"
          bsPrefix=" "
          className={`nav-item  ${item.classes}`}
          onClick={() => dispatch({ type: actionType.COLLAPSE_MENU })}
        >
          {subContent}
        </ListGroup.Item>
      );
    } else {
      mainContent = (
        <ListGroup.Item
          as="li"
          bsPrefix=" "
          className={`${(url === item.url ||
            url?.search(item?.url === '/leases' ? 'lease' : item?.url?.replace('/', '')?.replace('s', ''))) > -1
            ? `nav-item pcoded-trigger ${getColorCodeClassByParam()}  ${item.class}`
            : item.class}`}
        >
          {subContent}
        </ListGroup.Item>
      );
    }
  }

  return <React.Fragment>{mainContent}</React.Fragment>;
};

export default withRouter(NavItem);
