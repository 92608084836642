import { STRATEGY_APPROVAL_SET_DATA } from "../action-types/strategyAction.types"


const initialState = {
    strategyApprovalRecordData: {}
}

const strategyReducer = (state = initialState, action) => {
    switch (action.type) {
        case STRATEGY_APPROVAL_SET_DATA:
            return {
                ...state,
                strategyApprovalRecordData: action.payload,
            }
        default:
            return state
    }
}

export default strategyReducer