export const ENV = process.env.REACT_APP_ENV || ""
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || ""
export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID || ""
export const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET || ""
export const GUEST_USER_EMAIL = process.env.REACT_APP_GUEST_USER_EMAIL || ""

export const RESTRICTED_ACCESS = "restricted"
export const API_FETCH_START = "API_FETCH_START"
export const API_FETCH_COMPLETE = "API_FETCH_COMPLETE"

export const S3_BUCKET = 'hyprespace-app';
export const S3_REGION = 'us-east-1';
export const S3_ACCESS_KEY = 'AKIAVWXI57GTO2RPFD6P';
export const S3_SECRET_ACCESS_KEY = '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';


export const S3_FILE_UPLOAD_CONFIG = {
    bucketName: S3_BUCKET,
    region: S3_REGION,
    accessKeyId: S3_ACCESS_KEY,
    secretAccessKey: S3_SECRET_ACCESS_KEY,
}
