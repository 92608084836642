import React, { useContext, useEffect, useState } from "react";
import { ListGroup } from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import cushmanLogo from "../../../../assets/images/cushmanLogo.png";
import colliersLogo from "../../../../assets/images/colliersLogo.png";
import jllLogo from "../../../../assets/images/jllLogo.svg";
import pepsicoWhite from "../../../../assets/images/pepsicoWhite.png";

import NavGroup from "./NavGroup";
import { ConfigContext } from "../../../../contexts/ConfigContext";
import * as actionType from "../../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import USER_HELPER from "../../../../helper/api/UserHelper";
import { getCookie } from "../../../../constants/commonFunc";
import { LoginSuccessAction } from "../../../../redux/actions/userActions";
import {
  GUEST_USER_EMAIL,
  RESTRICTED_ACCESS,
} from "../../../../constants/envConstants";
import http from "../../../../helper/Interceptors";
import { GET_ALL_FILES_PROJECT_DETAIL_API } from "../../../../constants/apiPaths";
import AppsIcon from "@mui/icons-material/Apps";
import ServiceAppModal from "../../../../components/common/AppModal";
import { SET_CHART_COLOR_ACTION, SET_GLOBAL_ZOOM_ACTION } from "../../../../redux/actions/CommonActions";

const NavContent = ({ navigation }) => {
  const userInfo = useSelector((state) => state?.user?.userInfo);
  const appList = userInfo?.companies;

  const [scrollWidth, setScrollWidth] = useState(0);
  const [prevDisable, setPrevDisable] = useState(true);
  const [nextDisable, setNextDisable] = useState(false);
  const [appModal, setAppModal] = useState(false);
  const [currentCompanyObj, setCurrentCompanyObj] = useState({});

  const configContext = useContext(ConfigContext);
  const { dispatch } = configContext;
  const location = useLocation();
  const { getUserInfo, getAPISchemaData } = USER_HELPER();
  const reduxDispatch = useDispatch();
  const currentUser = useSelector((state) => state?.user?.userInfo);
  const VIEW_MODE =
    !!currentUser?.access_level && currentUser?.access_level?.length > 0
      ? currentUser?.access_level?.includes(RESTRICTED_ACCESS)
      : false;

  const { layout, rtlLayout } = configContext.state;

  const handleChangeDefaultSalesforceColor = () => {
    dispatch({
      type: actionType.NAV_BRAND_COLOR,
      navBrandColor: "brand-lightblue",
    });
    dispatch({
      type: actionType.HEADER_BACK_COLOR,
      headerBackColor: "header-lightblue",
    });
    dispatch({
      type: actionType.NAV_BACK_COLOR,
      navBackColor: "navbar-lightblue",
    });
    dispatch({
      type: actionType.NAV_ACTIVE_LIST_COLOR,
      navActiveListColor: "active-default",
    });
  };

  const handleChangeCushmanColor = () => {
    dispatch({
      type: actionType.NAV_BRAND_COLOR,
      navBrandColor: "brand-default",
    });
    dispatch({
      type: actionType.HEADER_BACK_COLOR,
      headerBackColor: "header-cushman",
    });
    dispatch({
      type: actionType.NAV_BACK_COLOR,
      navBackColor: "navbar-default",
    });
    dispatch({
      type: actionType.NAV_ACTIVE_LIST_COLOR,
      navActiveListColor: "active-cushman",
    });
  };
  const handleChangeCollierColor = () => {
    dispatch({
      type: actionType.NAV_BRAND_COLOR,
      navBrandColor: "brand-default",
    });
    dispatch({
      type: actionType.HEADER_BACK_COLOR,
      headerBackColor: "header-collier",
    });
    dispatch({
      type: actionType.NAV_BACK_COLOR,
      navBackColor: "navbar-collier",
    });
    dispatch({
      type: actionType.NAV_ACTIVE_LIST_COLOR,
      navActiveListColor: "active-default",
    });
  };
  const handleChangeJLLColor = () => {
    dispatch({ type: actionType.NAV_BRAND_COLOR, navBrandColor: "brand-jll" });
    dispatch({
      type: actionType.HEADER_BACK_COLOR,
      headerBackColor: "header-jll",
    });
    dispatch({
      type: actionType.NAV_BACK_COLOR,
      navBackColor: "navbar-default",
    });
    dispatch({
      type: actionType.NAV_ACTIVE_LIST_COLOR,
      navActiveListColor: "active-jll",
    });
  };

  const handleHeaderColorChange = (colorCode) => {
    const colorName = "header-" + colorCode?.replace("#", "");
    dispatch({
      type: actionType.HEADER_BACK_COLOR,
      headerBackColor: colorName,
    });
  };

  const handleChangeNavbarColor = (colorCode) => {
    const colorName = "navbar-" + colorCode?.replace("#", "");
    dispatch({ type: actionType.NAV_BACK_COLOR, navBackColor: colorName });
  };
  const handleChnageMenuBrandColor = (colorCode) => {
    const colorName = "brand-default " + colorCode?.replace("#", "");
    dispatch({ type: actionType.NAV_BRAND_COLOR, navBrandColor: colorName });
  };

  const handleChangeButtonColor = (colorCode) => {
    const colorName = "button-color-" + colorCode?.replace("#", "");
    dispatch({ type: actionType.BUTTON_COLOR, buttonColor: colorName });
  };
  const handleChangeTabColor = (colorCode) => {
    const colorName = "tab-color-" + colorCode?.replace("#", "");
    dispatch({ type: actionType.TAB_COLOR, tabColor: colorName });
  };

  const handleChangeProjectColor = (colorCode) => {
    const colorName = "project-color-" + colorCode?.replace("#", "");
    dispatch({ type: actionType.PROJECT_COLOR, projectColor: colorName });
  };

  const handleChangeBuildingColor = (colorCode) => {
    const colorName = "building-color-" + colorCode?.replace("#", "");
    dispatch({ type: actionType.BUILDING_COLOR, buildingColor: colorName });
  };
  const handleChangeCompanyColorChart = (colors) => {
    dispatch({
      type: actionType.COMPANY_COLOR_CHART,
      companyColorChart: colors,
    });
  };

  const handleChangeLeaseColor = (colorCode) => {
    const colorName = "lease-color-" + colorCode?.replace("#", "");
    dispatch({ type: actionType.LEASE_COLOR, leaseColor: colorName });
  };
  const handleChangeKeyDateColor = (colorCode) => {
    const colorName = "keyDate-color-" + colorCode?.replace("#", "");
    dispatch({ type: actionType.KEY_DATE_COLOR, keyDateColor: colorName });
  };
  const handleChangeBudgetColor = (colorCode) => {
    const colorName = "budget-color-" + colorCode?.replace("#", "");
    dispatch({ type: actionType.BUDGET_COLOR, budgetColor: colorName });
  };

  const handleGetUserInfo = () => {
    if (!getCookie("SessionId")) return;
    getUserInfo()?.then((res) => {
      const apiData = res?.data;

      if (apiData?.email === GUEST_USER_EMAIL) {
        const finalObj = {
          ...apiData,
          session_id: getCookie("SessionId"),
        };
        localStorage.setItem("user_info", JSON.stringify(finalObj));
        reduxDispatch(LoginSuccessAction(finalObj));
      } else {
        reduxDispatch(LoginSuccessAction(apiData));
      }

      const activeCompany = apiData?.current_company;

      const fullInfoCompany = apiData?.companies?.find(
        (key) => key?.company_id === activeCompany
      );
      setCurrentCompanyObj(fullInfoCompany);
      // header color change
      handleHeaderColorChange(fullInfoCompany?.company_color_header);
      //navbar full bg  color
      handleChangeNavbarColor(fullInfoCompany?.company_color_menu);
      // menu brand color
      handleChnageMenuBrandColor("#ffffff");

      handleChangeButtonColor(fullInfoCompany?.company_color_button);
      handleChangeTabColor(fullInfoCompany?.company_color_tab);
      handleChangeProjectColor(fullInfoCompany?.company_color_project);
      handleChangeLeaseColor(fullInfoCompany?.company_color_lease);
      handleChangeKeyDateColor(fullInfoCompany?.company_color_key_date);
      handleChangeBudgetColor(fullInfoCompany?.company_color_budget);
      handleChangeBuildingColor(fullInfoCompany?.company_color_building);
      handleChangeCompanyColorChart(fullInfoCompany?.company_color_chart)
      window.localStorage.setItem("CHART_COLORS", fullInfoCompany?.company_color_chart)
      reduxDispatch(SET_CHART_COLOR_ACTION(fullInfoCompany?.company_color_chart));
      reduxDispatch(SET_GLOBAL_ZOOM_ACTION(apiData?.zoom))

    });
  };

  const getImageRecords = (projectId) => {
    const queryParam = `?record_id=${projectId}&file_content_required=true`;
    http.get(GET_ALL_FILES_PROJECT_DETAIL_API + queryParam).then((res) => {
      if (res?.status === 200) {
        let apiData = res?.data;
        if (!!apiData && apiData.length > 0) {
          const merged = {
            ...currentUser,
            companyImage: true,
          };
          reduxDispatch(LoginSuccessAction(merged));
        }
      }
    });
  };

  useEffect(() => {
    handleGetUserInfo();

    // checking record image if found
    // getImageRecords("a0d5f000002v9ChAAI")
    // get api schema for fileds data and save in redux store
    // getAPISchemaData()
  }, []);

  const scrollPrevHandler = () => {
    const wrapperWidth = document.getElementById("sidenav-wrapper").clientWidth;

    let scrollWidthPrev = scrollWidth - wrapperWidth;
    if (scrollWidthPrev <= 0) {
      setScrollWidth(0);
      setPrevDisable(true);
      setNextDisable(false);
    } else {
      setScrollWidth(scrollWidthPrev);
      setPrevDisable(false);
      setNextDisable(false);
    }
  };

  const scrollNextHandler = () => {
    const wrapperWidth = document.getElementById("sidenav-wrapper").clientWidth;
    const contentWidth =
      document.getElementById("sidenav-horizontal").clientWidth;

    let scrollWidthNext = scrollWidth + (wrapperWidth - 80);
    if (scrollWidthNext > contentWidth - wrapperWidth) {
      scrollWidthNext = contentWidth - wrapperWidth + 80;

      setScrollWidth(scrollWidthNext);
      setPrevDisable(false);
      setNextDisable(true);
    } else {
      setScrollWidth(scrollWidthNext);
      setPrevDisable(false);
    }
  };

  const navItems = navigation.map((item) => {
    switch (item.type) {
      case "group":
        return (
          <NavGroup layout={layout} key={"nav-group-" + item.id} group={item} />
        );
      default:
        return false;
    }
  });

  let scrollStyle = {
    marginLeft: "-" + scrollWidth + "px",
  };

  if (layout === "horizontal" && rtlLayout) {
    scrollStyle = {
      marginRight: "-" + scrollWidth + "px",
    };
  }

  let mainContent = "";
  if (layout === "horizontal") {
    let prevClass = ["sidenav-horizontal-prev"];
    if (prevDisable) {
      prevClass = [...prevClass, "disabled"];
    }
    let nextClass = ["sidenav-horizontal-next"];
    if (nextDisable) {
      nextClass = [...nextClass, "disabled"];
    }

    mainContent = (
      <div className="navbar-content sidenav-horizontal" id="layout-sidenav">
        <Link
          to="#"
          className={prevClass.join(" ")}
          onClick={scrollPrevHandler}
        >
          <span />
        </Link>
        <div id="sidenav-wrapper" className="sidenav-horizontal-wrapper">
          <ListGroup
            variant="flush"
            bsPrefix=" "
            as="ul"
            id="sidenav-horizontal"
            className="nav pcoded-inner-navbar sidenav-inner"
            onMouseLeave={() =>
              dispatch({ type: actionType.NAV_CONTENT_LEAVE })
            }
            style={scrollStyle}
          >
            {navItems}
          </ListGroup>
        </div>
        <Link
          to="#"
          className={nextClass.join(" ")}
          onClick={scrollNextHandler}
        >
          <span />
        </Link>
      </div>
    );
  } else {
    mainContent = (
      <div className="navbar-content datta-scroll">
        <PerfectScrollbar className="d-flex flex-column">
          <ListGroup
            variant="flush"
            as="ul"
            bsPrefix=" "
            className="nav pcoded-inner-navbar"
            id="nav-ps-next"
          >
            {/* <li className="nav-item pcoded-menu-caption customUserName ">
              <label>
                Hi, {userInfo?.full_name}!
              </label>
            </li> */}

            {!!appList && appList.length > 1 && (
              <li class="">
                <a class="nav-link cp"
                  onClick={() => setAppModal(!appModal)} style={{ marginBottom: 25 }}>
                  <span class="pcoded-micon">
                    <AppsIcon style={{ color: "white" }} />
                  </span>
                  <span class="pcoded-mtext">
                    {currentCompanyObj?.company_name}
                  </span>
                </a>
              </li>
            )}
            {navItems}
          </ListGroup>
          <div className="mt-auto text-center" style={{ background: "white" }}>
            <p className="powerText mb-0 bold pcoded-inner-navbar pcoded-menu-caption">
              powered by{" "}
            </p>
            <div className="b-bg mt-1">
              {/* <p className='poweredByText'>{currentCompanyObj?.company_name}</p> */}
              <img
                src={
                  !!currentCompanyObj?.company_logo
                    ? currentCompanyObj?.company_logo
                    : jllLogo
                }
                alt="logo"
                style={{ maxWidth: 45, paddingBottom: 5 }}
              />
            </div>
            {/* <div className="b-bg mt-1">
              <img src={jllLogo} alt="logo" style={{ maxWidth: 45, paddingBottom: 5 }} />
            </div> */}
            {/* <div className="b-bg mt-1">
              <img src={cushmanLogo} alt="logo" style={{ maxWidth: 45, paddingBottom: 5 }} />
            </div> */}
          </div>
        </PerfectScrollbar>
      </div>
    );
  }

  return (
    <React.Fragment>
      {!VIEW_MODE ? mainContent : ""}

      <ServiceAppModal
        isModalOpen={appModal}
        onCloseModal={() => setAppModal(false)}
      />
    </React.Fragment>
  );
};

export default NavContent;
