import { OPEN_USER_NOTIFICATION_MODAL } from "../action-types/userNotificationAction.types"

const initialState = {
    notificationModal: {}
}

const userNotificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case OPEN_USER_NOTIFICATION_MODAL:
            return {
                ...state,
                notificationModal: action.data
            }
        default:
            return state
    }

}

export default userNotificationReducer