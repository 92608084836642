import { Fragment } from "react";
import UploadImageToS3WithReactS3 from "../../components/common/S3FileUpload";

const UploadFileProjectDetail = ({
  projectId,
  onImageUpload,
  onDeleteSuccess,
  closeModal,
  page = "default",
}) => {
  return (
    <Fragment>
      <UploadImageToS3WithReactS3
        recordId={projectId}
        onImageUpload={onImageUpload}
        onDeleteSuccess={onDeleteSuccess}
        closeModal={closeModal}
        page={page}
      />
    </Fragment>
  );
};

export default UploadFileProjectDetail;
