import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';

const AllCkEditor = (props) => {
  return <CKEditor
    config={{
      toolbar: false
    }}
    {...props} />;
};

export default AllCkEditor;
