import { Drawer } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { SetActiveClausesInfo } from "../../redux/actions/projectActions";
import { API_BASE_URL } from "../../constants/envConstants";
import http from "../../helper/Interceptors"
import DataNotFoundMessage from "./kendo/DataNotFound";


const ClauseSidebarDrawer = () => {
  const clauseModalOpen = useSelector(state => state?.project?.isClauseSliderOpen);
  const clauseTableColumnName = useSelector(state => state?.project?.clauseTableColumnName);
  const clauseRecordId = useSelector(state => state?.project?.clauseRecordId);
  const dispatch = useDispatch()

  const [clauses,setClauses] = useState([])
  const [isLoading,setIsLoading] = useState(true)

  const getAllClauses = (tableColumnName,recordId) => {
    setIsLoading(true)
    if(!!tableColumnName && !!recordId){
      let apiPath = API_BASE_URL  + "apexrest/v1/records?p1="+tableColumnName+"&object=Clause__c&v1=" + recordId;
      http.get(apiPath).then((res) => {
          if (res?.status === 200) {
              // setComments(res?.data)
              console.log(res?.data,"---clausedata")
              setClauses(res?.data || [])
              setIsLoading(false)
          }
      }).catch((err) => {
          console.error("failed to load clause data", err)

      })
    }
  }

  useEffect(()=>{
    getAllClauses(clauseTableColumnName,clauseRecordId)
  },[clauseTableColumnName,clauseRecordId])

  return (
    <Fragment>
      <div className={`${clauseModalOpen ? "open" : ""} `}>
        <div className="style-block">
          <Drawer
            anchor={"right"}
            open={clauseModalOpen}
            onClose={() => dispatch(SetActiveClausesInfo("", "", false))}
          >
            <div style={{ width: "75vw" }} className="p-4 pt-5">
              <div className="mb-3 d-flex justify-content-between">
                <h5>Clauses</h5>
                <OverlayTrigger overlay={<Tooltip>Close</Tooltip>}>
                  <button
                    className="btn cPadding  m-0 px-3 btn-secondary"
                    onClick={() =>
                      dispatch(SetActiveClausesInfo("", "", false))
                    }
                  >
                    <i className="fa fa-close mr-0" />
                  </button>
                </OverlayTrigger>
              </div>
            </div>
            <div className="d-flex ml-4 mr-4" style={{flexDirection: 'column'}}>
              {isLoading ? <h4>Loading...</h4> : clauses?.map((clause) => (
                <Card style={{width:"1350px"}}>
                  <Card.Header>
                    <Card.Title as="h5">{clause?.Type__c} Clause</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <p> {clause?.Description__c}</p>
                  </Card.Body>
                </Card>
              ))}
              {(!clauses?.length && !isLoading) ? <DataNotFoundMessage message={"No Clause found"} /> : null }
            </div>
          </Drawer>
        </div>
      </div>
    </Fragment>
  );
};

export default ClauseSidebarDrawer;
