import { SET_CHART_COLORS, SET_FILE_UPLOAD_MODAL, SET_GLOBAL_FONT_ZOOM, SET_GLOBAL_LOADER, SET_GOOGLE_MAP_API_LOADED, SET_META_DATA_SCHEMA_FIELDS, SET_NOTIFICATION_DATA_API, SET_RELOAD_NOTIFICATION_API, SET_TOUR_MODAL_OPEN } from "../action-types/CommonAction.types"

export const SetMetaDataSchemaFields = (data) => {
    return {
        type: SET_META_DATA_SCHEMA_FIELDS,
        data
    }
}

export const SetReloadNotificationApi = (isReload) => {
    return {
        type: SET_RELOAD_NOTIFICATION_API,
        isReload
    }
}
export const SetNotificationData = (data) => {
    return {
        type: SET_NOTIFICATION_DATA_API,
        data
    }
}
export const SetGoogleMapApiLoaded = (isLoaded) => {
    return {
        type: SET_GOOGLE_MAP_API_LOADED,
        isLoaded
    }
}

export const SetGlobalLoader = (isLoading) => {
    return {
        type: SET_GLOBAL_LOADER,
        isLoading
    }
}
export const SET_FILE_UPLOAD_MODAL_OPEN = (isModalOpen) => {
    return {
        type: SET_FILE_UPLOAD_MODAL,
        isModalOpen
    }
}

export const SET_TOUR_MODAL_SHOW_HIDE = (isModalOpen) => {
    return {
        type: SET_TOUR_MODAL_OPEN,
        isModalOpen
    }
}
export const SET_GLOBAL_ZOOM_ACTION = (zoomInput) => {
    return {
        type: SET_GLOBAL_FONT_ZOOM,
        zoomInput
    }
}
export const SET_CHART_COLOR_ACTION = (colors) => {
    return {
        type: SET_CHART_COLORS,
        colors
    }
}