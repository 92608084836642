import { SET_CHART_COLORS, SET_FILE_UPLOAD_MODAL, SET_GLOBAL_FONT_ZOOM, SET_GLOBAL_LOADER, SET_GOOGLE_MAP_API_LOADED, SET_META_DATA_SCHEMA_FIELDS, SET_NOTIFICATION_DATA_API, SET_RELOAD_NOTIFICATION_API, SET_TOUR_MODAL_OPEN } from "../action-types/CommonAction.types"

const initialState = {
    schemaFields: [],
    reloadNotificationApi: false,
    notificationData: [],
    googleMapApiLoaded: null,
    globalLoader: false,
    fileUploadModal: false,
    tourModalOpen: false,
    globalZoom: 1,
    chartColors: ""
}

const commonReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_META_DATA_SCHEMA_FIELDS:

            return {
                ...state,
                schemaFields: [...state?.schemaFields, ...action.data]
            }
        case SET_RELOAD_NOTIFICATION_API:
            return {
                ...state,
                reloadNotificationApi: action?.isReload
            }
        case SET_NOTIFICATION_DATA_API:
            return {
                ...state,
                notificationData: action?.data
            }
        case SET_GOOGLE_MAP_API_LOADED:
            return {
                ...state,
                googleMapApiLoaded: action?.isLoaded
            }
        case SET_GLOBAL_LOADER:
            return {
                ...state,
                globalLoader: action?.isLoading
            }
        case SET_FILE_UPLOAD_MODAL:
            return {
                ...state,
                fileUploadModal: action?.isModalOpen
            }
        case SET_TOUR_MODAL_OPEN:
            return {
                ...state,
                tourModalOpen: action?.isModalOpen
            }
        case SET_GLOBAL_FONT_ZOOM:
            return {
                ...state,
                globalZoom: action?.zoomInput
            }
        case SET_CHART_COLORS:
            return {
                ...state,
                chartColors: action?.colors
            }
        default:
            return state
    }

}

export default commonReducer