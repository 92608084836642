import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';

import './services';

import { Provider } from 'react-redux';
import { ConfigProvider } from './contexts/ConfigContext';

import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './store';
import IdleStateDetector from './constants/IdleStateDetecter';
import { HANDLE_LOGOUT_USER } from './constants/commonFunc';

ReactDOM.render(
  <Provider store={store}>
    <ConfigProvider>
      <IdleStateDetector delay={5400000} onIdle={() => HANDLE_LOGOUT_USER()} />
      <App />
    </ConfigProvider>
  </Provider>,
  document.getElementById('root')
);

reportWebVitals();
