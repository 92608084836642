export const CHART_ALL_FIELD_OPTIONS = "CHART_ALL_FIELD_OPTIONS"
export const CHART_ACTIVE_FIELDS_OPTIONS = "CHART_ACTIVE_FIELDS_OPTIONS"
export const CHART_SECTIONS = "CHART_SECTIONS"
export const CHART_SECTION_RERENDER = "CHART_SECTION_RERENDER"
export const KPI_SECTION_RERENDER = "KPI_SECTION_RERENDER"

export const SET_CHART_DETAIL_FULL_INFO = "SET_CHART_DETAIL_FULL_INFO"
export const SET_FIELD_ORDERING_LIST = "SET_FIELD_ORDERING_LIST"
export const SET_CHART_KPIS = "SET_CHART_KPIS"
export const SET_USER_CHART_SAVED_LAYOUT = "SET_USER_CHART_SAVED_LAYOUT"
export const IS_EDITABLE_FIELD = "IS_EDITABLE_FIELD"

export const SET_CHART_LIST_DATA = "SET_CHART_LIST_DATA"
export const SET_FIELDS_TYPE_DATA = "SET_FIELDS_TYPE_DATA"
export const SET_INTERLINKING_PROJECT_IDS = "SET_INTERLINKING_PROJECT_IDS"
export const SET_CHART_LOADED_ONCE = "SET_CHART_LOADED_ONCE"