import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { ConfigContext } from '../../../../contexts/ConfigContext';
import * as actionType from '../../../../store/actions';
import cushmanLogo from "../../../../assets/images/cushmanLogo.png"
import colliersLogo from "../../../../assets/images/colliersLogo.png"
import jllLogo from "../../../../assets/images/jllLogo.svg"
import pepsicoGlobe from "../../../../assets/images/pepsicoGlobe.png"
import pepsicoLogoBlue from "../../../../assets/images/pepsicoLogoBlue.png"
import { useDispatch, useSelector } from 'react-redux';
import { RerenderChartSection, SetChartKPIs, SetChartListData, SetChartLoadedOnce, SetChartSections, SetUserSavedLayout } from '../../../../redux/actions/chartDetailActions';
import { SetActiveUserFilterDropDown, SetDataGridAdvancedFilters, SetDataGridCustomFilters, SetDataGridListDataAction, SetFilteredDataGridListDataAction, SetSavedSchemaByUserList, SetUserFiltersList } from '../../../../redux/actions/dataGridActions';
import S3_FILE_HELPER from '../../../../constants/S3FileUploadHelper';
import { SetInterlinkingProjectIds, SetIsEditableSections } from '../../../../redux/actions/projectDetailActions';
import { OpenKeyDateMapView } from '../../../../redux/actions/keyDatesActions';

const NavLogo = () => {
  const configContext = useContext(ConfigContext);
  const { collapseMenu } = configContext.state;
  const { dispatch } = configContext;
  const location = useLocation()
  const isCompanyImageUploaded = useSelector((state) => state?.user?.userInfo?.companyImage)
  const dispatchRedux = useDispatch()


  const [companyLogo, setCompanyLogo] = useState("")
  const { GET_ALL_IMAGES_PUBLIC_URL } = S3_FILE_HELPER()
  const userInfo = useSelector((state) => state?.user?.userInfo)
  const appList = userInfo?.companies
  const activeCompany = userInfo?.current_company;
  const [currentCompanyObj, setCurrentCompanyObj] = useState({})

  useEffect(() => {
    const fullInfoCompany = appList?.find((key) => key?.company_id === activeCompany)
    setCurrentCompanyObj(fullInfoCompany)
  }, [appList])

  let toggleClass = ['mobile-menu'];
  if (collapseMenu) {
    toggleClass = [...toggleClass, 'on'];
  }

  useEffect(() => {
    if (isCompanyImageUploaded) {
      const handleChangeDefaultSalesforceColor = () => {
        dispatch({ type: actionType.NAV_BRAND_COLOR, navBrandColor: "brand-lightblue" });
        dispatch({ type: actionType.HEADER_BACK_COLOR, headerBackColor: "header-lightblue" });
        dispatch({ type: actionType.NAV_BACK_COLOR, navBackColor: "navbar-lightblue" });
        dispatch({ type: actionType.NAV_ACTIVE_LIST_COLOR, navActiveListColor: "active-default" });
      }
      handleChangeDefaultSalesforceColor()
    }
    if (!isCompanyImageUploaded) {
      dispatch({ type: actionType.RESET })
    }
  }, [isCompanyImageUploaded])

  useEffect(() => {
    const oldUrl = localStorage?.getItem("oldUrl")
    const reactUrl = location?.pathname
    localStorage?.setItem("oldUrl", reactUrl)
    if (oldUrl !== reactUrl) {
      const handleResetGlobalKendoGrid = () => {
        //reset all chart global store
        dispatchRedux(SetChartSections([]))
        dispatchRedux(SetChartListData([]))
        dispatchRedux(SetUserSavedLayout([]))
        dispatchRedux(SetChartLoadedOnce(false))
        dispatchRedux(SetChartKPIs([]))
        dispatchRedux(SetInterlinkingProjectIds([]))


        // reset all kendo grid elements
        dispatchRedux(SetSavedSchemaByUserList([]))
        dispatchRedux(SetDataGridListDataAction([]))
        dispatchRedux(SetFilteredDataGridListDataAction([]))
        dispatchRedux(SetUserFiltersList([]))
        dispatchRedux(SetDataGridCustomFilters({}))
        dispatchRedux(SetDataGridAdvancedFilters({
          logic: 'and',
          filters: []
        }))
        dispatchRedux(SetActiveUserFilterDropDown(""))
        dispatchRedux(SetIsEditableSections(false));
        dispatchRedux(OpenKeyDateMapView({}))

      }

      handleResetGlobalKendoGrid()
    }
    // console.log("page change trigger", oldUrl, reactUrl)
  }, [location])

  const handleGetAllImagesInsideFolder = async () => {
    const folderName = activeCompany
    const result = await GET_ALL_IMAGES_PUBLIC_URL(folderName)
    const firstObj = !!result && result.length > 0 ? result[result?.length - 1] : {}
    setCompanyLogo(firstObj?.publicPath)
  }
  useEffect(() => {
    handleGetAllImagesInsideFolder()
  }, [activeCompany])

  return (
    <React.Fragment>
      <div className="navbar-brand header-logo bg-white">
        <Link to="#" className="b-brand">
          <div className="b-bg">
            {!isCompanyImageUploaded &&
              <img src={companyLogo ? companyLogo : "https://www.market-research-companies.in//images/default.jpg"} alt="logo" style={{ maxWidth: "75px", maxHeight: 50 }} />
            }
            {/* {!isCompanyImageUploaded &&
              <img src={cushmanLogo} alt="logo" style={{ maxWidth: "75px" }} />
            } */}
            {/* {isCompanyImageUploaded &&
              <img src={" https://upload.wikimedia.org/wikipedia/commons/thumb/f/f9/Salesforce.com_logo.svg/1200px-Salesforce.com_logo.svg.png"}
                alt="brand"
                style={{ width: 70 }}
              />
            } */}

          </div>
          {/* {!isCompanyImageUploaded &&
            <span className="b-title ml-3" style={{ color: "gray" }}>
              CUSHMAN & <br />WAKEFILED
            </span>
            <img src={cushmanLogo}
              alt="brand"
              style={{ width: 120 }}
              className="b-title ml-3"
            />
          } */}

          {/* {isCompanyImageUploaded &&
            <span className="b-title ml-4" style={{ color: "gray" }}>Salesforce</span>
          } */}

        </Link>
        {/* <Link to="#" className={toggleClass.join(' ')} id="mobile-collapse" onClick={() => dispatch({ type: actionType.COLLAPSE_MENU })}>
          <img src={colliersLogo} alt="logo" style={{ width: 500 }} />
          <span />
        </Link> */}
      </div>
    </React.Fragment>
  );
};

export default NavLogo;
