import react, { Fragment, useState } from "react"
import { Row, Col, Button, Form, Modal } from 'react-bootstrap';
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { SAVE_USER_FILTERS_PROJECT_LIST } from "../../constants/apiPaths";
import { replaceSpecialCharacter } from "../../constants/commonFunc";
import http from "../../helper/Interceptors"
import { PROJECT_ATTRIBUTE_FILTER_SAVE, PROJECT_TYPE_COLUMN, PROJECT_TYPE_COLUMN_FOR_FILTER_SAVE } from "./projectConstants";
import ProjectHelper from "./ProjectHelper";

const FilterSaveModalForUser = ({ isModalOpen, onCloseModal, type = "CUSTOM" }) => {

    const customFilters = useSelector((state) => state?.project?.customFilters)
    const currentUser = useSelector((state) => state?.user?.userInfo)
    const advancedFilters = useSelector((state) => state?.project?.advancedFilters)

    const [fileName, setFileName] = useState("")
    const [errorMessage, setErrorMessage] = useState("")

    const { getMappingConfigurationFilters } = ProjectHelper()

    const handleSaveFilterMapping = () => {
        if (!fileName) {
            setErrorMessage("name is required")
            return
        }
        if (!!fileName && fileName.toString().length > 80) {
            setErrorMessage("name must be less than 80 characters")
            return
        }
        setErrorMessage("")

        const jsonData = type === "CUSTOM" ? customFilters : advancedFilters

        const formData = {
            "attributes": {
                "type": PROJECT_ATTRIBUTE_FILTER_SAVE,
            },
            "Name": fileName,
            "JSON__c": JSON.stringify(jsonData),
            // "Id": replaceSpecialCharacter(fileName) + "_" + currentUser?.id,
            "Type__c": PROJECT_TYPE_COLUMN_FOR_FILTER_SAVE,
            "Sub_Type__c": type,
        }

        const apiPath = SAVE_USER_FILTERS_PROJECT_LIST

        http.post(apiPath, [formData]).then((res) => {
            if (res?.status === 200) {
                toast.success(fileName + " saved")
                setFileName("")
                onCloseModal()
                // reload all the saved users filters
                getMappingConfigurationFilters()
            }
        }).catch((err) => {
            toast.error("Failed to save mapping" + err?.response?.data?.detail)
        })
    }


    return (
        <Fragment>
            <Modal show={isModalOpen} onHide={() => onCloseModal()}>
                <Modal.Header closeButton>
                    <Modal.Title as="h5">
                        Save As...
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Row>
                        <Form.Group as={Col} className="mb-1">
                            <input className={`form-control ${errorMessage ? "is-invalid" : ""}`}
                                onChange={(e) => setFileName(e?.target?.value)}
                                placeholder="name"
                                value={fileName} />
                        </Form.Group>
                    </Form.Row>
                    {errorMessage &&
                        <p className="text-danger mt-0">
                            {errorMessage}
                        </p>
                    }
                </Modal.Body>
                <Modal.Footer className="p-3">
                    <Button variant="success" type="submit" onClick={handleSaveFilterMapping}>
                        Save
                    </Button>

                </Modal.Footer>

            </Modal>
        </Fragment>
    )
}

export default FilterSaveModalForUser