import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import { GET_PROJECTS_FILTERS_LIST_FOR_USER, GET_PROJECT_DETAILS_BY_ID } from "../../constants/apiPaths"
import { API_BASE_URL } from "../../constants/envConstants"
import USER_HELPER from "../../helper/api/UserHelper"
import http from "../../helper/Interceptors"
import { SetGlobalLoader } from "../../redux/actions/CommonActions"
import { SetUserFiltersList } from "../../redux/actions/projectActions"

const ProjectHelper = () => {
    const dispatch = useDispatch()
    const { GET_SCHEMA_FIELDS } = USER_HELPER()
    const CHART_COMPANY_COLORS = useSelector((state) => state?.gloabal?.chartColors) || ""
    const CHART_FINAL_COLORS = CHART_COMPANY_COLORS?.split(",")

    const getMappingConfigurationFilters = () => {
        const apiPath = GET_PROJECTS_FILTERS_LIST_FOR_USER + "?type=projects"
        http.get(apiPath).then((res) => {
            if (res?.status === 200) {
                dispatch(SetUserFiltersList(res?.data))
            }
        }).catch((err) => {
            console.error("Err mapping get configuration", err)
        })
    }

    const handleDeleteRecord = (TABLE_NAME, recordId, toastShow = true) => {
        const formData = {
            "attributes": {
                "type": TABLE_NAME
            },
            "Id": recordId,
        }

        const apiPath = API_BASE_URL + "apexrest/v1/record"
        return http.delete(apiPath, { data: formData }).then((res) => {
            if (res?.status === 200) {
                if (toastShow) {
                    toast.success("record successfully deleted")
                }
                return res
            }
        }).catch((err) => {
            toast.error("Failed to delete record" + err?.response?.data?.detail)
            return err
        })
    }

    const getSchemaData = async (TABLE_COLUMN_NAME) => {

        const result = await GET_SCHEMA_FIELDS(TABLE_COLUMN_NAME)
        return result

        // const apiPath = API_BASE_URL + "apexrest/v1/metadata/schema?object=" + TABLE_COLUMN_NAME
        // return http.get(apiPath)
        //     .then((res) => {
        //         if (res?.status === 200) {
        //             const apiData = res?.data
        //             if (apiData.length > 0) {
        //                 return (apiData[0]?.fields)
        //             } else {
        //                 return []
        //             }
        //         }
        //     }).catch((err) => {
        //         console.error("chart error", err)
        //     })
    }

    const getRecordData = (recordId, TABLE_COLUMN_NAME) => {
        if (!recordId) return
        const apiPath = API_BASE_URL + "apexrest/v1/records?object=" + TABLE_COLUMN_NAME + "&p1=Id&v1=" + recordId
        return http.get(apiPath).then((res) => {
            if (res?.data && res?.data.length > 0) {
                return res?.data[0]
            }
        }).catch((err) => {
            console.error("err detail ifno", err)
        })
    }

    const getFullRecordData = (TABLE_COLUMN_NAME) => {
        const apiPath = API_BASE_URL + "apexrest/v1/records?object=" + TABLE_COLUMN_NAME
        return http.get(apiPath).then((res) => {
            if (res?.data && res?.data.length > 0) {
                return res?.data
            }
        }).catch((err) => {
            console.error("err detail ifno", err)
        })
    }

    const getSingleRecord = (recordId) => {
        if (!recordId) return
        const apiPath = API_BASE_URL + "apexrest/v1/record?id=" + recordId
        return http.get(apiPath).then((res) => {
            return res
        }).catch((err) => {
            console.error("err detail ifno", err)
            return err
        })
    }

    const SET_GLOBAL_LOADER = (isLoading) => {
        dispatch(SetGlobalLoader(isLoading))
    }




    return { CHART_FINAL_COLORS, getMappingConfigurationFilters, handleDeleteRecord, getSchemaData, getRecordData, getSingleRecord, getFullRecordData, SET_GLOBAL_LOADER }

}

export default ProjectHelper