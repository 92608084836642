import {
    CHANGE_OLD_ACTIVE_PLACE_TYPE, CHANGE_SPREADSHEET_DATA_ARR, CLEAR_ALL_SLIDER_VALUE,
    CREATE_PROJECT_MODAL,
    EXCEL_EXPORT_LIST, IS_MAP_CLUSTER_VISIBLE, IS_STREET_VIEW_AVAILABLE,
    OPEN_MAP_VIEW_INSIDE_LIST, OPEN_SURVEY_TEMPLATE_MODAL, RELOAD_SPREADSHEET_DATA, SAVE_KENDO_GRAPH_CHART,
    SET_ACTIVE_COMMENT_ID, SET_ACTIVE_CLAUSE_INFO,
    SET_ACTIVE_USER_FILTER_DROPDOWN,
    SET_ADD_NEW_CHART,
    SET_ADVANCED_FILTERS_OPTIONS,
    SET_CLUSTER_SEARCHED_POINTS, SET_CUSTOM_FILTERS_OPTIONS,
    SET_PLACE_TYPE,
    SET_PROJECT_FILTERED_LIST_DATA, SET_PROJECT_FILTER_STATE,
    SET_PROJECT_LIST_DATA, SET_PROJECT_VIEW_TYPE,
    SET_SLIDER_VALUE, SET_USER_FILTERED_LIST, STREET_VIEW_SHOW,
    STREET_VIEW_SHOW_HIDE,
    TOGGLE_FILTER_SLIDER,
    OPEN_REQUIREMENT_SURVEY_MODAL,
    OPEN_SITE_SELECTION_MODAL,
    OPEN_GANTT_CHART_LIST_VIEW,

}
    from "../action-types/ProjectAction.types"

export const SetProjectListDataAction = (data) => {
    return {
        type: SET_PROJECT_LIST_DATA,
        data
    }
}
export const SetProjectFilteredListDataAction = (data) => {
    return {
        type: SET_PROJECT_FILTERED_LIST_DATA,
        data
    }
}

export const SetProjectFilterStateAction = (filterState) => {
    return {
        type: SET_PROJECT_FILTER_STATE,
        filterState
    }
}

export const SaveKendoGraphChartAction = (value) => {
    return {
        type: SAVE_KENDO_GRAPH_CHART,
        value
    }
}

export const SetStreetView3DDataAction = (streetViewObj) => {
    return {
        type: STREET_VIEW_SHOW,
        streetViewObj
    }
}

export const IsStreetViewAvailableAction = (isAvailable) => {
    return {
        type: IS_STREET_VIEW_AVAILABLE,
        isAvailable
    }
}


export const SetProjectViewType = (viewType) => {
    return {
        type: SET_PROJECT_VIEW_TYPE,
        viewType
    }
}

export const ResetSliderChartValue = () => {
    return {
        type: CLEAR_ALL_SLIDER_VALUE
    }
}

export const SetSliderValue = (conditonCase, value) => {
    return {
        type: conditonCase,
        value
    }
}

export const SetStreetViewShowHide = (isShow) => {
    return {
        type: STREET_VIEW_SHOW_HIDE,
        isShow
    }
}

export const SetOpenMapViewInsideList = (isShow) => {
    return {
        type: OPEN_MAP_VIEW_INSIDE_LIST,
        isShow
    }
}

export const SetClusterSearchedPoints = (points) => {
    return {
        type: SET_CLUSTER_SEARCHED_POINTS,
        points
    }
}

export const ChangeOldActivePlaceType = (placeType) => {
    return {
        type: CHANGE_OLD_ACTIVE_PLACE_TYPE,
        placeType
    }
}

export const SetCustomFilterAction = (customFilters) => {
    return {
        type: SET_CUSTOM_FILTERS_OPTIONS,
        customFilters
    }
}
export const CreateExcelSheetFromList = (isExport) => {
    return {
        type: EXCEL_EXPORT_LIST,
        isExport
    }
}

export const SetMapClusterViewVisible = (isShow) => {
    return {
        type: IS_MAP_CLUSTER_VISIBLE,
        isShow
    }
}

export const SetPlacesTypes = (placeTypes) => {
    return {
        type: SET_PLACE_TYPE,
        placeTypes
    }
}

export const SetAddNewChartAction = (payload) => {
    return {
        type: SET_ADD_NEW_CHART,
        payload
    }
}

export const ReloadSpreadSheetData = (reload) => {
    return {
        type: RELOAD_SPREADSHEET_DATA,
        reload
    }
}

export const ChangeSpreadSheetDataArr = (arr) => {
    return {
        type: CHANGE_SPREADSHEET_DATA_ARR,
        arr
    }
}

export const SetUserFiltersList = (arr) => {
    return {
        type: SET_USER_FILTERED_LIST,
        arr
    }
}

export const SetActiveUserFilterDropDown = (dropdownValue) => {
    return {
        type: SET_ACTIVE_USER_FILTER_DROPDOWN,
        dropdownValue
    }
}
export const ToggleFilterSlider = (isActive) => {
    return {
        type: TOGGLE_FILTER_SLIDER,
        isActive
    }
}

export const SetAdvancedFilterAction = (advanceFilter) => {
    return {
        type: SET_ADVANCED_FILTERS_OPTIONS,
        advanceFilter
    }
}

export const SetActiveCommentId = (commentId) => {
    return {
        type: SET_ACTIVE_COMMENT_ID,
        commentId
    }
}

export const SetActiveClausesInfo = (clauseTableColumnName,clauseRecordId, isClauseSliderOpen) => {
    return {
        type: SET_ACTIVE_CLAUSE_INFO,
        clauseTableColumnName,clauseRecordId,isClauseSliderOpen
    }
}

export const OpenCreateProjectModal = (createProjectObj) => {
    return {
        type: CREATE_PROJECT_MODAL,
        createProjectObj
    }
}
export const OpenRequirementSurveyModal = (requirementObj) => {
    return {
        type: OPEN_REQUIREMENT_SURVEY_MODAL,
        requirementObj
    }
}
export const OpenSurveyTemplateModal = (modalObj) => {
    return {
        type: OPEN_SURVEY_TEMPLATE_MODAL,
        modalObj
    }
}
export const OpenSiteSelectionModal = (modalObj) => {
    return {
        type: OPEN_SITE_SELECTION_MODAL,
        modalObj
    }
}

export const OpenGanttChartListView = (modalObj) => {
    return {
        type: OPEN_GANTT_CHART_LIST_VIEW,
        modalObj
    }
}