import { SAVE_BRUTEFORCE_TABLE, SET_AVG_AFTER_COMMUTE_TIME, SET_AVG_BEFORE_COMMUTE_TIME, SET_EMPLOYEE_LIST, SET_FULL_BUILDINGS_DATA, SET_OPTIMIZATION_SEARCH_LAT_LNG, SET_PAYLOAD_SITES_DATA, SET_SELECTED_BUILDINGS } from "../action-types/distanceMatrixAction.types"

const initialState = {
    employeeList: [],
    selectedBuildings: [],
    avgBeforeTime: [],
    avgAfterTime: [],
    saveBruteForceTable: {},
    payloadDataSites: [],
    allBuildings: [],
    mapSearchLatLong: {}
}

const distanceMatrix = (state = initialState, action) => {
    switch (action.type) {
        case SET_EMPLOYEE_LIST:
            return {
                ...state,
                employeeList: action.data
            }
        case SET_SELECTED_BUILDINGS:
            return {
                ...state,
                selectedBuildings: action?.buildingIds
            }
        case SET_AVG_BEFORE_COMMUTE_TIME:
            let mergeBefore = [...state?.avgBeforeTime, ...action?.data]
            return {
                ...state,
                avgBeforeTime: mergeBefore
            }
        case SET_AVG_AFTER_COMMUTE_TIME:
            let merge = [...state?.avgAfterTime, ...action?.data]
            return {
                ...state,
                avgAfterTime: merge
            }
        case SAVE_BRUTEFORCE_TABLE:
            return {
                ...state,
                saveBruteForceTable: action?.modalObj
            }
        case SET_PAYLOAD_SITES_DATA:
            return {
                ...state,
                payloadDataSites: action?.sitesData
            }
        case SET_FULL_BUILDINGS_DATA:
            return {
                ...state,
                allBuildings: action?.data
            }
        case SET_OPTIMIZATION_SEARCH_LAT_LNG:
            return {
                ...state,
                mapSearchLatLong: action?.latlng
            }
        default:
            return state
    }

}

export default distanceMatrix