import React, { Fragment, useContext, useEffect, useState } from "react";
import { ListGroup, Dropdown, Media } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import { ConfigContext } from "../../../../contexts/ConfigContext";
import useAuth from "../../../../hooks/useAuth";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import avatar1 from "../../../../assets/images/user/avatar-1.jpg";
import http from "../../../../helper/Interceptors";
import {
  GET_NOTIFICATION_LIST,
  GET_USER_DETAILS,
} from "../../../../constants/apiPaths";
import { toast } from "react-toastify";
import CommentWidgetSidebar from "../../../../components/common/CommentWidgetSidebar";
import { useDispatch, useSelector } from "react-redux";
import { ToggleFilterSlider } from "../../../../redux/actions/dataGridActions";
import { Drawer } from "@mui/material";
import {
  SetNotificationData,
  SetReloadNotificationApi,
} from "../../../../redux/actions/CommonActions";
import NotificationModal from "../../../../components/common/NotificationModal";
import FeedbackDrawer from "../../../../components/common/FeedBackDrawer";
import CommentWidgetSidebarDrawer from "../../../../components/common/CommentWidgetSidebarDrawer";
import { SetActiveCommentId } from "../../../../redux/actions/projectActions";
import ChangeLogServerNotification from "../../../../components/common/ChangeLogServer";
import S3_FILE_HELPER from "../../../../constants/S3FileUploadHelper";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';
import TermsAndConditionsModal from "../../../../components/Auth/TermsAndConditionsModal";
import ClauseSidebarDrawer from "../../../../components/common/ClauseSidebarDrawer";
import { HANDLE_LOGOUT_USER } from "../../../../constants/commonFunc";

const NavRight = () => {
  const { GET_ALL_IMAGES_PUBLIC_URL, DELETE_S3_FILE } = S3_FILE_HELPER();

  const toggleFilterSlider = useSelector(
    (state) => state?.kendoDataGrid?.toggleFilterSlider
  );
  const dispatchRedux = useDispatch();

  const notificationData = useSelector(
    (state) => state?.global?.notificationData
  );
  const activeCommentId = useSelector(
    (state) => state?.project?.activeCommentId
  );
  const userInfo = useSelector((state) => state?.user?.userInfo);
  const [userDetails, setUserDetails] = useState();

  const location = useLocation();
  const configContext = useContext(ConfigContext);
  const { logout } = useAuth();
  const { rtlLayout, tabColor } = configContext.state;
  const [listOpen, setListOpen] = useState(false);
  const [feedbackModal, setFeedbackModal] = useState(false);

  const [commentModalOpen, setCommentModalOpen] = useState(false);
  const [activityModalOpen, setActivityModalOpen] = useState(false);
  const currentCompany = userInfo?.current_company;
  const [folderN, setFolderN] = useState("");
  const [userImg, setUserImg] = useState("");


  const handleComm = () => {
    setCommentModalOpen(true);
  };
  useEffect(() => {
    http
      .get(GET_USER_DETAILS)
      .then((res) => {
        setUserDetails(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        toast(err.message);
      });
  }, []);
  const handleGetAllImagesInsideFolder = async () => {
    const result = await GET_ALL_IMAGES_PUBLIC_URL(folderN);
    setUserImg(result[0]?.publicPath);
  };

  const handleLogout = async () => {
    try {
      HANDLE_LOGOUT_USER()
      // await logout();
    } catch (err) {
      console.error(err);
    }
  };

  const ToggleSlider = (isActive) => {
    dispatchRedux(ToggleFilterSlider(isActive));
  };

  const handleGetNotificationsList = () => {
    http
      .get(GET_NOTIFICATION_LIST)
      .then((res) => {
        dispatchRedux(SetNotificationData(res?.data));
        dispatchRedux(SetReloadNotificationApi(false));
      })
      .catch((err) => {
        console.error("err notification list", err);
      });
  };

  useEffect(() => {
    handleGetNotificationsList();
  }, []);

  const handleFeedback = () => {
    setFeedbackModal(!feedbackModal);
  };
  const handleActivityLog = () => {
    setActivityModalOpen(true);
  };
  useEffect(() => {
    const folderName = userInfo?.id
      ? currentCompany + "/" + userInfo?.id
      : currentCompany;
    setFolderN(folderName);
  }, [userDetails, currentCompany, folderN]);

  useEffect(() => {
    if (!currentCompany) return;
    handleGetAllImagesInsideFolder();
  }, [folderN]);

  return (
    <React.Fragment>
      <TermsAndConditionsModal />
      <ListGroup
        as="ul"
        bsPrefix=" "
        className="navbar-nav ml-5 pcoded-header d-flex flex-row topIconGrouping"
        id="navbar-right"
      >
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip>Activity log</Tooltip>}
        >
          <ListGroup.Item
            as="li"
            bsPrefix=" "
            onClick={handleActivityLog}
          >
            <div class="dropdown">
              <i class="fa-solid fa-bell mr-0"></i>
            </div>
          </ListGroup.Item>
        </OverlayTrigger>

        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip>Feedback</Tooltip>}
        >
          <ListGroup.Item
            as="li"
            bsPrefix=" "
            onClick={handleFeedback}
          >
            <div class="dropdown">
              <i class="fa-solid fa-paper-plane mr-0"></i>
            </div>
          </ListGroup.Item>
        </OverlayTrigger>

        <OverlayTrigger placement="left" overlay={<Tooltip>Settings</Tooltip>}>
          <ListGroup.Item as="li" bsPrefix=" " style={{ marginRight: "0px !important" }}>
            <Dropdown alignRight={!rtlLayout} className="drp-user">
              <Dropdown.Toggle
                as={Link}
                variant="link"
                to="#"
                id="dropdown-basic"
              >
                <i class="fa-solid fa-gear mr-0"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu alignRight className="profile-notification">
                <div
                  className={`pro-head`}
                  style={{ background: tabColor?.replace("tab-color-", "#") }}
                >
                  <img
                    src={userImg ? userImg : avatar1}
                    className="img-radius"
                    alt="User Profile"
                  />
                  <span>{userInfo?.full_name}</span>
                  <p className="userName">{userInfo?.username}</p>

                  <Link
                    to="/"
                    className="dud-logout"
                    title="Logout"
                    onClick={handleLogout}
                  >
                    <i className="feather icon-log-out" />
                  </Link>
                </div>
                <ListGroup
                  as="ul"
                  bsPrefix=" "
                  variant="flush"
                  className="pro-body"
                >
                  <ListGroup.Item as="li" bsPrefix=" ">
                    <a onClick={() => window.open("/userprofile", "_blank")} className="dropdown-item">
                      <i className="feather icon-user" /> My Account
                    </a>
                  </ListGroup.Item>
                  <ListGroup.Item as="li" bsPrefix=" ">
                    <a onClick={() => window.open("/settings", "_blank")} className="dropdown-item">
                      <i class="fa-solid fa-sliders"></i> My Settings
                    </a>
                  </ListGroup.Item>
                  <ListGroup.Item as="li" bsPrefix=" ">
                    <a onClick={() => window.open("/configuration", "_blank")} className="dropdown-item">
                      <i class="fa-solid fa-screwdriver-wrench"></i>
                      App Configuration
                    </a>
                  </ListGroup.Item>
                </ListGroup>
              </Dropdown.Menu>
            </Dropdown>
          </ListGroup.Item>
        </OverlayTrigger>

      </ListGroup>

      {/* <Drawer
        anchor={"right"}
        open={commentModalOpen}
        onClose={() => setCommentModalOpen(false)}
      >
        <div style={{ width: 1000 }} className="p-4 pt-5">
          <CommentWidgetSidebar isOpen={commentModalOpen} />
        </div>
      </Drawer> */}

      <Drawer
        anchor={"right"}
        open={activityModalOpen}
        onClose={() => setActivityModalOpen(false)}
      >
        <div style={{ width: "75vw" }} className="p-4 pt-5">
          <div className="mb-3 d-flex justify-content-between">
            <h5>Activity log</h5>
            <OverlayTrigger overlay={<Tooltip>Close</Tooltip>}>
              <button className="btn cPadding  m-0 px-3 btn-secondary" onClick={() => setActivityModalOpen(false)}>
                <i className="fa fa-close mr-0" />
              </button>
            </OverlayTrigger>
          </div>
          <ChangeLogServerNotification />
        </div>
      </Drawer>
      <ClauseSidebarDrawer />
      <FeedbackDrawer
        setFeedbackModal={setFeedbackModal}
        feedbackModal={feedbackModal}
      />
    </React.Fragment>
  );
};

export default NavRight;
