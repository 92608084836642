import { process } from '@progress/kendo-data-query';
import { GOOGLE_MAP_KEY } from '../../constants/commonFunc';


export const UPDATE_PROJECT_FIELDS = ["Heads__c", "Target_Heads__c", "Seats__c", "Target_Seats__c",
    "Annual_Base_Rent__c", "Target_Annual_Base_Rent__c",
    "BU_Contact_Email__c", "Client_Broker_Email__c", "Listing_Broker_Email__c", "General_Contractor_Email__c",
    "Business_Case_Summary__c", "Site_Selection_Summary__c",

]


export const MAX_TAKE = 1000000000
export const MAX_RADIUS = 20000000
export const STREET_VIEW_MAX_DISTANCE = 50
export const USA_DEFAULT_LATITUDE = 37.09024
export const USA_DEFAULT_LONGTITUDE = -95.712891
export const DEFAULT_STREET_VIEW_MODAL_WIDTH = 600
export const DEFAULT_STREET_VIEW_MODAL_HEIGHT = 400
export const DEFAULT_CLUSTER_VIEW_WIDTH = 600
export const DEFAULT_CLUSTER_VIEW_HEIGHT = 400

export const GroupByResultsByState = (arr, keyField = "area", groupBy = "state") => {
    const res = [...arr.reduce((map, current) => {
        const state = current[groupBy];
        const grouped = map.get(state);
        if (!grouped) {
            map.set(state, { ...current });
        } else {
            if (keyField === "area") {
                map.set(state, { ...grouped, area: grouped["area"] + current["area"] })
            }
            if (keyField === "heads") {
                map.set(state, { ...grouped, heads: grouped["heads"] + current["heads"] })
            }
            if (keyField === "seats") {
                map.set(state, { ...grouped, seats: grouped["seats"] + current["seats"] })
            }
        }
        return map;
    }, new Map).values()
    ];
    // console.log("group by", res)
    return res;
}

export const groupByStateData = (groupKeyName, projectList, dataState, groupBy = "state") => {
    const projectListFinal = process(projectList, dataState)
    if (!projectListFinal || projectListFinal?.data.length === 0) return
    const total = GroupByResultsByState(projectListFinal?.data, groupKeyName, groupBy)
    if (total && Array.isArray(total) && total.length > 0) {
        const formattedResultPieChart = total.map((key) => {
            return {
                category: key[groupBy],
                value: key[groupKeyName]
            }
        })
        return formattedResultPieChart
    }
}

export const TopWiseOrBottomWiseArr = [
    { name: "All", value: "all" },
    { name: "Top", value: "top" },
    { name: "Bottom", value: "bottom" },
]

export const arePointsNear = (checkPoint, centerPoint, km = MAX_RADIUS) => {
    var ky = 40000 / 360;
    var kx = Math.cos(Math.PI * centerPoint.lat / 180.0) * ky;
    var dx = Math.abs(centerPoint.lng - checkPoint.lng) * kx;
    var dy = Math.abs(centerPoint.lat - checkPoint.lat) * ky;
    return Math.sqrt(dx * dx + dy * dy) <= km;
}

// http://jsfiddle.net/zd1fsh2t/3/

export const CheckStreetViewAvailable = async (lat, lng) => {
    const apiPath = `https://maps.googleapis.com/maps/api/streetview/metadata?size=100x100&location=${lat},${lng}&fov=90&heading=235&pitch=10&radius=${STREET_VIEW_MAX_DISTANCE}&key=${GOOGLE_MAP_KEY}`
    return await fetch(apiPath)
        .then(response => response.json())
        .then((data) => {
            if (data.status === "OK") {
                return true
            }
            if (data.status === "ZERO_RESULTS") {
                return false
            }
        });

}

// chart.js, echart and highchart open source


// get all unique values 
export const OnlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
}

export const createOptionListBasedOnType = (arr, type) => {
    if (!arr) return []
    const typeUniqueOptions = arr.map((key) => key[type]).filter(OnlyUnique)
    return typeUniqueOptions.map((key) => {
        return (
            {
                label: key,
                value: key
            }
        )
    })
}

export const getFilteredListResult = (arr, filtersOption) => {

    let temp = arr

    if (filtersOption.hasOwnProperty('type')) {
        const typeOptions = filtersOption?.type.map((key) => key?.value)
        if (typeOptions.length > 0) {
            temp = temp.filter((key) => typeOptions.includes(key?.type))
        }

    }
    if (filtersOption.hasOwnProperty('use_type')) {
        const typeOptions = filtersOption?.use_type.map((key) => key?.value)
        if (typeOptions.length > 0) {
            temp = temp.filter((key) => typeOptions.includes(key?.use_type))
        }
    }
    if (filtersOption.hasOwnProperty('state')) {
        const typeOptions = filtersOption?.state.map((key) => key?.value)
        if (typeOptions.length > 0) {
            temp = temp.filter((key) => typeOptions.includes(key?.state))
        }
    }
    if (filtersOption.hasOwnProperty('status')) {
        const typeOptions = filtersOption?.status.map((key) => key?.value)
        if (typeOptions.length > 0) {
            temp = temp.filter((key) => typeOptions.includes(key?.status))
        }
    }
    if (filtersOption.hasOwnProperty('name')) {
        if (!!filtersOption?.name) {
            const nameField = filtersOption?.name.toLowerCase()
            temp = temp.filter((key) => {
                return ((!!key?.name ? key?.name.toLowerCase().includes(nameField) : false)
                    || (!!key?.street ? key?.street.toLowerCase().includes(nameField) : false)
                    || (!!key?.zip ? key?.zip.includes(nameField) : false)
                )
            }
            )
        }
    }
    if (filtersOption.hasOwnProperty('street')) {
        if (!!filtersOption?.street) {
            temp = temp.filter((key) => key?.street.toLowerCase().includes(filtersOption?.street.toLowerCase()))
        }
    }
    if (filtersOption.hasOwnProperty('city')) {
        const typeOptions = filtersOption?.city.map((key) => key?.value)
        if (typeOptions.length > 0) {
            temp = temp.filter((key) => typeOptions.includes(key?.city))
        }

    }
    if (filtersOption.hasOwnProperty('area')) {
        if (!!filtersOption?.area && filtersOption?.area?.length > 1 && filtersOption?.area[1] > 0) {
            temp = temp.filter((key) => {
                return (key?.area >= filtersOption?.area[0]) && (key?.area <= filtersOption?.area[1])
            })
        }
    }

    if (filtersOption.hasOwnProperty('seats')) {
        if (!!filtersOption?.seats && filtersOption?.seats?.length > 1 && filtersOption?.seats[1] > 0) {
            temp = temp.filter((key) => {
                return (key?.seats >= filtersOption?.seats[0]) && (key?.seats <= filtersOption?.seats[1])
            })
        }
    }

    if (filtersOption.hasOwnProperty('heads')) {
        if (!!filtersOption?.heads && filtersOption?.heads?.length > 1 && filtersOption?.heads[1] > 0) {
            temp = temp.filter((key) => {
                return (key?.heads >= filtersOption?.heads[0]) && (key?.heads <= filtersOption?.heads[1])
            })
        }
    }

    if (filtersOption?.hasOwnProperty("planned_start_date")) {

        const filterPlannedStartDate = filtersOption?.planned_start_date ? filtersOption?.planned_start_date.toString() : ""

        // when checking back date 
        if (filterPlannedStartDate.includes("-")) {
            temp = temp.filter((key) => {
                const listDate = new Date(key?.planned_start_date)
                const todaysDate = new Date()
                const conditionDate = GET_BACK_DATE(filterPlannedStartDate.replace("-", ""))
                console.log({ listDate }, { conditionDate }, "negative check")
                /* if you are checking for back date like "30 Days Back" 
                then it should be greater than conditon date and less than current date 
                */
                return ((listDate >= conditionDate) && (listDate <= todaysDate))
            })
        }
        // when checking next date 
        if (filterPlannedStartDate.includes("+")) {
            temp = temp.filter((key) => {
                const listDate = new Date(key?.planned_start_date)
                const todaysDate = new Date()

                const conditionDate = GET_NEXT_DATE(filterPlannedStartDate.replace("+", ""))
                console.log({ listDate }, { conditionDate }, "positive check")
                /* if you are checking for next date like "30 Days Next" 
                then it should be less than conditon date and greater than current date 
                */
                return ((listDate <= conditionDate) && (listDate >= todaysDate))
            })
        }
    }

    if (filtersOption?.hasOwnProperty("planned_end_date")) {

        const filterPlannedEndDate = filtersOption?.planned_end_date ? filtersOption?.planned_end_date.toString() : ""

        // when checking back date 
        if (filterPlannedEndDate.includes("-")) {
            temp = temp.filter((key) => {
                const listDate = new Date(key?.planned_end_date)
                const todaysDate = new Date()
                const conditionDate = GET_BACK_DATE(filterPlannedEndDate.replace("-", ""))
                console.log({ listDate }, { conditionDate }, "negative check")
                /* if you are checking for back date like "30 Days Back" 
                then it should be greater than conditon date and less than current date 
                */
                return ((listDate >= conditionDate) && (listDate <= todaysDate))
            })
        }
        // when checking next date 
        if (filterPlannedEndDate.includes("+")) {
            temp = temp.filter((key) => {
                const listDate = new Date(key?.planned_end_date)
                const todaysDate = new Date()

                const conditionDate = GET_NEXT_DATE(filterPlannedEndDate.replace("+", ""))
                console.log({ listDate }, { conditionDate }, "positive check")
                /* if you are checking for next date like "30 Days Next" 
                then it should be less than conditon date and greater than current date 
                */
                return ((listDate <= conditionDate) && (listDate >= todaysDate))
            })
        }
    }

    console.log("filtered Result final", temp)
    return temp

}

export const GET_BACK_DATE = (numberOfDaysToMinus) => {
    const someDate = new Date();
    var result = someDate.setDate(someDate.getDate() - numberOfDaysToMinus);
    return new Date(result)
    // console.log(new Date(result))
}

export const MultiplePlacesTypeMap = [
    "airport",
    "university",
    "public_transit",
    "traffic",
    "kml_layer",
]

export const IsDateGreaterThanToday = (dateFormat) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    if (!dateFormat) return false
    const givenDate = new Date(dateFormat); //dd-mm-YYYY
    if (givenDate > today) return true
    return false
}

export const updateItem = (item, data) => {
    let index = data.findIndex((record) => record._uid === item._uid);
    data[index] = item;
    console.log("item edit", item)
    return data;
};
export function isNumeric(str) {
    if (typeof str != "string") return false // we only process strings!  
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

export const CommaFormattedNumber = (number, digit = 2, fixedDigitShow = false) => {
    let num = Number(number)
    if (!num && fixedDigitShow) return parseFloat("0").toFixed(digit)
    if (!num) return 0
    if (digit === 0) return Math.ceil(parseFloat(num).toFixed(digit))?.toLocaleString("en")
    if (fixedDigitShow) {
        return num.toFixed(digit).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        // return (num?.toFixed(digit))?.toLocaleString("en")
    } else if (!!num) {
        return parseFloat(num?.toFixed(digit))?.toLocaleString("en")
    }
}

export const nFormatter = (num, digits = 1) => {
    let symbolNegative = ""
    if (num?.toString()?.includes("-")) {
        symbolNegative = "-"
        num = parseFloat(num?.toString()?.replace("-", ""))
    }

    if (!num) return 0
    const lookup = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "K" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "B" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: "P" },
        { value: 1e18, symbol: "E" }
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup.slice().reverse().find(function (item) {
        return num >= item.value;
    });
    if (item?.value === 1) return num.toLocaleString("en")
    if (num < 10000) return num.toLocaleString("en")
    return item ? symbolNegative + (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
}

const columns = [{
    title: 'Name',
    field: 'name',
    show: true,
}, {
    title: 'Project Type',
    field: 'type',
    show: true,

}, {
    title: 'Area',
    field: 'area',
    show: true,
}, {
    title: 'Ownership Type',
    field: 'ownership_type',
    show: true,
}, {
    title: 'Location',
    field: 'street',
    show: true,
},
{
    title: 'Occupancy',
    field: 'seats',
    show: true,
},
{
    title: 'Schedule',
    field: 'planned_start_date',
    show: true,
},
{
    title: 'Status',
    field: 'status',
    show: true,
},

];
export default columns;

export const CHART_WIDTH = 500
export const CHART_HEIGHT = 350

export const DEFAULT_ADD_NEW_CHART = [
    {
        position: 5,
        chartViewType: "Radar",
        groupBy: "state",
        summarizeBy: "area",
        countWise: "top"
    }
]

export const GET_NEXT_DATE = (numberOfDaysToAdd) => {
    let someDate = new Date();
    let finalResult = someDate.setDate(someDate.getDate() + parseInt(numberOfDaysToAdd));
    // console.log(new Date(finalResult))
    return new Date(finalResult)

}

export const PROJECT_ATTRIBUTE_FILTER_SAVE = "Projects"
export const PROJECT_TYPE_COLUMN_FOR_FILTER_SAVE = "Projects"
export const RANGE_SLIDER_LABEL = [
    {
        value: -50,
        label: '-50',
    },
    {
        value: -100,
        label: '-100',
    },
    {
        value: 0,
        label: '0',
    },

    {
        value: 50,
        label: '50',
    },
    {
        value: 100,
        label: '100',
    },
];

export const getColorCodeClassByParam = (budgetColor, projectColor, keyDateColor, leaseColor, buildingColor, params) => {

    if (params.pathname === '/buildings') {
        return buildingColor
    }
    else if (params.pathname === '/leases') {
        return leaseColor
    }
    else if (params.pathname === '/keydates') {
        return keyDateColor
    }
    else if (params.pathname === '/budgettracking') {
        return budgetColor
    }
    else if (params.pathname === '/projects') {
        return projectColor
    }

}

export const getColorCodeClassByParamDetail = (budgetColor, projectColor, keyDateColor, leaseColor, buildingColor, params) => {
    if (params.pathname.includes('/building/') === true) {
        return buildingColor
    }
    else if (params.pathname.includes('/lease/') === true) {
        return leaseColor
    }
    else if (params.pathname.includes('/keydate/') === true) {
        return keyDateColor
    }
    else if (params.pathname.includes('/budgettracking/') === true) {
        return budgetColor
    }
    else if (params.pathname.includes('/project/') === true) {
        return projectColor
    }

}

//   export const getColorCodeClassByParam2 = (budgetColor,projectColor,keyDateColor,leaseColor,buildingColor,getParam ) => {
//    debugger
//     let color = '';
//     if (getParam === '/buildings' || getParam.includes('/building/')) {
//       color = buildingColor;
//     } else if (getParam === '/leases' || getParam.includes('/lease/')) {
//       color = leaseColor;
//     } else if (getParam === '/keydates' || getParam.includes('/keydate/')) {
//       color = keyDateColor;
//     } else if (getParam === '/budgettracking' || getParam.includes('/budgettracking/')) {
//       color = budgetColor;
//     } else if (getParam === '/projects' || getParam.includes('/project/')) {
//       color = projectColor;
//     }
//     localStorage.setItem("navSideColor",color );

//     return color;

//   };