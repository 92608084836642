import { IS_EDITABLE_FIELD, CHART_ACTIVE_FIELDS_OPTIONS, CHART_ALL_FIELD_OPTIONS, CHART_SECTIONS, CHART_SECTION_RERENDER, KPI_SECTION_RERENDER, SET_FIELD_ORDERING_LIST, SET_CHART_DETAIL_FULL_INFO, SET_CHART_KPIS, SET_USER_SAVED_LAYOUT, SET_CHART_LIST_DATA, SET_FIELDS_TYPE_DATA, SET_USER_CHART_SAVED_LAYOUT, SET_INTERLINKING_PROJECT_IDS, SET_CHART_LOADED_ONCE }
    from "../action-types/chartDetailAction.types"

const initialState = {
    listData: [], // set all list items data in this array
    fieldsTypeData: [],
    isEditable: false, // to enable the edit option
    fullInfo: {}, // project detail full info
    fieldContainers: [], // get all section created in project detail page
    chartInfoBySections: [],
    rerenderSection: false,
    userSavedLayouts: [], // user saved layout options
    interLinkingProjectIds: {}, // for chart interlinking ids 
    chartLoadedOnce: false


}

const chartDetailReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CHART_LIST_DATA:
            return {
                ...state,
                listData: action?.data
            }

        case SET_FIELDS_TYPE_DATA:
            return {
                ...state,
                fieldsTypeData: action?.data
            }

        case SET_CHART_DETAIL_FULL_INFO:
            return {
                ...state,
                fullInfo: action?.data
            }

        case CHART_SECTIONS:
            return {
                ...state,
                fieldContainers: action?.data
            }
        case CHART_SECTION_RERENDER:
            return {
                ...state,
                rerenderSection: action?.isRender
            }

        case KPI_SECTION_RERENDER:
            return {
                ...state,
                rerenderSection: action?.isRender
            }
        case SET_USER_CHART_SAVED_LAYOUT:
            return {
                ...state,
                userSavedLayouts: action?.chartData
            }

        case IS_EDITABLE_FIELD:
            return {
                ...state,
                isEditable: action?.isEdit
            }
        case SET_INTERLINKING_PROJECT_IDS:
            return {
                ...state,
                interLinkingProjectIds: action?.projectIds

            }

        case SET_CHART_LOADED_ONCE:
            return {
                ...state,
                chartLoadedOnce: action?.isLoaded
            }

        default:
            return state
    }

}

export default chartDetailReducer