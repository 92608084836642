import { Fragment } from "react"

const DataNotFoundMessage = ({ message }) => {
    return (
        <Fragment>
            <div className="col-md-12 noDataFound text-center">
                <i className="fa fa-exclamation-circle notFoundIcon" />
                <p className="mt-2 mb-0">
                    {message}
                </p>
            </div>
        </Fragment>
    )
}

export default DataNotFoundMessage