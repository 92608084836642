import { IS_EDITABLE_FIELD, PROJECT_ACTIVE_FIELDS_OPTIONS, PROJECT_ALL_FIELD_OPTIONS, PROJECT_SECTIONS, PROJECT_SECTION_RERENDER, SET_FIELD_ORDERING_LIST, SET_INTERLINKING_PROJECT_IDS, SET_PROJECT_DETAIL_FULL_INFO, SET_PROJECT_KPIS, SET_USER_SAVED_LAYOUT } from "../action-types/ProjectDetailAction.types"

const initialState = {
    isEditable: false, // to enable the edit option
    fullInfo: {}, // project detail full info
    fieldContainers: [], // get all section created in project detail page
    activeInfoFields: [], // checked fields section in project detail
    rerenderSection: false,
    KPIsList: [], // keypoints saved similar to list page 
    userSavedLayouts: [], // user saved layout options,
    interLinkingProjectIds: {} // for chart interlinking ids 

}

const projectDetailReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PROJECT_DETAIL_FULL_INFO:
            return {
                ...state,
                fullInfo: action?.data
            }

        case PROJECT_SECTIONS:
            return {
                ...state,
                fieldContainers: action?.data
            }
        case PROJECT_SECTION_RERENDER:
            return {
                ...state,
                rerenderSection: action?.isRender
            }

        case PROJECT_ACTIVE_FIELDS_OPTIONS:
            return {
                ...state,
                activeInfoFields: action.data
            }
        case SET_PROJECT_KPIS:
            return {
                ...state,
                KPIsList: action?.data
            }
        case SET_USER_SAVED_LAYOUT:
            return {
                ...state,
                userSavedLayouts: action?.data
            }

        case IS_EDITABLE_FIELD:
            return {
                ...state,
                isEditable: action?.isEdit
            }
        case SET_INTERLINKING_PROJECT_IDS:
            return {
                ...state,
                interLinkingProjectIds: action?.projectIds
            }

        default:
            return state
    }

}

export default projectDetailReducer