import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

import { FirebaseProvider } from "./contexts/FirebaseContext";
//import { JWTProvider } from "./contexts/JWTContext";
//import { Auth0Provider } from "./contexts/Auth0Context";
import "../src/assets/scss/layouts.scss";
import routes, { renderRoutes } from "./routes";
import { BASENAME } from "./config/constant";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import '@progress/kendo-theme-default/dist/all.css'
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoginSuccessAction } from "./redux/actions/userActions";
import { GOOGLE_MAP_KEY, HANDLE_LOGOUT_USER } from "./constants/commonFunc";
import { useLoadScript } from "@react-google-maps/api";
import { SetGoogleMapApiLoaded } from "./redux/actions/CommonActions";
import KendoLoader from "./pages/DynamicDataGrid/KendoLoader";
import USER_HELPER from "./helper/api/UserHelper";

const App = () => {
  const libraries = ["places"];
  const dispatch = useDispatch();
  const defaultZoom = useSelector((state) => state?.global?.globalZoom)

  useEffect(() => {
    const user = localStorage.getItem("user_info");
    if (!!user) {
      try {
        const parsedUser = JSON.parse(user);
        dispatch(LoginSuccessAction(parsedUser));
      } catch {
        console.error("failed user info");
      }
    }
  }, []);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLE_MAP_KEY,
    libraries,
    version: "beta",
  });

  // const { GET_SCHEMA_FIELDS } = USER_HELPER();

  useEffect(() => {
    dispatch(SetGoogleMapApiLoaded(isLoaded));
  }, [isLoaded]);

  // useEffect(() => {
  //   const user = localStorage.getItem("user_info");
  //   if (!!user) {
  //     GET_SCHEMA_FIELDS("Project__c");
  //   }
  // }, []);

  // useEffect(() => {
  //   console.log("clicked")
  //   // window.addEventListener('beforeunload', handleTabClosing)
  //   window.addEventListener('unload', handleTabClosing)

  //   return () => {
  //     // window.removeEventListener('beforeunload', handleTabClosing)
  //     window.removeEventListener('unload', handleTabClosing)
  //   }
  // })

  // const handleTabClosing = (event) => {
  //   HANDLE_LOGOUT_USER()
  // }

  return (
    <React.Fragment>
      <Router basename={BASENAME}>
        <div className="hyprespace-app" style={{ zoom: defaultZoom }}>
          <FirebaseProvider>{renderRoutes(routes)}</FirebaseProvider>
          <ToastContainer />
          <KendoLoader />
        </div>
      </Router>
    </React.Fragment>
  );
};

export default App;
