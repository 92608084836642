import { DATA_GRID_DEFAULT_STATE } from "../../constants/kendoDataGridConstants"
import { USA_DEFAULT_LATITUDE, USA_DEFAULT_LONGTITUDE } from "../../pages/ProjectPage/projectConstants"
import { GET_SCHEMA_LIST_OPTIONS, IMPORT_MASS_CSV_UPLOAD, RERENDER_TREE_VIEW, SET_MAPPED_KEY_ARR } from "../action-types/ImportAction.types"
import { SAVE_KENDO_GRAPH_CHART, SET_GLOBAL_SLIDER_VALUE, SET_CLUSTER_SEARCHED_POINTS, SET_SLIDER_VALUE_FIRST, SET_SLIDER_VALUE_SECOND, SET_SLIDER_VALUE_THIRD, SET_PROJECT_FILTER_STATE, SET_PROJECT_LIST_DATA, SET_PROJECT_VIEW_TYPE, STREET_VIEW_SHOW, CLEAR_ALL_SLIDER_VALUE, STREET_VIEW_SHOW_HIDE, OPEN_MAP_VIEW_INSIDE_LIST, CHANGE_OLD_ACTIVE_PLACE_TYPE, SET_CUSTOM_FILTERS_OPTIONS, SET_PROJECT_FILTERED_LIST_DATA, IS_STREET_VIEW_AVAILABLE, EXCEL_EXPORT_LIST, IS_MAP_CLUSTER_VISIBLE, SET_PLACE_TYPE, SET_ADD_NEW_CHART, RELOAD_SPREADSHEET_DATA, CHANGE_SPREADSHEET_DATA_ARR } from "../action-types/ProjectAction.types"

const initialState = {
    importCSVFileObj: [],
    schemaListOptions: [],
    isTreeRerendering: false,
    userMappedKeys: []
}

const importReducer = (state = initialState, action) => {
    switch (action.type) {
        case IMPORT_MASS_CSV_UPLOAD:
            return {
                ...state,
                importCSVFileObj: action.csvObject
            }
        case GET_SCHEMA_LIST_OPTIONS:
            return {
                ...state,
                schemaListOptions: action?.schemaList
            }
        case RERENDER_TREE_VIEW:
            return {
                ...state,
                isTreeRerendering: action?.isTrue
            }
        case SET_MAPPED_KEY_ARR:
            return {
                ...state,
                userMappedKeys: action?.arr
            }
        default:
            return state
    }

}

export default importReducer