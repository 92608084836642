import { Drawer } from "@mui/material";
import React, { Fragment, useState, useEffect } from "react";
import { Form, Col, Row, Button, OverlayTrigger, Tooltip } from "react-bootstrap";

import { UPDATE_FEEDBACK } from "../../constants/apiPaths";
import { useSelector } from "react-redux";
import { convertToRaw, EditorState } from "draft-js";
// import draftToHtml from "draftjs-to-html";

import http from "../../helper/Interceptors";
import { toast } from "react-toastify";
import UploadFileProjectDetail from "../../pages/ProjectDetail/UploadFileSection";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { getUserIPAddress } from "../../constants/commonFunc";
import draftToHtml from "draftjs-to-html";
const FeedbackDrawer = ({ onClose, feedbackModal, setFeedbackModal }) => {
  const userInfo = useSelector((state) => state?.user?.userInfo);

  const handleCloseModalOpen = () => {
    setFeedbackModal(false);
    if (!!onClose) {
      onClose();
    }
  };
  const [image, setImage] = useState();
  const onImageUpload = (file) => {
    let ImageFile = file[0]?.file_content;
    setImage(ImageFile);
  };

  const [feedBack, setFeedBack] = useState(null);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [text, setText] = useState();
  const [selectedRich, setSelectedRich] = useState(true);

  const onEditorStateChange = function (editorState) {
    setEditorState(editorState);
    // let text = editorState.getCurrentContent().getPlainText("\u0001");
    setText(sendContent());
    setFeedBack({
      ...feedBack,
      Description__c: sendContent(),
      Link__c: fullLink,
    });
  };
  useEffect(() => {
    setSubmitButtonText("Submit");
  }, [feedbackModal]);

  const [submitButtonText, setSubmitButtonText] = useState("Submit");

  const handleRichtext = () => {
    setSelectedRich(!selectedRich);
    setSubmitButtonText("Submit");
  };

  const [ip, setIP] = useState("");

  const getUserDetails = async () => {
    const IP_ADDRESS = await getUserIPAddress();
    setIP(IP_ADDRESS);
  };

  const handleChange = (event) => {
    const { value, name } = event.target;

    setFeedBack({
      ...feedBack,
      [name]: value,
      // Company__c: userInfo?.current_company,
      // User__c: userInfo?.id,
      // Link__c: fullLink,
    });
  };
  useEffect(() => {
    getUserDetails();
  }, []);
  const fullLink = window.location.href;
  const handleSubmit = (event) => {
    event.preventDefault();

    if (
      feedBack?.Title__c?.length > 0 ||
      feedBack?.Description__c?.length > 0
    ) {
      setSubmitButtonText("Submitting");
      let payload = {
        attributes: {
          type: "Feedback__c",
        },
        Title__c: feedBack?.Title__c,
        Description__c: feedBack?.Description__c,
        Company__c: userInfo?.current_company,
        User__c: userInfo?.id,
        Link__c: fullLink,
        IP_Address__c: ip,
      };
      http
        .post(UPDATE_FEEDBACK, JSON.stringify(payload))
        .then((res) => {
          setSubmitButtonText("Submitted");
          toast("Feedback submitted successfully");
          setFeedbackModal(false);
          setFeedBack(null);
          setEditorState(EditorState.createEmpty());
        })
        .catch((err) => {
          toast(err.message);
        });
    } else {
      toast.error("Either title or description is required");
    }
  };

  const handleGetDetails = async () => { };
  const sendContent = () => {
    return draftToHtml(convertToRaw(editorState.getCurrentContent()));
  };
  return (
    <Fragment>
      <div className={`${feedbackModal ? "open" : ""} `}>
        <div className="style-block">
          <Drawer
            anchor={"right"}
            open={feedbackModal}
            onClose={() => handleCloseModalOpen()}
          >
            <div
              style={{ width: "40vw" }}
              className="p-4 pt-5 feedbackModalDiv"
            >
              <div className="mb-3 d-flex justify-content-between">
                <h5>Feedback</h5>
                <OverlayTrigger overlay={<Tooltip>Close</Tooltip>}>
                  <button className="btn cPadding  m-0 px-3 btn-secondary" onClick={() => setFeedbackModal(false)}>
                    <i className="fa fa-close mr-0" />
                  </button>
                </OverlayTrigger>
              </div>
              <div className="col-md-12">
                <Row>
                  <div className="col-md-12 pl-0 pr-0">
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Title</Form.Label>
                      <Form.Control
                        type="text"
                        name="Title__c"
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>

                  </div>
                </Row>
              </div>

              {selectedRich && (
                <>
                  <p className="DescriptionTi">Description</p>
                  <Editor
                    editorStyle={{ height: "200px" }}
                    editorState={editorState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    onEditorStateChange={onEditorStateChange}
                  />
                </>
              )}


              <UploadFileProjectDetail
                projectId={"feedback_" + new Date()?.getTime()}
                onImageUpload={() => handleGetDetails()}
                page={"feed"}
              />
              <div className="feedbackSubmitBtnDiv mt-5">
                <Button
                  variant="primary"
                  type="submit"
                  className={`width200 feedbackSubmitBtn ${submitButtonText}`}
                  onClick={handleSubmit}
                >
                  {submitButtonText}
                </Button>
              </div>
            </div>
          </Drawer>
        </div>
      </div>
    </Fragment>
  );
};

export default FeedbackDrawer;
