import Form from 'react-bootstrap/Form';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { useHistory } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from "react-toastify";
import { UPDATE_USER_DETAILS } from '../../constants/apiPaths';
import http from "../../helper/Interceptors";
import useAuth from '../../hooks/useAuth';
import { LoginSuccessAction } from '../../redux/actions/userActions';
import styled from 'styled-components';
// import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import file from '../../assets/pdf/TermsOfUse.pdf'
// import { PDFViewer } from "@progress/kendo-react-pdf-viewer";
import { SampleFileBase64 } from './TermsOfService';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';


const StyledModal = styled(Modal)`
  .modal-dialog {
    max-width: 90vw; 
  }
`;

const StyledButton = styled.button`
    border: none;
    background-color: #DC143C;
    color: #fff;
    cursor: pointer;
    padding: 10px 20px;
    border-radius: 0.25rem;
    font-size: 14px;
    margin-bottom: 5px;
    margin-right: 10px;
    transition: all 0.3s ease-in-out;
`


const TermsAndConditionsModal = () => {
  const userInfo = useSelector((state) => state?.user?.userInfo);
  const userAccessLevels = userInfo?.access_level;
  const restrictedUser = userAccessLevels?.includes("restricted")
  const dispatch = useDispatch();
  const [hasAgreedToTermsOfUse, setHasAgreeedToTermsOfUse] = useState(userInfo?.terms_of_use_signed);
  const [checkBoxValue, setCheckBoxValue] = useState(false)
  const [sign, setSign] = useState("")
  const { logout } = useAuth()
  const history = useHistory()
  const TermsOfUsePDFUrl = './TermsOfUse.pdf';
  const [numPages, setNumPages] = useState(0); // State variable to hold the total number of pages (initialize to 0)

  // Function to handle loading the PDF and getting the total number of pages
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages); // Update the state with the total number of pages
  };

  const handleLogout = async () => {
    try {
      await logout();
      history.push("/")
    } catch (err) {
      console.error(err);
    }
  };

  const handleAcceptTermsOfUse = (event) => {
    event.preventDefault();
    if (checkBoxValue && !!sign.trim()) {
      // console.log("Form data:", userDetails);
      let payload = {
        attributes: {
          type: "User__c",
        },
        Id: userInfo.id,
        Terms_of_Use_Signed__c: true,
        Terms_of_Use_Signed_Name__c: sign,
        Terms_of_Use_Signed_On__c: formattedDate
      }
      http.post(UPDATE_USER_DETAILS, JSON.stringify(payload)).then((res) => {
        const updatedUserObject = { ...userInfo, terms_of_use_signed: true }
        localStorage.setItem("user_info", JSON.stringify(updatedUserObject));
        dispatch(LoginSuccessAction(updatedUserObject));
        setHasAgreeedToTermsOfUse(true)
        // window?.location?.reload();
      }).catch((err) => {
        toast(err.message)
      })
    }
  }

  return restrictedUser ? null : (
    <StyledModal show={!hasAgreedToTermsOfUse}>
      <Modal.Header>
        <h5>
          Terms of Service
        </h5>
      </Modal.Header>
      <Modal.Body>
        <div className='px-5 pt-2'>
          <div style={{ color: "#2e2e2e" }}>
            <h3 className='text-center mb-5'>
              HYPRESPACE BETA TEST
            </h3>
            <p>YOU HAVE SIGNED AN NDA AND TERMS OF SERVICE FOR HYPRESPACE.</p>
            <div className='mb-2 pb-3'>
              <div >

                <p>
                  THAT MEANS:
                  <br />
                  ALL HYPRESPACE MATERIALS ARE CONFIDENTIAL.
                </p>
              </div>
              <p>
                YOU WILL NOT
              </p>
              <p>
                copy, download, screenshot, record, take notes on, reproduce or disseminate any of the Hyprespace Materials in any format.
              </p>
              <p>
                YOU WILL
              </p>
              <p>
                safeguard the Hyprespace Materials and prevent unauthorized access to, reproduction of, disclosure of and/or unauthorized use of, the Hyprespace Materials, and fully comply with the terms and conditions of the NDA and the Terms of Use.
              </p>
              <p>


                YOU AGREE THAT
              </p>
              <p>
                breach of the above obligations will cause irreparable harm to Hyprespace.
                <br />
                Your obligation to keep the Hyprespace Materials confidential will continue until Hyprespace notifies you otherwise.
              </p>
            </div>

            <div style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-end'
            }}>
              <div>
                <b style={{ textDecoration: 'underline', textTransform: "uppercase", marginBottom: "5px" }}>AGREED TO AND ACCEPTED ON {longDateString?.trim()}:</b>
                <div style={{
                  display: 'flex',
                  marginTop: '10px',
                  alignItems: 'center'

                }}>
                  <b className="mr-5">HYPRESPACE, LLC</b>

                  <div style={{ width: "300px" }}>
                    <Form.Control width={300} value={sign} type="email" placeholder="Please sign your name"
                      onChange={(e) => setSign(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div className=''>
                <div className='mb-2 text-center cp' title='View & Download Terms of Use'>
                  <i className="fa fa-file-pdf"
                    style={{ color: "#DC143C", fontSize: 60 }}
                    onClick={() => window.open("https://hyprespace.s3.amazonaws.com/Hyprespace_Beta_Test_Terms_of_Use.pdf", "_blank")} />
                </div>
                <br />
                <Form.Check
                  inline
                  label="I have read, understand and agree to the Terms of Service above"
                  type="checkbox"
                  checked={checkBoxValue}
                  onChange={() => setCheckBoxValue(prev => !prev)}
                />
              </div>

            </div>

          </div>
        </div>

      </Modal.Body>
      <Modal.Footer>
        <StyledButton
          onClick={() => handleLogout()}
          style={{ backgroundColor: 'red !important' }}
        >
          I Decline
        </StyledButton>
        <Button variant="success"
          onClick={handleAcceptTermsOfUse}
          disabled={!(checkBoxValue && !!sign.trim())}
          color='green'
        >
          I Accept
        </Button>
      </Modal.Footer>
    </StyledModal >
  );
}

const currentDate = new Date();
const year = currentDate.getFullYear();
const month = String(currentDate.getMonth() + 1).padStart(2, '0');
const day = String(currentDate.getDate()).padStart(2, '0');

const formattedDate = `${year}-${month}-${day}`;

const longDateString = currentDate.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });


export default TermsAndConditionsModal;