import { Fragment, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { GET_NOTIFICATION_LIST } from "../../constants/apiPaths";
import http from "../../helper/Interceptors"
import { SetNotificationData, SetReloadNotificationApi } from "../../redux/actions/CommonActions";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { ListGroup, Dropdown, Media } from 'react-bootstrap';
import { Card } from "react-bootstrap"
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';

const NotificationModal = () => {

    const dispatchRedux = useDispatch()
    const [listOpen, setListOpen] = useState(false);
    const notificationList = useSelector((state) => state?.global?.notificationData)
    const notificationCount = notificationList?.filter((key) => {
        return (!key?.seen && !key?.read && !key?.cleared)
    });
    const reloadNotification = useSelector((state) => state?.global?.reloadNotificationApi)

    const handleGetNotificationsList = () => {
        http.get(GET_NOTIFICATION_LIST)
            .then((res) => {
                dispatchRedux(SetNotificationData(res?.data))
                dispatchRedux(SetReloadNotificationApi(false))
            }).catch((err) => {
                console.error("err notification list", err)
            })
    }

    const handleReadAllNotification = (keyName) => {
        const formData = notificationList?.map((key) => {
            return {
                "seen": key?.seen,
                "read": key?.read,
                "id": key?.id,
                "cleared": key?.cleared,
                [keyName]: true
            }
        })
        http.post(GET_NOTIFICATION_LIST, formData)
            .then((res) => {
                handleGetNotificationsList()
                // toast.success("all notification " + keyName)
            }).catch((err) => {
                console.error("err notification list", err)
            })


    }



    useEffect(() => {
        if (!!listOpen) {
            handleReadAllNotification("seen")
        }
    }, [listOpen])

    useEffect(() => {

        if (!!reloadNotification) {
            handleGetNotificationsList()
        }
    }, [reloadNotification])

    return (
        <Fragment>
            <Card>
                <Card.Header className="p-3">

                    <div className="d-flex justify-content-between">
                        <Card.Title as="h5">Notifications</Card.Title>

                        {notificationList?.length > 0 &&
                            <div className="float-right">
                                <a className="m-r-10 cp" onClick={() => handleReadAllNotification("read")}>
                                    <MarkEmailReadIcon />
                                </a>
                                {/* <a className='cp' onClick={() => handleReadAllNotification("cleared")}>
                            clear all
                        </a> */}
                            </div>
                        }
                    </div>


                </Card.Header>
                <Card.Body className="p-1">
                    <PerfectScrollbar suppressScrollX={true} >
                        {notificationList?.length > 0 &&
                            <Fragment>
                                <ListGroup as="ul" bsPrefix=" " variant="flush" className="noti-body">


                                    {!!notificationList && notificationList?.map((notify, index) => {
                                        return (
                                            <Fragment key={index}>
                                                <ListGroup.Item as="li" bsPrefix=" " className="notification">
                                                    <Media>
                                                        {/* <img className="img-radius" src={avatar1} alt="avtar" /> */}
                                                        <Media.Body>
                                                            <p className="mb-1">

                                                                <span className="n-time text-muted">
                                                                    <i className="icon feather icon-clock m-r-10" />
                                                                    {!!notify?.posted_datetime ? new Date(notify?.posted_datetime).toLocaleString() : ""}
                                                                </span>
                                                            </p>
                                                            <p className={!notify?.read ? "fw700 mb-1" : "mb-1"}> {notify?.title}</p>
                                                            <p>{notify?.body}</p>
                                                        </Media.Body>
                                                    </Media>
                                                </ListGroup.Item>
                                            </Fragment>
                                        )
                                    })}



                                </ListGroup>
                            </Fragment>
                        }

                        {notificationList?.length === 0 &&
                            <p className='p-3 text-center'>No notification to show</p>
                        }

                    </PerfectScrollbar>
                </Card.Body>
            </Card>


        </Fragment>
    )
}

export default NotificationModal