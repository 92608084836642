export const SORTABLE_OPTIONS = {
  allowUnsort: true,
  mode: "multiple",
};
export const FILTERABLE_OPTION = false;
export const RESIZABLE_OPTION = true;
export const REORDERABLE_OPTION = false;
export const PAGEABLE_OPTION = true;
export const EXPANDED_FIELD = "expanded";
export const SUB_TYPE_CUSTOM_FILTER = "CUSTOM";
export const SUB_TYPE_ADVANCED_FILTER = "ADVANCED";
export const DATA_GRID_TABLE_SCHEMA = "DATA_GRID_TABLE_SCHEMA";
export const DATA_GRID_FILTER_SCHEMA = "DATA_GRID_FILTER_SCHEMA";
export const DATA_GRID_CHART_SCHEMA = "DATA_GRID_CHART_SCHEMA";

export const PAGEABLE = {
  buttonCount: 5,
  info: true,
  type: "numeric",
  pageSizes: [10, 20, 50, 100, 200],
  previousNext: true,
};

export const KENDO_DATA_GRID_DEFAULT_STATE = {
  take: 10,
  skip: 0,
};

export const DATA_GRID_DEFAULT_STYLE = {
  maHeight: "100vh",
};

export const GET_FIELD_INFO_BY_URL = (dbFieldName = "") => {
  // pass the db field name and it will return all the necessary routes and key name
  let detailUrl = "";
  if (dbFieldName === "Project__c") {
    detailUrl = "project";
  }
  if (dbFieldName === "Building__c") {
    detailUrl = "building";
  }
  if (dbFieldName === "Lease__c") {
    detailUrl = "lease";
  }
  if (dbFieldName === "Key_Date__c") {
    detailUrl = "keydate";
  }
  if (dbFieldName === "Expense__c") {
    detailUrl = "expense";
  }
  if (dbFieldName === "Proposal__c") {
    detailUrl = "proposal";
  }
  if (dbFieldName === "Occupancy__c") {
    detailUrl = "occupancy";
  }
  if (dbFieldName === "Key_Date__c") {
    detailUrl = "keydate";
  }
  if (dbFieldName === "Market_Survey__c") {
    detailUrl = "marketsurvey";
  }
  if (dbFieldName === "Strategic_Plan__c") {
    detailUrl = "strategic_plan_detail";
  }
  if (dbFieldName === "Target__c") {
    detailUrl = "goal";
  }

  return { detailUrl };
};

export const GET_GRID_WIDTH = (fieldType) => {
  if (fieldType === "DATE") {
    return "150px";
  }
  if (fieldType === "DOUBLE" || fieldType === "CURRENCY") return "170px";
  if (fieldType === "STRING") return "250px";
  if (fieldType === "PICKLIST") return "150px";
  if (fieldType === "TEXTAREA") return "600px";
  return "200px";
};

export const GET_GRID_FORMATTED_DATA_TYPE = (type) => {
  let format = "";
  let className = "";
  if (type === "DOUBLE") {
    format = "{0:n}";
    className = "text-right";
  } else if (type === "DATE") {
    format = "{0:MM/dd/yyyy}";
    className = "text-right";
  } else if (type === "DATETIME") {
    format = "{MM/dd/yyyy H:mm} ";
    className = "text-right";
  } else if (type === "CURRENCY") {
    format = "{0:n}"; //{0:c2}
    className = "text-right";
  } else if (type === "PERCENT") {
    format = "{0:0.##\\%}";
    // className = "text-right";
  }
  return { format, className };
};

export const GET_INCLUDED_FIELDS = (type) => {
  let includedFilters = [];
  let includedGridColumns = [];
  let editableGridColumns = [];
  let chartSummary = [];
  let chartGroupBy = [];
  let lockedColumn = [];
  if (type === "Project__c") {
    includedGridColumns = [
      "Name",
      "Actual_Duration_Remaining__c",
      "Actual_Duration__c",
      "Actual_End_Date__c",
      "Actual_Start_Date__c",
      "Annual_Base_Rent__c",
      "Approval_Status__c",
      "Area_UoM__c",
      "Area__c",
      "BU_Contact_Email__c",
      "Business_Case_Summary__c",
      "City__c",
      "Client_Broker_Email__c",
      "Comments__c",
      "Completed_Date__c",
      "Current_Stage__c",
      "Forecasted_Duration__c",
      "Forecasted_End_Date__c",
      "Formatted_Address__c",
      "General_Contractor_Email__c",
      "Has_Budget__c",
      "Has_Timeline__c",
      "Heads__c",
      "Inflation_Rate__c",
      "Lease_Expiration_Date__c",
      "Listing_Broker_Email__c",
      "Ownership__c",
      "Planned_Duration__c",
      "Planned_End_Date__c",
      "Planned_Start_Date__c",
      "Postal_Code__c",
      "Project_Manager__c",
      "Project_Schedule_Target_Date__c",
      "Project_Schedule_Target_Type__c",
      "Project_Status__c",
      "Project_Type__c",
      "Region__c",
      "Seats__c",
      "Site_Selection_Summary__c",
      "State__c",
      "Street__c",
      "Suite_Floor__c",
      "Target_Area__c",
      "Target_Heads__c",
      "Target_Seats__c",
      "Total_Approved_Amount__c",
      "Total_Change_Order_Amount__c",
      "Total_Estimated_Amount__c",
      "Total_Invoice_Amount__c",
      "Total_Purchase_Order_Amount__c",
      "Total_Purchase_Order_Count__c",
      "Transaction_Manager__c",
      "Use_Type__c",
      "Address__c",
      "Area_per_Seat__c",
      "Capital_Cost_per_Area__c",
      "Free_Rent__c",
      "GAAP_Monthly_Rent__c",
      "Occupancy__c",
      "Rent_per_Area__c",
      "Savings_Free_Rent__c",
      "Savings_TI_Allowance__c",
      "Savings_Total_Base_Rent__c",
      "Schedule_Status__c",
      "TI_Allowance__c",
      "Target_Occupancy__c",
      "Total_Base_Rent__c",
      "Total_Capital_Expense__c",
      "Total_Remaining_Amount__c",
      "Company_Code__c",
      "Schedule_Variance__c",
    ];
    lockedColumn = ["Schedule_Status__c", "Name"];
    editableGridColumns = [
      "Name",
      "Actual_Duration_Remaining__c",
      "Actual_Duration__c",
      "Actual_End_Date__c",
      "Actual_Start_Date__c",
      "Annual_Base_Rent__c",
      "Approval_Status__c",
      "Area_UoM__c",
      "Area__c",
      "BU_Contact_Email__c",
      "Business_Case_Summary__c",
      "City__c",
      "Client_Broker_Email__c",
      "Comments__c",
      "Completed_Date__c",
      "Current_Stage__c",
      "Forecasted_Duration__c",
      "Forecasted_End_Date__c",
      "Formatted_Address__c",
      "General_Contractor_Email__c",
      "Has_Budget__c",
      "Has_Timeline__c",
      "Heads__c",
      "Inflation_Rate__c",
      "Lease_Expiration_Date__c",
      "Listing_Broker_Email__c",
      "Ownership__c",
      "Planned_Duration__c",
      "Planned_End_Date__c",
      "Planned_Start_Date__c",
      "Postal_Code__c",
      "Project_Manager__c",
      "Project_Schedule_Target_Date__c",
      "Project_Schedule_Target_Type__c",
      "Project_Status__c",
      "Project_Type__c",
      "Region__c",
      "Seats__c",
      "Site_Selection_Summary__c",
      "State__c",
      "Street__c",
      "Suite_Floor__c",
      "Target_Area__c",
      "Target_Heads__c",
      "Target_Seats__c",
      "Total_Approved_Amount__c",
      "Total_Change_Order_Amount__c",
      "Total_Estimated_Amount__c",
      "Total_Invoice_Amount__c",
      "Total_Purchase_Order_Amount__c",
      "Total_Purchase_Order_Count__c",
      "Transaction_Manager__c",
      "Use_Type__c",
      "Address__c",
      "Area_per_Seat__c",
      "Capital_Cost_per_Area__c",
      "Free_Rent__c",
      "GAAP_Monthly_Rent__c",
      "Occupancy__c",
      "Rent_per_Area__c",
      "Savings_Free_Rent__c",
      "Savings_TI_Allowance__c",
      "Savings_Total_Base_Rent__c",
      "Schedule_Status__c",
      "TI_Allowance__c",
      "Target_Occupancy__c",
      "Total_Base_Rent__c",
      "Total_Capital_Expense__c",
      "Total_Remaining_Amount__c",
      "Company_Code__c",
      "Schedule_Variance__c",
    ];
    includedFilters = [
      "Name",
      "Actual_Duration_Remaining__c",
      "Actual_Duration__c",
      "Actual_End_Date__c",
      "Actual_Start_Date__c",
      "Annual_Base_Rent__c",
      "Approval_Status__c",
      "Area_UoM__c",
      "Area__c",
      "BU_Contact_Email__c",
      "Business_Case_Summary__c",
      "City__c",
      "Client_Broker_Email__c",
      "Comments__c",
      "Completed_Date__c",
      "Current_Stage__c",
      "Forecasted_Duration__c",
      "Forecasted_End_Date__c",
      "Formatted_Address__c",
      "General_Contractor_Email__c",
      "Has_Budget__c",
      "Has_Timeline__c",
      "Heads__c",
      "Inflation_Rate__c",
      "Lease_Expiration_Date__c",
      "Listing_Broker_Email__c",
      "Ownership__c",
      "Planned_Duration__c",
      "Planned_End_Date__c",
      "Planned_Start_Date__c",
      "Postal_Code__c",
      "Project_Manager__c",
      "Project_Schedule_Target_Date__c",
      "Project_Schedule_Target_Type__c",
      "Project_Status__c",
      "Project_Type__c",
      "Region__c",
      "Seats__c",
      "Site_Selection_Summary__c",
      "State__c",
      "Street__c",
      "Suite_Floor__c",
      "Target_Area__c",
      "Target_Heads__c",
      "Target_Seats__c",
      "Total_Approved_Amount__c",
      "Total_Change_Order_Amount__c",
      "Total_Estimated_Amount__c",
      "Total_Invoice_Amount__c",
      "Total_Purchase_Order_Amount__c",
      "Total_Purchase_Order_Count__c",
      "Transaction_Manager__c",
      "Use_Type__c",
      "Address__c",
      "Area_per_Seat__c",
      "Capital_Cost_per_Area__c",
      "Free_Rent__c",
      "GAAP_Monthly_Rent__c",
      "Occupancy__c",
      "Rent_per_Area__c",
      "Savings_Free_Rent__c",
      "Savings_TI_Allowance__c",
      "Savings_Total_Base_Rent__c",
      "Schedule_Status__c",
      "TI_Allowance__c",
      "Target_Occupancy__c",
      "Total_Base_Rent__c",
      "Total_Capital_Expense__c",
      "Total_Remaining_Amount__c",
      "Company_Code__c",
      "Schedule_Variance__c",
    ];
    chartSummary = [
      "Name",
      "Actual_Duration_Remaining__c",
      "Actual_Duration__c",
      "Actual_End_Date__c",
      "Actual_Start_Date__c",
      "Annual_Base_Rent__c",
      "Approval_Status__c",
      "Area_UoM__c",
      "Area__c",
      "BU_Contact_Email__c",
      "Business_Case_Summary__c",
      "City__c",
      "Client_Broker_Email__c",
      "Comments__c",
      "Customer_Satisfaction_Score__c",
      "Savings_Free_Rent__c",
      "Savings_TI_Allowance__c",
      "Savings_Total_Base_Rent__c",
      "Completed_Date__c",
      "Current_Stage__c",
      "Forecasted_Duration__c",
      "Forecasted_End_Date__c",
      "Formatted_Address__c",
      "General_Contractor_Email__c",
      "Has_Budget__c",
      "Has_Timeline__c",
      "Heads__c",
      "Inflation_Rate__c",
      "Lease_Expiration_Date__c",
      "Listing_Broker_Email__c",
      "Ownership__c",
      "Planned_Duration__c",
      "Planned_End_Date__c",
      "Planned_Start_Date__c",
      "Postal_Code__c",
      "Project_Manager__c",
      "Project_Schedule_Target_Date__c",
      "Project_Schedule_Target_Type__c",
      "Project_Status__c",
      "Project_Type__c",
      "Region__c",
      "Seats__c",
      "Site_Selection_Summary__c",
      "State__c",
      "Street__c",
      "Suite_Floor__c",
      "Target_Area__c",
      "Target_Heads__c",
      "Target_Seats__c",
      "Total_Approved_Amount__c",
      "Total_Change_Order_Amount__c",
      "Total_Estimated_Amount__c",
      "Total_Invoice_Amount__c",
      "Total_Purchase_Order_Amount__c",
      "Total_Purchase_Order_Count__c",
      "Transaction_Manager__c",
      "Use_Type__c",
      "Address__c",
      "Area_per_Seat__c",
      "Capital_Cost_per_Area__c",
      "Free_Rent__c",
      "GAAP_Monthly_Rent__c",
      "Occupancy__c",
      "Rent_per_Area__c",
      "Savings_Free_Rent__c",
      "Savings_TI_Allowance__c",
      "Savings_Total_Base_Rent__c",
      "Schedule_Status__c",
      "TI_Allowance__c",
      "Target_Occupancy__c",
      "Total_Base_Rent__c",
      "Total_Capital_Expense__c",
      "Total_Remaining_Amount__c",
      "Company_Code__c",
      "Schedule_Variance__c",
    ];
    chartGroupBy = [
      "Name",
      "Actual_Duration_Remaining__c",
      "Actual_Duration__c",
      "Actual_End_Date__c",
      "Actual_Start_Date__c",
      "Annual_Base_Rent__c",
      "Schedule_Final_Status__c",
      "Approval_Status__c",
      "Area_UoM__c",
      "Area__c",
      "BU_Contact_Email__c",
      "Business_Case_Summary__c",
      "City__c",
      "Client_Broker_Email__c",
      "Comments__c",
      "Completed_Date__c",
      "Current_Stage__c",
      "Forecasted_Duration__c",
      "Forecasted_End_Date__c",
      "Formatted_Address__c",
      "General_Contractor_Email__c",
      "Has_Budget__c",
      "Has_Timeline__c",
      "Heads__c",
      "Inflation_Rate__c",
      "Lease_Expiration_Date__c",
      "Listing_Broker_Email__c",
      "Ownership__c",
      "Planned_Duration__c",
      "Planned_End_Date__c",
      "Planned_Start_Date__c",
      "Postal_Code__c",
      "Project_Manager__c",
      "Project_Schedule_Target_Date__c",
      "Project_Schedule_Target_Type__c",
      "Project_Status__c",
      "Project_Type__c",
      "Region__c",
      "Seats__c",
      "Site_Selection_Summary__c",
      "State__c",
      "Street__c",
      "Suite_Floor__c",
      "Target_Area__c",
      "Target_Heads__c",
      "Target_Seats__c",
      "Total_Approved_Amount__c",
      "Total_Change_Order_Amount__c",
      "Total_Estimated_Amount__c",
      "Total_Invoice_Amount__c",
      "Total_Purchase_Order_Amount__c",
      "Total_Purchase_Order_Count__c",
      "Transaction_Manager__c",
      "Use_Type__c",
      "Address__c",
      "Area_per_Seat__c",
      "Capital_Cost_per_Area__c",
      "Free_Rent__c",
      "GAAP_Monthly_Rent__c",
      "Occupancy__c",
      "Rent_per_Area__c",
      "Savings_Free_Rent__c",
      "Savings_TI_Allowance__c",
      "Savings_Total_Base_Rent__c",
      "Schedule_Status__c",
      "TI_Allowance__c",
      "Target_Occupancy__c",
      "Total_Base_Rent__c",
      "Total_Capital_Expense__c",
      "Total_Remaining_Amount__c",
      "Company_Code__c",
      "Schedule_Variance__c",
    ];
  }
  if (type === "Building__c") {
    includedGridColumns = [
      "Name",
      "Address__c",
      "Ownership__c",
      "Use_Type__c",
      "Region__c",
      "Area__c",
      "Heads__c",
      "Seats__c",
      "Area_per_Seat__c",
      "Rent_per_Area__c",
      "Market_Rent_per_Area__c",
      "Excess_Space__c",
      "Vacancy__c",
      "Vacancy_Rate__c",
      "Cost_of_Vacancy__c",
    ];
    lockedColumn = ["Schedule_Status__c", "Name"];
    includedFilters = [
      "Name",
      "Use_Type__c",
      "Region__c",
      "Ownership__c",
      "Heads__c",
      "Seats__c",
      "Area_per_Seat__c",
      "Rent_per_Area__c",
      "Street__c",
      "City__c",
      "State__c",
      "Postal_Code__c",
      "Area__c",
      "Cost_of_Vacancy__c",
      "Excess_Space__c",
      "Market_Rent_per_Area__c",
      "Savings__c",
      "Vacancy__c",
      "Vacancy_Rate__c",
    ];
    chartSummary = [
      "External_Id__c",
      "Name",
      "Area_UoM__c",
      "Area__c",
      "City__c",
      "Formatted_Address__c",
      "Heads__c",
      "Market_Rent_per_Area__c",
      "Market_Summary__c",
      "Ownership__c",
      "Postal_Code__c",
      "Region__c",
      "Rent_per_Area__c",
      "Seats__c",
      "State__c",
      "Status__c",
      "Street__c",
      "Use_Type__c",
      "Address__c",
      "Area_per_Seat__c",
      "Cost_of_Vacancy__c",
      "Excess_Space__c",
      "Project_Name__c",
      "Savings__c",
      "Vacancy_Rate__c",
      "Vacancy__c",
      "Company_Code__c",
      "Comments__c",
      "Country__c",
      "Sub_Region__c",
      "Occupancy_Market__c",
      "Business_Unit__c",
      "Sub_Business_Unit__c",
      "Plan_of_Action__c",
      "Lease_Expiration_Date__c",
      "Annual_Base_Rent__c",
      "Market_Savings__c",
      "Market_Variance__c",
      "Occupancy_Area__c",
      "Workspaces_Assigned__c",
      "Workspaces_Shared__c",
      "Workspaces_Vacant__c",
      "Total_Workspaces__c",
      "Workspace_Occupancy_Rate__c",
      "Workspace_Vacancy_Rate__c",
      "Rent_per_Workspace__c",
      "Rent_per_Headcount__c",
      "Area_per_Workspace__c",
      "Area_per_Headcount__c",
      "Headcount_Workspace_Ratio__c",
      "Workspace_Demand__c",
      "Currency_UoM__c",
    ];
    chartGroupBy = [
      "External_Id__c",
      "Name",
      "Area_UoM__c",
      "Area__c",
      "City__c",
      "Formatted_Address__c",
      "Heads__c",
      "Market_Rent_per_Area__c",
      "Market_Summary__c",
      "Ownership__c",
      "Postal_Code__c",
      "Region__c",
      "Rent_per_Area__c",
      "Seats__c",
      "State__c",
      "Status__c",
      "Street__c",
      "Use_Type__c",
      "Address__c",
      "Area_per_Seat__c",
      "Cost_of_Vacancy__c",
      "Excess_Space__c",
      "Project_Name__c",
      "Savings__c",
      "Vacancy_Rate__c",
      "Vacancy__c",
      "Company_Code__c",
      "Comments__c",
      "Country__c",
      "Sub_Region__c",
      "Occupancy_Market__c",
      "Business_Unit__c",
      "Sub_Business_Unit__c",
      "Plan_of_Action__c",
      "Lease_Expiration_Date__c",
      "Annual_Base_Rent__c",
      "Market_Savings__c",
      "Market_Variance__c",
      "Occupancy_Area__c",
      "Workspaces_Assigned__c",
      "Workspaces_Shared__c",
      "Workspaces_Vacant__c",
      "Total_Workspaces__c",
      "Workspace_Occupancy_Rate__c",
      "Workspace_Vacancy_Rate__c",
      "Rent_per_Workspace__c",
      "Rent_per_Headcount__c",
      "Area_per_Workspace__c",
      "Area_per_Headcount__c",
      "Headcount_Workspace_Ratio__c",
      "Workspace_Demand__c",
      "Currency_UoM__c",
    ];
  }
  if (type === "Lease__c") {
    includedGridColumns = [
      "Name",
      "Use_Type__c",
      "Commencement_Date__c",
      "Expiration_Date__c",
      "Annual_Base_Rent__c",
      "Rent_Type__c",
      "Area__c",
      "Usable_Area__c",
      "Area_UoM__c",
      "Address__c",
    ];
    lockedColumn = ["Building_Code__c", "Building_Name__c"];
    includedFilters = [
      "Name",
      "Commencement_Date__c",
      "Expiration_Date__c",
      "Rent_Type__c",
      "Use_Type__c",
      "Street__c",
      "State__c",
      "Postal_Code__c",
      "City__c",
      "Area_UoM__c",
      "Annual_Base_Rent__c",
      "Usable_Area__c",
      "Area__c",
    ];
    chartSummary = [
      "Name",
      "Annual_Base_Rent__c",
      "Area_UoM__c",
      "Area__c",
      "City__c",
      "Commencement_Date__c",
      "Comments__c",
      "Expiration_Date__c",
      "Formatted_Address__c",
      "Last_Expiration_Date__c",
      "Market_Rent_per_Area__c",
      "Market_Summary__c",
      "Original_Commencement_Date__c",
      "Original_Expiration_Date__c",
      "Postal_Code__c",
      "Remaining_Obligation__c",
      "Rent_Type__c",
      "State__c",
      "Status__c",
      "Street__c",
      "Usable_Area__c",
      "Use_Type__c",
      "Accepted_Proposal__c",
      "Address__c",
      "Building_Name__c",
      "Project_Type__c",
      "Transaction_Manager__c",
      "Company_Code__c",
      "Type__c",
      "Rent_per_Area__c",
      "Plan_of_Action__c",
      "Next_Break_Date__c",
      "Term__c",
      "Remaining_Term__c",
      "Lease_Expiration_Category__c",
      "Rentable_Area__c",
      "Is_Primary__c",
      "Expiration_Market_Favor__c",
      "Next_Option_Type__c",
      "Next_Notice_Date__c",
      "Next_Action_Date__c",
      "Option_Note__c",
      "Currency_Code__c",
      "Building_Code__c",
      "Occupancy_Market__c",
      "Building_Type__c",
      "Sub_Region__c",
      "Region__c",
      "Sub_Business_Unit__c",
      "Business_Unit__c",
      "Building_Ownership__c",
      "Building_Rent_per_Area__c",
      "Building_Area__c",
      "Building_Annual_Base_Rent__c",
      "Country__c",
      "Expiration_Year__c",
      "Market_Variance__c",
    ];
    chartGroupBy = [
      "Name",
      "Annual_Base_Rent__c",
      "Area_UoM__c",
      "Area__c",
      "City__c",
      "Commencement_Date__c",
      "Comments__c",
      "Expiration_Date__c",
      "Formatted_Address__c",
      "Last_Expiration_Date__c",
      "Market_Rent_per_Area__c",
      "Market_Summary__c",
      "Original_Commencement_Date__c",
      "Original_Expiration_Date__c",
      "Postal_Code__c",
      "Remaining_Obligation__c",
      "Rent_Type__c",
      "State__c",
      "Status__c",
      "Street__c",
      "Usable_Area__c",
      "Use_Type__c",
      "Accepted_Proposal__c",
      "Address__c",
      "Building_Name__c",
      "Project_Type__c",
      "Transaction_Manager__c",
      "Company_Code__c",
      "Type__c",
      "Rent_per_Area__c",
      "Plan_of_Action__c",
      "Next_Break_Date__c",
      "Term__c",
      "Remaining_Term__c",
      "Lease_Expiration_Category__c",
      "Rentable_Area__c",
      "Is_Primary__c",
      "Expiration_Market_Favor__c",
      "Next_Option_Type__c",
      "Next_Notice_Date__c",
      "Next_Action_Date__c",
      "Option_Note__c",
      "Currency_Code__c",
      "Building_Code__c",
      "Occupancy_Market__c",
      "Building_Type__c",
      "Sub_Region__c",
      "Region__c",
      "Sub_Business_Unit__c",
      "Business_Unit__c",
      "Building_Ownership__c",
      "Building_Rent_per_Area__c",
      "Building_Area__c",
      "Building_Annual_Base_Rent__c",
      "Country__c",
      "Expiration_Year__c",
      "Market_Variance__c",
    ];
  }
  if (type === "Market_Favor__c") {
    includedGridColumns = [
      "Name",
      "Code__c",
      "Type__c",
      "Region__c",
      "Country__c",
      "State__c",
      "City__c",
      "Quarter__c",
      "Reported_Date__c",
      "Year__c",
      "Period__c",
      "Favor__c",
      "Clock_Time__c",
    ];
    lockedColumn = ["Name"];
    editableGridColumns = [
      "Name",
      "Code__c",
      "Type__c",
      "Region__c",
      "Country__c",
      "State__c",
      "City__c",
      "Quarter__c",
      "Reported_Date__c",
      "Year__c",
      "Period__c",
      "Favor__c",
      "Clock_Time__c",
    ];
    includedFilters = [
      "Name",
      "Code__c",
      "Type__c",
      "Region__c",
      "Country__c",
      "State__c",
      "City__c",
      "Quarter__c",
      "Reported_Date__c",
      "Year__c",
      "Period__c",
      "Favor__c",
      "Clock_Time__c",
    ];
  }

  if (type === "Lease_Expense__c") {
    includedGridColumns = [
      "Property_Code__c",
      "Lease_Code__c",
      "Start_Date__c",
      "End_Date__c",
      "Date__c",
      "Amount__c",
      "Cost_Type__c",
      "Lease_Expense_Category_Type__c",
      "Type__c",
    ];
  }

  if (type === "Market_Clock__c") {
    includedGridColumns = [
      "Name",
      "Type__c",
      "Region__c",
      "Country__c",
      "State__c",
      "City__c",
      "Quarter__c",
      "Reported_Date__c",
      "Clock_Time__c",
      "Year__c",
      "Period__c",
    ];
    lockedColumn = ["Name"];
    editableGridColumns = [
      "Name",
      "Type__c",
      "Region__c",
      "Country__c",
      "State__c",
      "City__c",
      "Quarter__c",
      "Reported_Date__c",
      "Clock_Time__c",
      "Year__c",
      "Period__c",
    ];
    includedFilters = [
      "Name",
      "Type__c",
      "Region__c",
      "Country__c",
      "State__c",
      "City__c",
      "Quarter__c",
      "Reported_Date__c",
      "Clock_Time__c",
      "Year__c",
      "Period__c",
    ];
  }

  if (type === "Key_Date__c") {
    includedGridColumns = [
      "Lease_Name__c",
      "Name",
      "Lease_Area__c",
      "Date_Type__c",
      "Status__c",
      "Notice_Start_Date__c",
      "Notice_End_Date__c",
      "Effective_Start_Date__c",
      "Effective_End_Date__c",
    ];
    lockedColumn = ["Building_Code__c", "Building_Name__c"];
    editableGridColumns = ["Status__c"];
    includedFilters = [
      "Lease_Name__c",
      "Name",
      "Status__c",
      "Date_Type__c",
      "Notice_Start_Date__c",
      "Notice_End_Date__c",
      "Effective_Start_Date__c",
      "Effective_End_Date__c",
      "Lease_Area__c",
    ];
  }
  if (type === "Expense__c") {
    includedGridColumns = [
      "Type__c",
      "Start_Date__c",
      "End_Date__c",
      "Amount__c",
      "Frequency__c",
      "Status__c",
    ];
    lockedColumn = ["Schedule_Status__c", "Name"];
    includedFilters = [
      "Type__c",
      "Start_Date__c",
      "End_Date__c",
      "Amount__c",
      "Frequency__c",
      "Status__c",
    ];
  }
  if (type === "Project_Timeline_Template_Detail__c") {
    includedGridColumns = [
      "Phase_Name__c",
      "Stage_Number__c",
      "Stage_Name__c",
      "Stage_Predecessor__c",
      "Stage_Planned_Duration__c",
      "Stage_Assignee__c",
    ];
    includedFilters = [
      "Type__c",
      "Start_Date__c",
      "End_Date__c",
      "Amount__c",
      "Frequency__c",
      "Status__c",
    ];
    editableGridColumns = [
      "Phase_Name__c",
      "Stage_Number__c",
      "Stage_Name__c",
      "Stage_Predecessor__c",
      "Stage_Planned_Duration__c",
      "Stage_Assignee__c",
    ];
  }

  // new

  if (type === "Target__c") {
    includedGridColumns = [
      //   "Company__c",
      "Year__c",
      "Description__c",
      "Type__c",
      "Value__c",
    ];
    chartGroupBy = [
      "Year__c",
      "Description__c",
      "Type__c",
    ]
    includedFilters = [
      //   "Company__c",
      "Year__c",
      "Description__c",
      "Type__c",
      "Value__c",
    ];
    editableGridColumns = [
      //   "Company__c",
      "Year__c",
      "Description__c",
      "Type__c",
      "Value__c",
    ];
  }
  if (type === "Occupancy__c") {
    includedGridColumns = [
      "Name",
      "Building_Code__c",
      "Street__c",
      "City__c",
      "State__c",
      "Country__c",
      "Region__c",
      "Sub_Region__c",
      "Occupancy_Market__c",
      "Building_Type__c",
      "Business_Unit__c",
      "Sub_Business_Unit__c",
      "Building_Ownership__c",
      "Building_Area__c",
      "Building_Lease_Expiration_Date__c",
      "Headcount_Category__c",
      "Area_Assigned__c",
      "Area_Shared__c",
      "Area_Vacant__c",
      "Total_Area__c",
      "Workspaces_Assigned__c",
      "Workspaces_Shared__c",
      "Workspaces_Vacant__c",
      "Total_Workspaces__c",
      "Workspace_Vacancy_Rate__c",
      "Workspace_Occupancy_Rate__c",
      "Heads__c",
      "Area_UoM__c",
      "Building_Name__c",
      "Building_Business_Unit__c",
      "Building_Sub_Business_Unit__c",
      "Area_Percent__c",
      "Workspace_Percent__c",
      "Headcount_Percent__c",
      "Plan_of_Action__c",
    ];
    includedFilters = [
      "Name",
      "Building_Code__c",
      "Street__c",
      "City__c",
      "State__c",
      "Country__c",
      "Region__c",
      "Sub_Region__c",
      "Occupancy_Market__c",
      "Building_Type__c",
      "Business_Unit__c",
      "Sub_Business_Unit__c",
      "Building_Ownership__c",
      "Building_Area__c",
      "Building_Lease_Expiration_Date__c",
      "Headcount_Category__c",
      "Area_Assigned__c",
      "Area_Shared__c",
      "Area_Vacant__c",
      "Total_Area__c",
      "Workspaces_Assigned__c",
      "Workspaces_Shared__c",
      "Workspaces_Vacant__c",
      "Total_Workspaces__c",
      "Workspace_Vacancy_Rate__c",
      "Workspace_Occupancy_Rate__c",
      "Heads__c",
      "Area_UoM__c",
      "Building_Name__c",
      "Building_Business_Unit__c",
      "Building_Sub_Business_Unit__c",
      "Area_Percent__c",
      "Workspace_Percent__c",
      "Headcount_Percent__c",
      "Plan_of_Action__c",
    ];
    chartSummary = [
      "Name",
      "Building_Code__c",
      "Street__c",
      "City__c",
      "State__c",
      "Country__c",
      "Region__c",
      "Sub_Region__c",
      "Occupancy_Market__c",
      "Building_Type__c",
      "Business_Unit__c",
      "Sub_Business_Unit__c",
      "Building_Ownership__c",
      "Building_Area__c",
      "Building_Lease_Expiration_Date__c",
      "Headcount_Category__c",
      "Area_Assigned__c",
      "Area_Shared__c",
      "Area_Vacant__c",
      "Total_Area__c",
      "Workspaces_Assigned__c",
      "Workspaces_Shared__c",
      "Workspaces_Vacant__c",
      "Total_Workspaces__c",
      "Workspace_Vacancy_Rate__c",
      "Workspace_Occupancy_Rate__c",
      "Heads__c",
      "Area_UoM__c",
      "Building_Name__c",
      "Building_Business_Unit__c",
      "Building_Sub_Business_Unit__c",
      "Area_Percent__c",
      "Workspace_Percent__c",
      "Headcount_Percent__c",
      "Plan_of_Action__c",
    ];
    chartGroupBy = [
      "Name",
      "Building_Code__c",
      "Street__c",
      "City__c",
      "State__c",
      "Country__c",
      "Region__c",
      "Sub_Region__c",
      "Occupancy_Market__c",
      "Building_Type__c",
      "Business_Unit__c",
      "Sub_Business_Unit__c",
      "Building_Ownership__c",
      "Building_Area__c",
      "Building_Lease_Expiration_Date__c",
      "Headcount_Category__c",
      "Area_Assigned__c",
      "Area_Shared__c",
      "Area_Vacant__c",
      "Total_Area__c",
      "Workspaces_Assigned__c",
      "Workspaces_Shared__c",
      "Workspaces_Vacant__c",
      "Total_Workspaces__c",
      "Workspace_Vacancy_Rate__c",
      "Workspace_Occupancy_Rate__c",
      "Heads__c",
      "Area_UoM__c",
      "Building_Name__c",
      "Building_Business_Unit__c",
      "Building_Sub_Business_Unit__c",
      "Area_Percent__c",
      "Workspace_Percent__c",
      "Headcount_Percent__c",
      "Plan_of_Action__c",
    ];
  }
  if (type === "Project_Timeline__c") {
    //gantt chart list view
    includedGridColumns = [
      "Phase_Name__c",
      "Stage_Number__c",
      "Stage_Name__c",
      "Stage_Predecessor__c",
      "Stage_Planned_Start_Date__c",
      "Stage_Planned_End_Date__c",
      "Stage_Planned_Duration__c",
      "Stage_Actual_Start_Date__c",
      "Stage_Actual_End_Date__c",
      "Stage_Actual_Duration__c",
      "Stage_Forecasted_Start_Date__c",
      "Stage_Forecasted_End_Date__c",
      "Stage_Forecasted_Duration__c",
      "Stage_Assignee__c",
    ];
    includedFilters = [
      "Type__c",
      "Start_Date__c",
      "End_Date__c",
      "Amount__c",
      "Frequency__c",
      "Status__c",
    ];
    editableGridColumns = [
      "Stage_Name__c",
      "Stage_Actual_Start_Date__c",
      "Stage_Actual_End_Date__c",
    ];
  }
  if (type === "Project_Budget_Template_Detail__c") {
    includedGridColumns = ["Category_Code__c", "Category__c"];
  }

  if (type === "Requirement_Survey__c") {
    includedGridColumns = ["CreatedDate", "Lease_Commencement_Date__c", "Total_Worker_Head_Count__c", "Average_Daily_Workers__c", "Seating_Demand__c", "People_Space__c", "Office_Space__c", "Meeting_Space__c", "Utility_Space__c", "Total_Space__c", "Space_Per_Worker__c", "Space_Per_Occupant__c", "Utilization_Per_Worker__c", "Growth_Rate__c", "Daily_Worker_Head_Count__c", "Daily_Worker_Show_Rate__c", "Hybrid_Worker_Head_Count__c", "Hybrid_Worker_Show_Rate__c", "Remote_Worker_Head_Count__c", "Remote_Worker_Show_Rate__c", "Average_Daily_Visitors__c"]
  }

  if (
    type === "Landlord_Survey_Template_Detail__c" ||
    type === "Market_Survey_Template_Detail__c"
  ) {
    includedGridColumns = ["Name", "Label__c"];
  }
  if (type === "Clause__c") {
    includedGridColumns = ["Type__c", "Description__c"];
  }
  if (type === "Proposal__c") {
    includedGridColumns = [
      "Name",
      "Project_Name__c",
      "Street__c",
      "State__c",
      "City__c",
      "Postal_Code__c",
      "Area__c",
      "Possession_Date__c",
      "Commencement_Date__c",
      "Occupancy_Date__c",
      "Term__c",
      "Expiration_Date__c",
      "Free_Rent__c",
      "GAAP_Monthly_Rent__c",
      "Total_Base_Rent__c",
      "Reset_Month__c",
      "Analysis_Start_Date__c",
      "Possession_Months__c",
      "GAAP_Term__c",
      "Total_Base_Rent_Rollup__c",
      "Total_Capital_Expense__c",
      "Total_Monthly_Depreciation_Amount__c",
    ];
  }
  if (type === "Rent_Schedule__c") {
    includedGridColumns = [
      "Sequence_Number__c",
      "Monthly_Rent__c",
      "Months__c",
      "Base_Rent__c",
    ];
    editableGridColumns = [
      "Sequence_Number__c",
      "Monthly_Rent__c",
      "Months__c",
    ];
  }
  if (type === "Rent_Schedule_Detail__c") {
    includedGridColumns = [
      "Due_Date__c",
      "Monthly_Rent__c",
      "Monthly_Rent_So_Far__c",
      "GAAP_Monthly_Rent__c",
      "GAAP_Monthly_Rent_So_Far__c",
    ];
  }
  if (type === "Capital_Expense__c") {
    includedGridColumns = [
      "Name",
      "Purchase_Date__c",
      "Depreciation_Start_Date__c",
      "Amount__c",
      "GAAP_Life__c",
      "GAAP_Life_Override__c",
      "Capital_Term__c",
      "Capital_Depreciation_End__c",
      "Monthly_Depreciation_Amount__c",
    ];
    editableGridColumns = [
      "Name",
      "Purchase_Date__c",
      "Depreciation_Start_Date__c",
      "Amount__c",
      "GAAP_Life__c",
      "GAAP_Life_Override__c",
    ];
  }
  if (type === "Operating_Expense__c") {
    includedGridColumns = [
      "Name",
      "Annual_Amount__c",
      "Expense_Start_Date__c",
      "Included_In_Rent__c",
      "Base_Year__c",
      "Escalation_Type__c",
      "Escalation_Amount__c",
      "Escalation_Month__c",
    ];
    editableGridColumns = [
      "Name",
      "Annual_Amount__c",
      "Expense_Start_Date__c",
      "Included_In_Rent__c",
      "Base_Year__c",
      "Escalation_Type__c",
      "Escalation_Amount__c",
      "Escalation_Month__c",
    ];
  }
  if (type === "Budget_Survey__c") {
    includedGridColumns = [
      "Company_Name__c",
      "Contractor_Email__c",
      "Total_Estimated_Amount__c",
    ];
  }
  if (type === "User__c") {
    includedGridColumns = [
      "Full_Name__c",
      "Email__c",
      "Name",
      "First_Name__c",
      "Last_Name__c",
    ];
  }
  if (type === "Capital_Expense_Detail__c") {
    includedGridColumns = [
      "Due_Date__c",
      "Amount__c",
      "GAAP_Amount__c",
      "GAAP_Amount_So_Far__c",
    ];
  }
  if (type === "Approval_Process_Step_Task__c") {
    includedGridColumns = [
      "Sequence_Number__c",
      "Name",
      "Approver_Email__c",
      "Status__c",
      "Action_DateTime__c",
      "Comments__c",
    ];
  }
  if (type === "Approval_Process_Step__c") {
    includedGridColumns = ["Sequence_Number__c", "Name"];
  }
  if (type === "Strategic_Plan__c") {
    includedGridColumns = [
      "Name",
      "Site_Count__c",
      "Total_Heads__c",
      "Total_Optimized_Heads__c",
      "Total_Area__c",
      "Total_Optimized_Area__c",
      "Total_Densified_Area__c",
      "Total_Reconfiguration_Cost__c",
      "Total_Demised_Area__c",
      "Total_Demised_Cost__c",
      "Total_Expansion_Cost__c",
      "Total_Expanded_Area__c",
      "Total_Annual_Rent_Savings__c",
      "Total_Capital__c",
      "Total_Savings__c",
      "Total_Rent_Recovery__c",
      "Total_Seats__c",
    ];
    includedFilters = [
      "Name",
      "Site_Count__c",
      "Total_Heads__c",
      "Total_Optimized_Heads__c",
      "Total_Area__c",
      "Total_Optimized_Area__c",
      "Total_Densified_Area__c",
      "Total_Reconfiguration_Cost__c",
      "Total_Demised_Area__c",
      "Total_Demised_Cost__c",
      "Total_Expansion_Cost__c",
      "Total_Expanded_Area__c",
      "Total_Annual_Rent_Savings__c",
      "Total_Capital__c",
      "Total_Savings__c",
      "Total_Rent_Recovery__c",
      "Total_Seats__c",
    ];
    chartGroupBy = [
      "Name",
      "Plan_Year__c",
      "Status__c",
      "Source__c"
    ];
    editableGridColumns = ["Name",
      "Plan_Year__c",
      "Status__c",
    ]
  }
  if (type === "Strategic_Plan_Detail__c") {
    chartGroupBy = ["Choice__c"];
  }
  if (type === "Market_Survey__c") {
    includedGridColumns = [
      "CreatedDate",
      "Renewal_Starting_Rate__c",
      "Renewal_Escalations__c",
      "Renewal_TI_Allowance__c",
      "Renewal_Free_Rent__c",
      "Relocation_Starting_Rate__c",
      "Relocation_Escalations__c",
      "Relocation_TI_Allowance__c",
      "Relocation_Free_Rent__c",
      "Sublease_Starting_Rate__c",
      "Sublease_Escalations__c",
      "Sublease_TI_Allowance__c",
      "Sublease_Free_Rent__c",
      "Buyout_Rate__c",
      "Buyout_Probability_of_Success__c",
      "Sublease_Estimated_Lease_Commencement__c",
      "Sublease_Estimated_Fees__c",
      "Comp_1_Street__c",
      "Comp_1_Floor__c",
      "Comp_1_Floor_Size__c",
      "Comp_1_City__c",
      "Comp_1_State__c",
      "Comp_1_Postal_Code__c",
      "Comp_1_Starting_Rate__c",
      "Comp_1_Escalations__c",
      "Comp_1_TI_Allowance__c",
      "Comp_1_Free_Rent__c",
      "Comp_2_Street__c",
      "Comp_2_Floor__c",
      "Comp_2_Floor_Size__c",
      "Comp_2_City__c",
      "Comp_2_State__c",
      "Comp_2_Postal_Code__c",
      "Comp_2_Starting_Rate__c",
      "Comp_2_Escalations__c",
      "Comp_2_TI_Allowance__c",
      "Comp_2_Free_Rent__c",
      "Comp_3_Street__c",
      "Comp_3_Floor__c",
      "Comp_3_Floor_Size__c",
      "Comp_3_City__c",
      "Comp_3_State__c",
      "Comp_3_Postal_Code__c",
      "Comp_3_Starting_Rate__c",
      "Comp_3_Escalations__c",
      "Comp_3_TI_Allowance__c",
      "Comp_3_Free_Rent__c",
      "Comments__c",
    ];
  }

  return {
    includedFilters,
    includedGridColumns,
    chartSummary,
    chartGroupBy,
    editableGridColumns,
    lockedColumn,
  };
};
export const GET_EDITABLE_FIELD_TYPE = (fieldType) => {
  if (fieldType === "DATE") return "date";
  if (fieldType === "DOUBLE") return "numeric";
  if (fieldType === "BOOLEAN") return "boolean";
  return "string";
};
