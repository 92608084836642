import {
    IS_EDITABLE_FIELD,
    CHART_ACTIVE_FIELDS_OPTIONS, CHART_SECTIONS,
    CHART_SECTION_RERENDER,
    KPI_SECTION_RERENDER,
    SET_CHART_DETAIL_FULL_INFO, SET_CHART_KPIS, SET_CHART_LIST_DATA, SET_FIELDS_TYPE_DATA, SET_USER_CHART_SAVED_LAYOUT, SET_INTERLINKING_PROJECT_IDS, SET_CHART_LOADED_ONCE
} from "../action-types/chartDetailAction.types"


export const SetChartActiveFieldOptions = (data) => {
    return {
        type: CHART_ACTIVE_FIELDS_OPTIONS,
        data
    }
}

export const SetChartSections = (data) => {
    return {
        type: CHART_SECTIONS,
        data
    }
}

export const RerenderChartSection = (isRender) => {
    return {
        type: CHART_SECTION_RERENDER,
        isRender
    }
}


export const RerenderKpiSection = (isRender) => {
    return {
        type: KPI_SECTION_RERENDER,
        isRender
    }
}
export const SetChartDetailFullInfo = (data) => {
    return {
        type: SET_CHART_DETAIL_FULL_INFO,
        data
    }
}


export const SetChartKPIs = (data) => {
    return {
        type: SET_CHART_KPIS,
        data
    }
}

export const SetUserSavedLayout = (chartData) => {
    return {
        type: SET_USER_CHART_SAVED_LAYOUT,
        chartData
    }
}

export const SetIsEditableSections = (isEdit) => {
    return {
        type: IS_EDITABLE_FIELD,
        isEdit
    }
}

export const SetChartListData = (data) => {
    return {
        type: SET_CHART_LIST_DATA,
        data
    }
}
export const SetChartFieldTypeData = (data) => {
    return {
        type: SET_FIELDS_TYPE_DATA,
        data
    }
}
export const SetInterlinkingProjectIds = (projectIds) => {
    return {
        type: SET_INTERLINKING_PROJECT_IDS,
        projectIds
    }
}
export const SetChartLoadedOnce = (isLoaded) => {
    return {
        type: SET_CHART_LOADED_ONCE,
        isLoaded
    }
}