export const HOME_ICON = "fa fa-home";
export const OPTIMIZATION_ICON = "fa fa-wand-magic-sparkles";
export const STRATEGIC_ICON = "fa fa-crosshairs";
export const KEY_DATE_MANAGEMENT_ICON = "fa fa-calendar-check";
export const BUILDING_ICON = "fa fa-city";
export const OCCUPANCY_ICON = "fa fa-circle-half-stroke";
export const LEASE_ICON = "fa fa-file-contract";
export const KEY_DATE_ICON = "fa fa-calendar-days";
export const PROJECT_ICON = "fa fa-diamond";
export const IMPORT_WIZARD_ICON = "fa fa-upload";
export const THEME_ICON = "fa fa-palette";
export const TEMPLATE_ICON = "fa fa-object-group";
export const MY_ACCOUNT_ICON = "fa fa-user";
export const LOGOUT_ICON = "fa fa-right-from-bracket";
export const SITE_SELECTION_ICON = "fa fa-money-check-dollar";
export const SITE_ALTERNATIVE_ICON = "fa fa-building";
export const CONFIGURATION_ICON = "fa fa-screwdriver-wrench";
export const SETTING_ICON = "fa fa-sliders"

export const TOOLTIP_CHART_SAVE_AS = "Save as .PNG image";
export const TOOLTIP_CREATE_PROJECT = "Create Project";
export const TOOLTIP_CREATE_TARGET = "Create Target";

export const LAT_LNG_ERROR = "No data found";
export const GOAL_ICONS = "fa fa-flag-checkered"
export const APPROVAL_ICON_STRATEGY = "fa fa-check-double"
