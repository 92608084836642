export const PAGEABLE = {
    buttonCount: 5,
    info: true,
    type: 'numeric',
    pageSizes: [10, 20, 50, 100, 200],
    previousNext: true
}

export const DATA_GRID_DEFAULT_STATE = {
    take: 10,
    skip: 0
};

export const DATA_GRID_DEFAULT_STYLE = {
    maHeight: "100vh"
}

export const areNumbers = (arr) => {
    return arr.reduce(function (result, val) {
        return result && typeof val === 'number';
    }, true);
}

export const GET_FORMATTED_OPTIONS = (arr) => {
    if (arr && arr.length === 0) return arr
    return arr.map((key) => {
        return (
            {
                label: key,
                value: key
            }
        )
    })
}

export const GET_BACK_DATE_KENDO_GRID = (numberOfDaysToMinus) => {
    const someDate = new Date();
    var result = someDate.setDate(someDate.getDate() - numberOfDaysToMinus);
    return new Date(result)
    // console.log(new Date(result))
}

export const GET_NEXT_DATE_KENDO_GRID = (numberOfDaysToAdd) => {
    let someDate = new Date();
    let finalResult = someDate.setDate(someDate.getDate() + parseInt(numberOfDaysToAdd));
    return new Date(finalResult)
    // console.log(new Date(result))
}


export const getFilteredDataGridResult = (arr, filtersOption, stringArrKeysName = []) => {
    let temp = arr;
    const filterKeysArr = Object.keys(filtersOption)

    for (let i = 0; i < filterKeysArr?.length; i++) {
        let dbKeyName = filterKeysArr[i];
        if (filtersOption?.hasOwnProperty(dbKeyName)) {
            const filterValue = filtersOption[dbKeyName]
            if (!!filterValue && Array.isArray(filterValue) && filterValue.length === 2 && areNumbers(filterValue)) {
                // for range slider filters
                if (!!filtersOption[dbKeyName] && filtersOption[dbKeyName].length > 1 && filtersOption?.[dbKeyName][1] > 0) {
                    temp = temp.filter((key) => {
                        return (key[dbKeyName] >= filtersOption[dbKeyName][0]) && (key[dbKeyName] <= filtersOption?.[dbKeyName][1])
                    })
                }
            } else if (!!filterValue && Array.isArray(filterValue)) {
                // for picklist filters
                const typeOptions = filterValue.map((key) => key?.value)
                if (typeOptions.length > 0) {
                    temp = temp.filter((key) => typeOptions.includes(key[dbKeyName]))
                }
            } else if (!!filterValue) {

                const filterPlannedStartDate = filtersOption[dbKeyName] ? filtersOption[dbKeyName].toString() : ""

                // when checking back date 
                if (filterPlannedStartDate.includes("-")) {
                    temp = temp.filter((key) => {
                        const listDate = new Date(key[dbKeyName])
                        const todaysDate = new Date()
                        const conditionDate = GET_BACK_DATE_KENDO_GRID(filterPlannedStartDate.replace("-", ""))
                        /* if you are checking for back date like "30 Days Back" 
                        then it should be greater than conditon date and less than current date 
                        */
                        return ((listDate >= conditionDate) && (listDate <= todaysDate))
                    })
                }
                // when checking next date 
                if (filterPlannedStartDate.includes("+")) {
                    temp = temp.filter((key) => {
                        const listDate = new Date(key[dbKeyName])
                        const todaysDate = new Date()
                        const conditionDate = GET_NEXT_DATE_KENDO_GRID(filterPlannedStartDate.replace("+", ""))
                        /* if you are checking for next date like "30 Days Next" 
                        then it should be less than conditon date and greater than current date 
                        */
                        return ((listDate <= conditionDate) && (listDate >= todaysDate))
                    })
                }


            }
        }
    }

    if (filtersOption.hasOwnProperty('stringSearch')) {
        // all string search manipulation added here 
        if (!!filtersOption?.stringSearch) {
            const nameField = filtersOption?.stringSearch.toLowerCase()
            let finalResult = []

            for (let tempIndex = 0; tempIndex < temp?.length; tempIndex++) {
                let key = temp[tempIndex]
                for (let index = 0; index < stringArrKeysName?.length; index++) {
                    let dbKeyName = stringArrKeysName[index];
                    let dbFieldValue = !!key[dbKeyName] ? key[dbKeyName].toLowerCase() : ""

                    if (!!dbFieldValue && dbFieldValue.includes(nameField)) {
                        //checking if already exist that record in finalresult
                        let isExist = finalResult.some((resultArr) => resultArr?.Id === key?.Id)
                        if (!isExist) {
                            finalResult.push(key)
                        }
                    }
                }
            }

            temp = finalResult
        }
    }

    console.log("final search rwes", temp)



    return temp
}

