import { Fragment, useEffect, useState } from "react"
import { Card } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { FORMATTED_DATE_UI, GROUP_BY_KEY } from "../../constants/commonFunc"
import { API_BASE_URL } from "../../constants/envConstants"
import http from "../../helper/Interceptors"
import { SetReloadNotificationApi } from "../../redux/actions/CommonActions"
import { GET_FIELD_INFO_BY_URL } from '../../pages/DynamicDataGrid/KendoGridConstants';
import { FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select } from '@mui/material';
import DataNotFoundMessage from "./kendo/DataNotFound"



export const ALL_COMMENTS = "All";
export const PROJECT_COMMENTS = "Project"
export const BUILDING_COMMENTS = "Building"
export const LEASE_COMMENTS = "Lease"
export const KEY_DATE_COMMENTS = "Key Date"
export const STRATEGIC_COMMENTS = "Strategic Plan"
export const COMMENT_BY_DATE = "Date"
export const GROUP_BY_TYPE = "Type"

const names = [
    GROUP_BY_TYPE,
    COMMENT_BY_DATE,
    PROJECT_COMMENTS,
    BUILDING_COMMENTS,
    LEASE_COMMENTS,
    KEY_DATE_COMMENTS,
    STRATEGIC_COMMENTS
];


const ChangeLogServerNotification = () => {

    const [loader, setLoader] = useState(false)
    const [activeIndex, setActiveIndex] = useState(0)
    const [logsArr, setLogsArr] = useState([])
    const [logsGrouped, setLogsGrouped] = useState([])
    const [searchInput, setSearchInput] = useState("")
    const [groupComment, setGroupComment] = useState([COMMENT_BY_DATE])

    const getChangeLogs = () => {
        setLoader(true)
        const apiPath = API_BASE_URL + "apexrest/v1/user/notification?type=log"
        http.get(apiPath)?.then((res) => {

            const apiData = res?.data

            setLogsArr(res?.data)
            // grouping by date
            const groups = apiData.reduce((groups, game) => {
                const date = game.created_datetime.split('T')[0];
                if (!groups[date]) {
                    groups[date] = [];
                }
                groups[date].push(game);
                return groups;
            }, {});

            const groupArrays = Object.keys(groups).map((date) => {
                return {
                    [date]: groups[date]

                };
            });

            let finalArr = Object.assign(...groupArrays, {})
            setLogsGrouped(finalArr)

        }).catch((err) => {
            console.error("failed to get change logs", err)
        })?.finally(() => {
            setLoader(false)
        })
    }

    const groupByKey = (list, key) => list.reduce((hash, obj) => ({ ...hash, [obj[key]]: (hash[obj[key]] || []).concat(obj) }), {})


    const handleFilteredSearchResult = () => {
        let finalArr = logsArr

        if (!!searchInput) {
            let searchedArr = logsArr?.filter((key) => key?.body?.toLowerCase().indexOf(searchInput?.toLowerCase()) !== -1)
            // finalArr = GROUP_BY_KEY(searchedArr, "target_record_object_name")
            finalArr = searchedArr
        }

        if (!!groupComment && groupComment.length > 0) {
            if (groupComment.includes(COMMENT_BY_DATE)) {
                const groups = finalArr.reduce((groups, game) => {
                    const date = game.created_datetime.split('T')[0];
                    if (!groups[date]) {
                        groups[date] = [];
                    }
                    groups[date].push(game);
                    return groups;
                }, {});

                const groupArrays = Object.keys(groups).map((date) => {
                    return {
                        [date]: groups[date]
                    };
                });

                finalArr = Object.assign(...groupArrays, {})
            }


            if (groupComment.includes(GROUP_BY_TYPE)) {
                finalArr = GROUP_BY_KEY(finalArr, "target_record_object_name")
            }

            if (groupComment.includes(PROJECT_COMMENTS)) {
                let filtered = finalArr?.filter((key) => key?.target_record_object_api_name === "Project__c")
                let groupedData = groupByKey(filtered, "target_record_object_name")
                finalArr = groupedData
            }
            if (groupComment.includes(BUILDING_COMMENTS)) {
                let filtered = finalArr?.filter((key) => key?.target_record_object_api_name === "Building__c")
                let groupedData = groupByKey(filtered, "target_record_object_name")
                finalArr = groupedData
            }
            if (groupComment.includes(LEASE_COMMENTS)) {
                let filtered = finalArr?.filter((key) => key?.target_record_object_api_name === "Lease__c")
                let groupedData = groupByKey(filtered, "target_record_object_name")
                finalArr = groupedData
            }
            if (groupComment?.includes("Strategic Plan")) {
                let filtered = finalArr?.filter((key) => key?.target_record_object_api_name === "Strategic_Plan__c")
                let groupedData = groupByKey(filtered, "target_record_object_name")
                finalArr = groupedData
            }
            if (groupComment?.includes("Key Date")) {
                let filtered = finalArr?.filter((key) => key?.target_record_object_api_name === "Key_Date__c")
                let groupedData = groupByKey(filtered, "target_record_object_name")
                finalArr = groupedData
            }
        }



        // if (currentUser?.current_company !== "a0vDp000000kyoWIAQ") {
        //     finalArr = []
        // }

        console.log("final search", finalArr)

        setLogsGrouped(finalArr)

    }





    const handleChangeGroup = (event) => {
        const {
            target: { value },
        } = event;
        setGroupComment(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };



    useEffect(() => {
        handleFilteredSearchResult()
    }, [searchInput, groupComment])

    useEffect(() => {
        getChangeLogs()
    }, [])



    return (
        <Fragment>
            <div className='search-fields mb-4 d-flex gap-10  p-3 pb-0 pt-0'>
                <input className='form-control' placeholder='Search'
                    value={searchInput}
                    onChange={(e) => setSearchInput(e?.target?.value)}
                    style={{ height: "38px" }}
                />
                <FormControl fullWidth>
                    <InputLabel id="demo-multiple-checkbox-label">Group by</InputLabel>
                    <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        value={groupComment}
                        onChange={handleChangeGroup}
                        input={<OutlinedInput label="Group by" />}
                        renderValue={(selected) => selected.join(', ')}
                        style={{ maxHeight: 280 }}

                    >
                        {names.map((name) => (
                            <MenuItem key={name} value={name}>
                                <ListItemText primary={name} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>

            {!!logsGrouped && Object.keys(logsGrouped)?.map((key, index) => {
                const logsArr = logsGrouped[key]

                return (
                    <Fragment key={index}>
                        <Card className="mt-0 cp">
                            <Card.Header onClick={() => setActiveIndex(index)}>
                                <Card.Title as="h5">
                                    {(groupComment?.includes(COMMENT_BY_DATE)) ? FORMATTED_DATE_UI(key) : ((groupComment?.includes(GROUP_BY_TYPE) && !searchInput) ? key : groupComment?.join(" "))}
                                </Card.Title>
                            </Card.Header>
                            <Card.Body className={`${activeIndex === index ? "d-block" : "d-none"} p-3`}>

                                <ul className="pb-0 mb-0" style={{ maxHeight: 500, overflow: "hidden", overflowY: "scroll" }}>

                                    <li className="media" style={{ borderBottom: "1px solid #dedede" }}>
                                        <div className="media-body">
                                            <div className="gridNotification">
                                                <div>
                                                    <h6 className="media-heading txt-primary">
                                                        Type
                                                    </h6>
                                                </div>
                                                <div>
                                                    <h6 className="media-heading txt-primary">
                                                        Activity
                                                    </h6>
                                                </div>
                                                <div>
                                                    <h6 className="media-heading txt-primary">
                                                        Description
                                                    </h6>
                                                </div>
                                                <div>
                                                    <h6 className="media-heading txt-primary">
                                                        By
                                                    </h6>
                                                </div>
                                                <div>
                                                    <h6 className="media-heading txt-primary">
                                                        On
                                                    </h6>
                                                </div>
                                            </div>

                                            <p>

                                            </p>
                                        </div>
                                    </li>

                                    {logsArr?.map((logInfo, index) => {
                                        return (
                                            <Fragment key={logInfo?.id}>
                                                <li className="media" style={{ borderBottom: "1px solid #dedede" }}>
                                                    <div className="media-body">
                                                        <div className="gridNotification">
                                                            <div>
                                                                {logInfo?.target_record_object_name}
                                                            </div>

                                                            <div>
                                                                <a href={`/${GET_FIELD_INFO_BY_URL(logInfo?.target_record_object_api_name)?.detailUrl}/${logInfo?.target_record}`}
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                >
                                                                    {logInfo?.title}
                                                                </a>

                                                            </div>
                                                            <div>
                                                                <p className="mb-0" dangerouslySetInnerHTML={{ __html: logInfo?.body }}>
                                                                </p>
                                                            </div>
                                                            <div>
                                                                <p className="mb-0">
                                                                    {logInfo?.created_by}
                                                                </p>
                                                            </div>
                                                            <div>
                                                                {FORMATTED_DATE_UI(logInfo?.created_datetime, true)}
                                                            </div>
                                                        </div>

                                                        <p>

                                                        </p>
                                                    </div>
                                                </li>
                                            </Fragment>
                                        )
                                    })}
                                </ul>

                            </Card.Body>
                        </Card>
                    </Fragment>
                )
            })}

            {!loader && !!logsGrouped && Object.keys(logsGrouped)?.length === 0 &&
                <DataNotFoundMessage message={"No logs found"} />
            }

        </Fragment>
    )
}

export default ChangeLogServerNotification