import { KENDO_DATA_GRID_DEFAULT_STATE } from "../../pages/DynamicDataGrid/KendoGridConstants"
import { EXCEL_EXPORT_DATA_GRID, MULTIPLE_EDIT_ENABLE_DATA_GRID, RELOAD_API_DATA_GRID, SET_DATA_GRID_ADVANCED_FILTERS, SET_DATA_GRID_CUSTOM_FILTERS, SET_DATA_GRID_FILTER_STATE, SET_DATA_GRID_LIST_DATA, SET_DATA_GRID_TOGGLE_FILTER_SLIDER, SET_DATA_GRID_USER_FILTERED_LIST, SET_FIELD_COLUMNS_OPTIONS_LIST, SET_FILTERED_DATA_GRID_LIST_DATA, SET_GRID_ACTIVE_USER_FILTER_DROPDOWN, SET_SAVED_SCHEMA_BY_USER, SET_SCHEMA_DUAL_LIST_FILTER_MODAL, SET_SCHEMA_DUAL_LIST_ORDER_MODAL, SET_VIEWABLE_LIST_COLUMNS } from "../action-types/dataGridAction.types"

const initialState = {
    fieldColumnsOptionsList: [], // formatted field options List
    fieldOptions: [], // original field options list
    listData: [], // original without modified list data array
    filteredListResult: [], // after filter apply list data result
    customFilters: {}, //selected custom filter 
    advancedFilters: {
        logic: 'and',
        filters: []
    },
    filterState: {},
    toggleFilterSlider: false, // open and hide filter slider
    activeUserFilterDrodpdown: "", // selected filter id
    userSavedFilterList: [], // user saved filters array list
    excelExport: false, // when export to excel button triggers
    multipleEdit: false,
    reloadApiData: false,
    userSavedConfigurationData: [],
    schemaDualListModal: false,
    schemaDualListFilterModal: false,
    viewableListColumns: []
}

const kendoDataGridReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_FIELD_COLUMNS_OPTIONS_LIST:
            return {
                ...state,
                fieldColumnsOptionsList: action.data
            }
        case SET_DATA_GRID_LIST_DATA:
            return {
                ...state,
                listData: action.data
            }
        case SET_FILTERED_DATA_GRID_LIST_DATA:
            return {
                ...state,
                filteredListResult: action.data
            }
        case SET_DATA_GRID_FILTER_STATE:
            return {
                ...state,
                filterState: action.filterState
            }
        case SET_DATA_GRID_CUSTOM_FILTERS:
            return {
                ...state,
                customFilters: action.customFilters
            }
        case SET_DATA_GRID_ADVANCED_FILTERS:
            return {
                ...state,
                advancedFilters: action.advancedFilters
            }
        case SET_DATA_GRID_TOGGLE_FILTER_SLIDER:
            return {
                ...state,
                toggleFilterSlider: action?.isActive
            }
        case SET_GRID_ACTIVE_USER_FILTER_DROPDOWN:
            return {
                ...state,
                activeUserFilterDrodpdown: action?.dropdownValue
            }
        case SET_DATA_GRID_USER_FILTERED_LIST:
            return {
                ...state,
                userSavedFilterList: action?.arr
            }
        case EXCEL_EXPORT_DATA_GRID:
            return {
                ...state,
                excelExport: action?.isExport
            }
        case MULTIPLE_EDIT_ENABLE_DATA_GRID:
            return {
                ...state,
                multipleEdit: action?.isActive
            }
        case RELOAD_API_DATA_GRID:
            return {
                ...state,
                reloadApiData: action?.isReload
            }
        case SET_SAVED_SCHEMA_BY_USER:
            return {
                ...state,
                userSavedConfigurationData: action?.data
            }
        case SET_SCHEMA_DUAL_LIST_ORDER_MODAL:
            return {
                ...state,
                schemaDualListModal: action?.isModalOpen
            }
        case SET_SCHEMA_DUAL_LIST_FILTER_MODAL:
            return {
                ...state,
                schemaDualListFilterModal: action?.isModalOpen
            }
        case SET_VIEWABLE_LIST_COLUMNS:
            return {
                ...state,
                viewableListColumns: action?.data
            }
        default:
            return state
    }

}

export default kendoDataGridReducer