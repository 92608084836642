import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
  Fragment,
} from "react";
import { Link, useHistory } from "react-router-dom";
import navigation from "../../../SidebarMenu";
import { BASE_TITLE, BASENAME } from "../../../config/constant";
import { useSearchDebounce } from "../../../hooks/useSearchDebounce";
import { GlobalSearch } from "../../../helper/api/search";
import { SetGlobalSearchResults } from "../../../redux/actions/globalSearchActions";
import { useDispatch, useSelector } from "react-redux";
import { ClickAwayListener, Fade, Paper, Popper } from "@mui/material";
import { isEmptySearchResult } from "../../../utils";
import debouce from "lodash.debounce";
import {
  Card,
  InputGroup,
  ListGroup,
  Overlay,
  Popover,
  Table,
  Tooltip,
} from "react-bootstrap";
import Select from "react-select";
import useWindowSize from "../../../hooks/useWindowSize";
import { RESTRICTED_ACCESS } from "../../../constants/envConstants";
import NavRight from "../NavBar/NavRight";

const variants = [
  { label: "All", value: "all" },
  { label: "Projects", value: "projects" },
  { label: "Buildings", value: "buildings" },
  { label: "Leases", value: "leases" },
  { label: "Key Date", value: "keydates" },
  { label: "Strategic Plan", value: "strategic_plans" },
];
let ALL = variants[0];

const SearchResult = ({
  title,
  results,
  entityType,
  handleCloseSearchResult,
}) => {
  const resultToRender = results?.slice(0, 10);
  const history = useHistory();

  const handleClickResult = (url) => {
    window.open(url, '_blank');
    handleCloseSearchResult();
  };

  return (
    <>
      {resultToRender && (
        <div>
          <Card.Title as="p" className={"font-weight-bold"}>
            {title.toUpperCase()}
          </Card.Title>
          <Card.Body className="pb-0 pt-0">
            <Table responsive>
              <tbody>
                {resultToRender.map((result) => {

                  console.log("entityType", entityType?.replace(/.$/, ""))
                  let url = entityType?.replace(/.$/, "")
                  if (url === "strategic_plan") {
                    url = "strategic_plan_detail"
                  }
                  if (url === "keydate") {
                    url = "keydate"
                  }


                  return (
                    <tr
                      className="cp"
                      onClick={() =>
                        handleClickResult(
                          `/${url}/${result.id}`
                        )
                      }
                    >
                      <td
                        className="pb-2 pt-2"
                        style={{ whiteSpace: "nowrap", overflow: "hidden" }}
                      >
                        <div className="text-body cp">
                          {result.name} {!!result?.external_id && `(${result?.external_id})`}
                        </div>
                        {(result?.address && result?.address?.trim() !== ',') ? <p className="mb-0">{result?.address}</p> : null }
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Card.Body>
        </div>
      )}
    </>
  );
};

const Breadcrumb = () => {
  const [main, setMain] = useState([]);
  const [item, setItem] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [search, setSearchQuery] = useSearchDebounce("");
  const [entityType, setEntityType] = useState("All");
  const searchResults = useSelector(
    (state) => state?.globalSearch?.searchResults
  );
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const searchBoxRef = useRef(null);
  const windowSize = useWindowSize();
  const [selectedVariants, setSelectedVariants] = React.useState([ALL]);
  const currentUser = useSelector((state) => state?.user?.userInfo);
  const VIEW_MODE =
    !!currentUser?.access_level && currentUser?.access_level?.length > 0
      ? currentUser?.access_level?.includes(RESTRICTED_ACCESS)
      : false;

  const handleChange = (selected) => {
    if (
      selected.length === 0 ||
      selected[selected.length - 1].value === ALL.value
    ) {
      setSelectedVariants([ALL]);
    } else if (selected.length > 1 && selected[0].value === ALL.value) {
      setSelectedVariants(selected.splice(1, selected.length));
    } else {
      setSelectedVariants(selected);
    }
  };
  useEffect(() => {
    navigation.items.map((item, index) => {
      if (item.type && item.type === "group") {
        getCollapse(item, index);
      }
      return false;
    });
  });
  const onSearchQueryChange = useCallback((e) => {
    setSearchQuery(e.target.value);
  });
  const debouncedResults = useMemo(() => {
    return debouce(onSearchQueryChange, 300);
  }, []);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });
  useEffect(() => {
    if (search && search.length >= 2) {
      (async () => {
        try {
          const res = await GlobalSearch(search);
          if (res.status === 200) {
            dispatch(SetGlobalSearchResults(res?.data));
            setOpen(true);
          }
        } catch (e) { }
      })();
    }
  }, [search]);

  const getCollapse = (item, index) => {
    if (item.children) {
      item.children.filter((collapse) => {
        if (collapse.type && collapse.type === "collapse") {
          getCollapse(collapse, index);
        } else if (collapse.type && collapse.type === "item") {
          if (document.location.pathname === BASENAME + collapse.url) {
            setMain(item);
            setItem(collapse);
          }
        }
        return false;
      });
    }
  };

  let mainContent, itemContent;
  let breadcrumbContent = "";
  let title = "";

  if (main && main.type === "collapse") {
    mainContent = (
      <ListGroup.Item as="li" bsPrefix=" " className="breadcrumb-item">
        <Link to="#">{main.title}</Link>
      </ListGroup.Item>
    );
  }

  if (item && item.type === "item") {
    title = item.title;
    itemContent = (
      <ListGroup.Item as="li" bsPrefix=" " className="breadcrumb-item">
        <Link to="#">{title}</Link>
      </ListGroup.Item>
    );

    if (item.breadcrumbs !== false) {
      breadcrumbContent = (
        <div className="page-header">
          <div className="page-block">
            <div className="row align-items-center"></div>
          </div>
        </div>
      );
    }

    document.title = title + BASE_TITLE;
  }

  const handleCloseSearchResult = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      {!VIEW_MODE && (
        <Fragment>
          {breadcrumbContent}
          <div className="projectTileBlock breadcrum mb-3 mt-2 d-flex justify-content-between">
            {title && (
              <div className="page-header-title">
                <h5 className="m-b-10"></h5>
              </div>
            )}
            <ListGroup
              as="ul"
              bsPrefix=" "
              className="breadcrumb mb-0 mr-auto align-items-center"
            >
              {/* <ListGroup.Item as="li" bsPrefix=" " className="breadcrumb-item">
                            <Link to="/">
                                <i className="feather icon-home" />
                            </Link>
                        </ListGroup.Item> */}
              {/* {mainContent}
                        {itemContent} */}
            </ListGroup>
            <ClickAwayListener
              onClickAway={() => {
                setOpen(false);
              }}
            >
              <div className="searchBox mr-4">
                <InputGroup>
                  <Select
                    isMulti
                    name="colors"
                    options={variants}
                    className="basic-multi-select"
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                      }),
                    }}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: "rgba(0,0,0,0.04)",
                      },
                    })}
                    Placeholder="Choose Type"
                    onChange={handleChange}
                    hideSelectedOptions
                    isClearable
                    value={selectedVariants}
                  />

                  <input
                    type="text"
                    placeholder="search"
                    className="form-control p-1 mb-0 search-input"
                    ref={searchBoxRef}
                    onChange={(e) => {
                      setAnchorEl(e.currentTarget);
                      debouncedResults(e);
                    }}
                    onFocus={() => {
                      if (!isEmptySearchResult(searchResults) && search) {
                        setOpen(true);
                      }
                    }}
                  />
                  <div>
                    <Overlay
                      target={searchBoxRef.current}
                      show={open}
                      placement="bottom"
                      container={this}
                      containerPadding={20}
                    >
                      {
                        <Popover
                          id="popover-basic"
                          className={"search-results-container"}
                          style={{
                            maxWidth: "400px",
                            width: "400px",
                          }}
                        >
                          {isEmptySearchResult(searchResults) && (
                            <>
                              <Popover.Content>
                                <h6>
                                  <i>No results found</i>
                                </h6>
                              </Popover.Content>
                            </>
                          )}
                          {!isEmptySearchResult(searchResults) && (
                            <>
                              <Popover.Title
                                as="p"
                                className={
                                  "pb-1 text-secondary font-weight-light text-right"
                                }
                              >
                                <>
                                  <i>
                                    showing top 10 results from each category
                                  </i>
                                </>{" "}
                              </Popover.Title>



                              <Popover.Content>
                                <div className={"h-100 overflow-auto"}>
                                  {searchResults &&
                                    selectedVariants.find(
                                      (s) => s.value === ALL.value
                                    ) &&
                                    variants
                                      .filter((s) => {
                                        return s.value !== ALL.value;
                                      })
                                      .map((v) => {
                                        return (
                                          <>
                                            {searchResults[v.value] && (
                                              <SearchResult
                                                key={v.value}
                                                title={v.label}
                                                results={searchResults[v.value]}
                                                handleCloseSearchResult={
                                                  handleCloseSearchResult
                                                }
                                                entityType={v.value}
                                              />
                                            )}
                                          </>
                                        );
                                      })}
                                  {searchResults &&
                                    selectedVariants.find(
                                      (s) => s.value !== ALL.value
                                    ) &&
                                    selectedVariants.map((v) => {
                                      return (
                                        <>
                                          {searchResults[v.value] && (
                                            <SearchResult
                                              key={v.value}
                                              title={v.label}
                                              results={searchResults[v.value]}
                                              entityType={v.value}
                                            />
                                          )}
                                        </>
                                      );
                                    })}
                                </div>
                              </Popover.Content>
                            </>
                          )}
                        </Popover>
                      }
                    </Overlay>
                    {/* <popover /> */}
                  </div>
                </InputGroup>
              </div>
            </ClickAwayListener>
            <div className=" topIcon">
              <NavRight />
            </div>
          </div>
        </Fragment>
      )}
    </React.Fragment>
  );
};

export default Breadcrumb;
