import { DATA_GRID_DEFAULT_STATE } from "../../constants/kendoDataGridConstants"
import { USA_DEFAULT_LATITUDE, USA_DEFAULT_LONGTITUDE } from "../../pages/ProjectPage/projectConstants"
import { SAVE_KENDO_GRAPH_CHART, SET_GLOBAL_SLIDER_VALUE, SET_CLUSTER_SEARCHED_POINTS, SET_SLIDER_VALUE_FIRST, SET_SLIDER_VALUE_SECOND, SET_SLIDER_VALUE_THIRD, SET_PROJECT_FILTER_STATE, SET_PROJECT_LIST_DATA, SET_PROJECT_VIEW_TYPE, STREET_VIEW_SHOW, CLEAR_ALL_SLIDER_VALUE, STREET_VIEW_SHOW_HIDE, OPEN_MAP_VIEW_INSIDE_LIST, CHANGE_OLD_ACTIVE_PLACE_TYPE, SET_CUSTOM_FILTERS_OPTIONS, SET_PROJECT_FILTERED_LIST_DATA, IS_STREET_VIEW_AVAILABLE, EXCEL_EXPORT_LIST, IS_MAP_CLUSTER_VISIBLE, SET_PLACE_TYPE, SET_ADD_NEW_CHART, RELOAD_SPREADSHEET_DATA, CHANGE_SPREADSHEET_DATA_ARR, SET_USER_FILTERED_LIST, SET_ACTIVE_USER_FILTER_DROPDOWN, TOGGLE_FILTER_SLIDER, SET_ADVANCED_FILTERS_OPTIONS, SET_ACTIVE_COMMENT_ID, SET_ACTIVE_CLAUSE_INFO, CREATE_PROJECT_MODAL, OPEN_SURVEY_TEMPLATE_MODAL, OPEN_REQUIREMENT_SURVEY_MODAL, OPEN_SITE_SELECTION_MODAL, OPEN_GANTT_CHART_LIST_VIEW } from "../action-types/ProjectAction.types"

const initialState = {
    projectViewType: 1,
    projectList: [],
    filterState: DATA_GRID_DEFAULT_STATE,
    exportKendoGraphChartAutomatic: false,
    streetViewMap: false,
    isStreetViewAvailable: false,
    openMapViewInsideList: false,
    streetViewDataObj: {},
    firstSlider: 10,
    secondSlider: 10,
    thirdSlider: 10,
    globalSlider: 10,
    oldActivePlaceType: "",
    clusterSearchedPoints: { lat: USA_DEFAULT_LATITUDE, lng: USA_DEFAULT_LONGTITUDE },
    customFilters: {},
    advancedFilters: {
        logic: 'and',
        filters: []
    },
    filteredListResult: [],
    excelExport: false,
    isMapClusterVisible: true,
    placeTypes: [],
    addNewChartArr: [],
    reloadSpreadSheetData: false,
    spreadSheetChangedDataArr: [],
    userSavedFilterList: [],
    activeUserFilterDrodpdown: "",
    toggleFilterSlider: false,
    activeCommentId: "",
    clauseTableColumnName: "",
    clauseRecordId: "",
    isClauseSliderOpen: false,
    createProjectModal: {},
    requirementSurveyModal: {},
    surveyTemplateModal: {},
    siteSelectionModal: {},
    ganttChartListView: {}
}

const projectReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PROJECT_VIEW_TYPE:
            return {
                ...state,
                projectViewType: action.viewType
            }

        case SET_PROJECT_LIST_DATA:
            return {
                ...state,
                projectList: action.data
            }
        case SET_PROJECT_FILTERED_LIST_DATA:
            return {
                ...state,
                filteredListResult: action.data
            }
        case SET_PROJECT_FILTER_STATE:
            return {
                ...state,
                filterState: action.filterState
            }
        case SAVE_KENDO_GRAPH_CHART:
            return {
                ...state,
                exportKendoGraphChartAutomatic: action.value
            }
        case STREET_VIEW_SHOW:
            return {
                ...state,
                streetViewDataObj: action.streetViewObj
            }
        case STREET_VIEW_SHOW_HIDE:
            return {
                ...state,
                streetViewMap: action.isShow,
            }
        case IS_STREET_VIEW_AVAILABLE:
            return {
                ...state,
                isStreetViewAvailable: action.isAvailable,
            }
        case OPEN_MAP_VIEW_INSIDE_LIST:
            return {
                ...state,
                openMapViewInsideList: action.isShow,
            }
        case CLEAR_ALL_SLIDER_VALUE:
            return {
                ...state,
                firstSlider: 0,
                secondSlider: 0,
                thirdSlider: 0,
                globalSlider: 10
            }

        case SET_SLIDER_VALUE_FIRST:
            return {
                ...state,
                firstSlider: action.value
            }
        case SET_SLIDER_VALUE_SECOND:
            return {
                ...state,
                secondSlider: action.value
            }
        case SET_SLIDER_VALUE_THIRD:
            return {
                ...state,
                thirdSlider: action.value
            }
        case SET_GLOBAL_SLIDER_VALUE:
            return {
                ...state,
                globalSlider: action.value
            }

        case SET_CLUSTER_SEARCHED_POINTS:
            return {
                ...state,
                clusterSearchedPoints: action.points
            }
        case CHANGE_OLD_ACTIVE_PLACE_TYPE:
            return {
                ...state,
                oldActivePlaceType: action.placeType
            }
        case SET_PLACE_TYPE:
            return {
                ...state,
                placeTypes: action.placeTypes
            }

        case SET_CUSTOM_FILTERS_OPTIONS:
            return {
                ...state,
                customFilters: action.customFilters
            }
        case SET_ADVANCED_FILTERS_OPTIONS:
            return {
                ...state,
                advancedFilters: action.advanceFilter
            }
        case EXCEL_EXPORT_LIST:
            return {
                ...state,
                excelExport: action.isExport
            }
        case IS_MAP_CLUSTER_VISIBLE:
            return {
                ...state,
                isMapClusterVisible: action.isShow
            }

        case SET_ADD_NEW_CHART:
            return {
                ...state,
                addNewChartArr: action.payload
            }
        case RELOAD_SPREADSHEET_DATA:
            return {
                ...state,
                reloadSpreadSheetData: action?.reload
            }
        case CHANGE_SPREADSHEET_DATA_ARR:
            return {
                ...state,
                spreadSheetChangedDataArr: action?.arr
            }

        case SET_USER_FILTERED_LIST:
            return {
                ...state,
                userSavedFilterList: action?.arr
            }
        case SET_ACTIVE_USER_FILTER_DROPDOWN:
            return {
                ...state,
                activeUserFilterDrodpdown: action?.dropdownValue
            }
        case TOGGLE_FILTER_SLIDER:
            return {
                ...state,
                toggleFilterSlider: action?.isActive
            }
        case SET_ACTIVE_COMMENT_ID:
            return {
                ...state,
                activeCommentId: action?.commentId
            }
        case SET_ACTIVE_CLAUSE_INFO:
            return {
                ...state,
                clauseTableColumnName: action?.clauseTableColumnName,
                clauseRecordId: action?.clauseRecordId,
                isClauseSliderOpen: action?.isClauseSliderOpen
            }

        case CREATE_PROJECT_MODAL:
            return {
                ...state,
                createProjectModal: action?.createProjectObj
            }
        case OPEN_REQUIREMENT_SURVEY_MODAL:
            return {
                ...state,
                requirementSurveyModal: action?.requirementObj
            }
        case OPEN_SURVEY_TEMPLATE_MODAL:
            return {
                ...state,
                surveyTemplateModal: action?.modalObj
            }
        case OPEN_SITE_SELECTION_MODAL:
            return {
                ...state,
                siteSelectionModal: action?.modalObj
            }
        case OPEN_GANTT_CHART_LIST_VIEW:
            return {
                ...state,
                ganttChartListView: action?.modalObj
            }

        default:
            return state
    }

}

export default projectReducer