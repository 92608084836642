import { Drawer } from "@mui/material";
import React, { Fragment, useState } from "react";
import CommentWidgetSidebar from "./CommentWidgetSidebar";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const CommentWidgetSidebarDrawer = ({
  groupBy,
  projectId,
  isModalOpen = false,
  onClose,
  iconClassName = ""
}) => {
  const [commentModalOpen, setCommentModalOpen] = useState(isModalOpen);

  const handleCloseModalOpen = () => {
    setCommentModalOpen(false);
    if (!!onClose) {
      onClose();
    }
  };

  return (
    <Fragment>
      <OverlayTrigger placement="bottom" overlay={<Tooltip>Comments</Tooltip>}>
        <span
          className={`chatIconTop mr-3 cp ${!!projectId ? "detailChat" : ""} ${iconClassName}`}
        >
          {/* <ChatBubbleOutlineIcon
            style={{ width: 18, color: "#333333", marginTop: "2px" }}
            onClick={() => setCommentModalOpen(!commentModalOpen)}
            className="cp"
          /> */}
          <i class="fa-solid fa-message mr-0" onClick={() => setCommentModalOpen(!commentModalOpen)}></i>
        </span>
      </OverlayTrigger>

      <div className={`${commentModalOpen ? "open" : ""} `}>
        <div className="style-block">
          <Drawer
            anchor={"right"}
            open={commentModalOpen}
            onClose={() => handleCloseModalOpen()}
          >
            <div style={{ width: "75vw" }} className="p-4 pt-5">
              <div className="mb-3 d-flex justify-content-between">
                <h5>Comments</h5>
                <OverlayTrigger overlay={<Tooltip>Close</Tooltip>}>
                  <button className="btn cPadding  m-0 px-3 btn-secondary" onClick={() => handleCloseModalOpen()}>
                    <i className="fa fa-close mr-0" />
                  </button>
                </OverlayTrigger>
              </div>
              <CommentWidgetSidebar
                isOpen={commentModalOpen}
                groupBy={groupBy}
                projectId={projectId}
              />
            </div>
          </Drawer>
        </div>
      </div>
    </Fragment>
  );
};

export default CommentWidgetSidebarDrawer;
