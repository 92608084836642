// import moment from "moment";

import { FORMATTED_DATE_UI } from "../constants/commonFunc";

export function isEmptySearchResult(searchResults) {
    return !searchResults || (!searchResults.projects && !searchResults.buildings);
}

export const getFieldsObjectFromArray = data => data?.reduce((result, field) => {
    result[field.name] = field;
    return result;
}, {});

export const getRelationshipsObjectFromArray = data => data?.reduce((result, field) => {
    result[field.relationship_name] = field;
    return result;
}, {});

export const getFieldLabel = (field) => field?.label;

export const getFormattedValue = (field) => {
    let value = field?.value
    // if (value === null || value === undefined || value === "") {
    //     value = "N/A"
    // }

    switch (field?.type) {
        case "CURRENCY":
            return value ? `$${Number(value).toFixed(2)}` : '';
        case "DATE":
            // return value ? moment(value).format("MM/DD/YYYY") : '';
            return value ? FORMATTED_DATE_UI(value) : ""
        default:
            return value
    }


};


export const getFieldAlignment = field => ["CURRENCY", "DOUBLE"].includes(field?.type) ? "right" : "left"

let labels = {
    term_in_months: "Term (Months)",
    annual_growth_rate: "HC Annual Growth (%)",
    inflation_rate: "Annual Rent Escalation (%)",
    ti_allowance: "TI Allowance ($)",
    free_rent_in_months: "Free Rent (Months)",
}
export const formatLabel = (label) => {
    // Remove _ -  and replace with space and capitalize first letter of each word

    if (labels[label]) {
        return labels[label]
    }

    return label.replace(/[_-]/g, " ").replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
}
