import { Fragment } from "react"
import { useSelector } from "react-redux"

const KendoLoader = () => {

    const isLoading = useSelector((state) => state?.global?.globalLoader)

    return (
        <Fragment>
            {isLoading &&
                <div className="k-loading-mask">
                    <span className="k-loading-text">Loading</span>
                    <div className="k-loading-image"></div>
                    <div className="k-loading-color"></div>
                </div>
            }
        </Fragment>

    )
}

export default KendoLoader