import React, { useContext, useEffect, useRef, useState, useMemo } from "react";

import Navigation from "./Navigation";
import NavBar from "./NavBar";
import Breadcrumb from "./Breadcrumb";
import Configuration from "./Configuration";

import useWindowSize from "../../hooks/useWindowSize";
import useOutsideClick from "../../hooks/useOutsideClick";
import { ConfigContext } from "../../contexts/ConfigContext";
import * as actionType from "../../store/actions";
import MainSlider from "../../components/Slider/MainSlider";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { RESTRICTED_ACCESS } from "../../constants/envConstants";
import { getCookie } from "../../constants/commonFunc";
import { useParams, useLocation } from "react-router-dom";
import {
  getColorCodeClassByParam,
  getColorCodeClassByParamDetail,
  getColorCodeClassByParam2,
} from "../../pages/ProjectPage/projectConstants";

const AdminLayout = ({ children }) => {
  const params = useParams();
  const location = useLocation();

  const params3 = window.location.pathname;

  // const { budgetColor,projectColor,keyDateColor,leaseColor,buildingColor } = configContext.state;

  const windowSize = useWindowSize();
  const ref = useRef();
  const configContext = useContext(ConfigContext);
  const history = useHistory();

  const {
    collapseMenu,
    layout,
    subLayout,
    headerFixedLayout,
    configBlock,
    buttonColor,
    tabColor,
    budgetColor,
    projectColor,
    keyDateColor,
    leaseColor,
    buildingColor,
  } = configContext.state;
  const { dispatch } = configContext;
  const activeUserFilterDropDown = useSelector(
    (state) => state?.project?.activeUserFilterDrodpdown
  );
  const currentUser = useSelector((state) => state?.user?.userInfo);
  const VIEW_MODE =
    !!currentUser?.access_level && currentUser?.access_level?.length > 0
      ? currentUser?.access_level?.includes(RESTRICTED_ACCESS)
      : false;

  useOutsideClick(ref, () => {
    if (collapseMenu) {
      dispatch({ type: actionType.COLLAPSE_MENU });
    }
  });

  useEffect(() => {
    if (
      windowSize.width > 992 &&
      windowSize.width <= 1024 &&
      layout !== "horizontal"
    ) {
      dispatch({ type: actionType.COLLAPSE_MENU });
    }

    if (layout === "horizontal" && windowSize.width < 992) {
      dispatch({ type: actionType.CHANGE_LAYOUT, layout: "vertical" });
    }
  }, [dispatch, layout, windowSize]);

  const mobileOutClickHandler = () => {
    if (windowSize.width < 992 && collapseMenu) {
      dispatch({ type: actionType.COLLAPSE_MENU });
    }
  };

  let mainClass = ["pcoded-wrapper"];
  if (layout === "horizontal" && subLayout === "horizontal-2") {
    mainClass = [...mainClass, "container"];
  }

  let common = (
    <React.Fragment>
      <Navigation />
    </React.Fragment>
  );

  const [getParam, setGetParams] = useState(params[0]);
  const [colorClass, setColorClass] = useState("");

  const mainContainer = useMemo(() => {
    let mainContainer = (
      <React.Fragment>
        <NavBar />
        <div

          className={`pcoded-main-container hi ${!VIEW_MODE ? "" : "viewModeOnlyHeader"
            } ${buttonColor} ${tabColor}`}

        >
          <div className={mainClass.join(" ")}>
            <div className={`pcoded-content pt-0 `}>
              <div
                className={`pcoded-inner-content 
              
              ${getColorCodeClassByParam(
                  budgetColor,
                  projectColor,
                  keyDateColor,
                  leaseColor,
                  buildingColor,
                  location
                )
                    ? getColorCodeClassByParam(
                      budgetColor,
                      projectColor,
                      keyDateColor,
                      leaseColor,
                      buildingColor,
                      location
                    )
                    : getColorCodeClassByParamDetail(
                      budgetColor,
                      projectColor,
                      keyDateColor,
                      leaseColor,
                      buildingColor,
                      location
                    )
                  }

    }
              
              `}
              >
                <Breadcrumb />
                {children}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
    return mainContainer;
  }, [
    params,
    colorClass,
    location,
    budgetColor,
    projectColor,
    keyDateColor,
    leaseColor,
    buildingColor,
  ], []);

  if (windowSize.width < 992) {
    let outSideClass = ["nav-outside"];
    if (collapseMenu) {
      outSideClass = [...outSideClass, "mob-backdrop"];
    }
    if (headerFixedLayout) {
      outSideClass = [...outSideClass, "mob-fixed"];
    }

    common = (
      <div className={outSideClass.join(" ")} ref={ref}>
        {common}
      </div>
    );


  }

  useEffect(() => {
    let accessToken = getCookie("Authorization");
    let sessionId = getCookie("SessionId");
    if (!accessToken || !sessionId) {
      window.localStorage.setItem("redirect_url_login", window.location.href)
      window.open(window.location.origin, "_self");
      return;
    }
  }, []);

  return (
    <React.Fragment>
      {getCookie("Authorization") && getCookie("SessionId") && (
        <>
          {common}
          {mainContainer}
        </>
      )}

      {/* {configBlock && <Configuration />} */}
    </React.Fragment>
  );
};

export default AdminLayout;
