import { API_BASE_URL } from "./envConstants";

export const SAVE_USER_FILTERS_PROJECT_LIST =
  API_BASE_URL + "apexrest/v1/user/configuration";
export const GET_PROJECTS_FILTERS_LIST_FOR_USER =
  API_BASE_URL + "apexrest/v1/user/configuration";
export const SAVE_PROJECT_COMMENTS_BY_ID =
  API_BASE_URL + "apexrest/v1/chat/:projectId";
export const GET_ALL_PROJECT_COMMENTS_BY_ID =
  API_BASE_URL + "apexrest/v1/chat/:projectId";
export const GET_PROJECT_DETAILS_BY_ID =
  API_BASE_URL + "apexrest/v1/record?id=";
export const SAVE_PROJECT_MAPPING_LAYOUT =
  API_BASE_URL + "apexrest/v1/user/configuration";
export const GET_PROJECT_DETAIL_MAPPING_LAYOUT =
  API_BASE_URL + "apexrest/v1/user/configuration";
export const PROJECT_DETAIL_UPLOAD_FILE_API =
  API_BASE_URL + "data/v55.0/sobjects/ContentVersion/";
export const GET_ALL_FILES_PROJECT_DETAIL_API =
  API_BASE_URL + "apexrest/v1/files";

export const GET_LEASES_FILTERS_LIST_FOR_USER =
  API_BASE_URL + "apexrest/v1/user/configuration";
export const SAVE_USER_FILTERS_LEASES_LIST =
  API_BASE_URL + "apexrest/v1/user/configuration";
export const GET_USER_CONFIGURATION_DETAILS =
  API_BASE_URL + "apexrest/v1/user/configuration";

export const GET_NOTIFICATION_LIST =
  API_BASE_URL + "apexrest/v1/user/notification";

export const DATA_GRID_SAVED_FILTERS_LIST_API =
  API_BASE_URL + "apexrest/v1/user/configuration";
export const OPTIMIZATION_MODE_LINEAR =
  API_BASE_URL + "apexrest/v1/optimization?mode=V1A";
export const OPTIMIZATION_MODE_BRUTE_FORCE =
  API_BASE_URL + "apexrest/v1/optimization?mode=V1B";
export const GET_USER_DETAILS = API_BASE_URL + "apexrest/v1/user";
export const UPDATE_USER_DETAILS = API_BASE_URL + "apexrest/v1/record";
export const RESET_PASSWORD = API_BASE_URL + "apexrest/v1/password/reset";
export const UPDATE_FEEDBACK = API_BASE_URL + "apexrest/v1/record";
