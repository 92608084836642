import Slider from "@mui/material/Slider";
import React, { Fragment } from "react"

const RangeSliderMUI = ({ value, min, max, onChange }) => {


    return (
        <Fragment>
            <Slider
                min={min ? min : 0}
                max={max ? max : 100}
                value={value}
                onChange={onChange}
                valueLabelDisplay="auto"
            />
        </Fragment>
    )
}

export default RangeSliderMUI