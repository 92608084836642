import { Skeleton } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { uploadFile } from "react-s3";
import { toast } from "react-toastify";
import {
  DETECT_FILE_TYPE,
  formatFileSize,
  FORMATTED_DATE_UI,
  S3_BUCKET_SIGN,
} from "../../constants/commonFunc";
import { API_BASE_URL, S3_BUCKET, S3_FILE_UPLOAD_CONFIG } from "../../constants/envConstants";
import S3_FILE_HELPER from "../../constants/S3FileUploadHelper";
import FileSaver from "file-saver";
import http from "../../helper/Interceptors"
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmDeleteMappingModal from "../../pages/ImportWizardPage/ConfirmDeleteMappingModal";

const UploadImageToS3WithReactS3 = ({
  recordId,
  onImageUpload,
  onDeleteSuccess,
  closeModal,
  page,
}) => {
  const [imagesList, setImagesList] = useState([]);
  const [loader, setLoader] = useState(false);

  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [deleteImageModal, setDeleteImageModal] = useState(false);
  const [deleteImageFullName, setDeletImageFullName] = useState("");

  const userInfo = useSelector((state) => state?.user?.userInfo);
  const currentCompany = userInfo?.current_company;

  const folderName = !!recordId
    ? currentCompany + "/" + recordId
    : currentCompany;

  const { GET_ALL_IMAGES_PUBLIC_URL, DELETE_S3_FILE } = S3_FILE_HELPER();

  const handleFileInput = (e) => {
    setSelectedFile(e.target.files[0]);
    handleUpload(e.target.files[0], folderName);
  };

  const handleUpload = async (file, folderName = "") => {
    const folderNameConfig = {
      dirName: folderName,
    };
    const config = { ...S3_FILE_UPLOAD_CONFIG, ...folderNameConfig };
    setLoading(true);
    await uploadFile(file, config)
      .then((data) => {
        console.log("image uploaded", data);
        toast.success(file?.name + " successfully uploaded");
        handleCreateRecordImagePath(data?.location)
        setSelectedFile(null);
        handleGetAllImagesInsideFolder();
        if (!!onImageUpload) {
          onImageUpload();
        }
      })
      .catch((err) => {
        toast.error("Failed to upload file " + err?.response?.data?.message);
      })
      ?.finally(() => {
        setLoading(false);
      });
  };

  const handleGetAllImagesInsideFolder = async () => {
    setLoader(true);
    const result = await GET_ALL_IMAGES_PUBLIC_URL(folderName);
    setImagesList(result);
    setLoader(false);
  };

  const handleDeleteRecordFromDB = (fileName) => {
    let slugUrl = "https://hyprespace-app.s3.amazonaws.com/" + fileName
    const apiPath = API_BASE_URL + "apexrest/v1/records?p1=Link__c&object=File__c&v1=" + slugUrl;
    http.delete(apiPath)?.then((res) => {
      if (res?.status === 200) {

      }
    })
  }

  const handleDeleteFile = async (fileName) => {
    handleDeleteRecordFromDB(fileName)
    DELETE_S3_FILE(fileName);
    setDeleteImageModal(false);
    setTimeout(() => {
      handleGetAllImagesInsideFolder();
      if (!!onDeleteSuccess) {
        onDeleteSuccess();
      }
    }, 1000);
  };

  const handleDownloadImage = (url) => {
    FileSaver.saveAs(url, "Twitter-logo.jpg");
  };

  const handleCreateRecordImagePath = (imagePath = "") => {
    const formData = {
      attributes: {
        type: "File__c",
      },
      Company__c: currentCompany,
      User__c: userInfo?.id,
      Name: "",
      Link__c: imagePath,
      Size__c: ""

    }
    const apiPath = API_BASE_URL + "apexrest/v1/record";
    http
      .post(apiPath, formData)
      ?.then((res) => {
        if (res?.status === 200) {
          // console.log("Successfully uploaded")
        }
      })
      .catch((err) => {
        console.error("error failed", err);
        toast.error("Failed");
      });
  }

  useEffect(() => {
    if (!currentCompany) return;
    handleGetAllImagesInsideFolder();
  }, [folderName]);

  const handleDeleteImageModal = (fileName, fileFullName) => {
    if (!!closeModal) {
      closeModal();
    }
    setDeleteImageModal(fileName);
    setDeletImageFullName(fileFullName)
  };

  return (
    <Fragment>
      <div id="dropzone">
        <div>{selectedFile?.name ? selectedFile?.name : "Drop a file"}</div>
        <input type="file" onChange={(e) => handleFileInput(e)} />
      </div>
      {/* {page == "default" && (
        <div className="col-md-12 text-center">
          <button
            onClick={() => handleUpload(selectedFile, folderName)}
            className={`btn btn-primary text-center ${
              loading ? "disabled" : ""
            }`}
          >
            {loading ? "Please wait..." : "Upload"}
          </button>
        </div>
      )} */}

      <div className="col-md-12 uploadedFilesSection">
        {loader && page == "default" && (
          <Fragment>
            <Skeleton height={50} />
            <Skeleton height={50} />
            <Skeleton height={50} />
            <Skeleton height={50} />
            <Skeleton height={50} />
          </Fragment>
        )}



        {console.log("image list", imagesList)}

        {!loader && !!imagesList && imagesList?.length > 0 && (
          <Fragment>
            <h4 className="mb-4 mt-5">All Files</h4>
            {imagesList?.map((file) => {
              return (
                <Fragment>
                  <div className="d-flex justify-content-between">
                    <div className="d-flex">

                      {DETECT_FILE_TYPE(file?.Key) === "xlsx" &&
                        <i class="fa-solid fa-file-excel s3ImageInline fileUploadIcon"></i>
                      }
                      {DETECT_FILE_TYPE(file?.Key) === "ppt" &&
                        <i class="fa-solid fa-file-powerpoint s3ImageInline fileUploadIcon"></i>
                      }
                      {DETECT_FILE_TYPE(file?.Key) === "zip" &&
                        <i class="fa-solid fa-file-zipper s3ImageInline fileUploadIcon"></i>
                      }
                      {DETECT_FILE_TYPE(file?.Key) === "doc" &&
                        <i class="fa-solid fa-file-word s3ImageInline fileUploadIcon"></i>
                      }

                      {DETECT_FILE_TYPE(file?.Key) === "image" &&
                        <img src={file?.publicPath} className="s3ImageInline fileUploadIcon" />
                      }
                      {DETECT_FILE_TYPE(file?.Key) === "csv" &&
                        <i className="fa fa-file-csv s3ImageInline fileUploadIcon" />
                      }
                      {DETECT_FILE_TYPE(file?.Key) === "pdf" &&
                        <i className="fa fa-file-pdf s3ImageInline fileUploadIcon" />
                      }
                      {!(["csv", "pdf", "image", "xlsx", "zip", "doc", "ppt"]?.includes(DETECT_FILE_TYPE(file?.Key))) &&
                        <i className="fa fa-file-lines s3ImageInline fileUploadIcon" />
                      }
                      <div>
                        <p className="mb-1">
                          {
                            file?.Key?.split("/")[
                            file?.Key?.split("/").length - 1
                            ]
                          }

                        </p>
                        <p className="text-muted mb-1">
                          <span className="mr-2">
                            <b> &nbsp;{formatFileSize(file?.Size)}</b>
                          </span> {FORMATTED_DATE_UI(file?.LastModified, true)}
                        </p>
                      </div>
                    </div>
                    <div>
                      <a
                        className=" ml-2 k-button k-button-md k-rounded-md downloadIc"
                        onClick={() => handleDownloadImage(file?.publicPath)}
                      >
                        <i class="fa fa-download" aria-hidden="true" />


                      </a>
                      <a
                        className=" ml-2 k-button k-button-md k-rounded-md  deleteIc"
                        onClick={() => handleDeleteImageModal(file?.Key)}
                      >
                        <i class="fa fa-trash" aria-hidden="true" />
                      </a>
                    </div>
                  </div>
                  <hr className="mb-3 mt-1" />
                </Fragment>
              );
            })}
          </Fragment>
        )}

        {!!deleteImageModal && (
          <ConfirmDeleteMappingModal
            onCloseModal={() => setDeleteImageModal(false)}
            onSave={() => handleDeleteFile(deleteImageModal)}
            isModalOpen={!!deleteImageModal ? true : false}
          />
        )}
      </div>
    </Fragment>
  );
};

export default UploadImageToS3WithReactS3;