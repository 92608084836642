import { KEY_DATE_MAP_VIEW_OPEN, OPEN_EDIT_KEY_DATES_MODAL, SET_FILTERED_KEY_DATES_LIST_DATA, SET_KEY_DATES_CUSTOM_FILTERS, SET_KEY_DATES_LIST_DATA, SET_KEY_DATES_TOGGLE_FILTER_SLIDER } from "../action-types/keyDatesAction.types"


export const SetKeyDatesListDataAction = (data) => {
    return {
        type: SET_KEY_DATES_LIST_DATA,
        data
    }
}

export const SetFilteredKeyDatesListDataAction = (data) => {
    return {
        type: SET_FILTERED_KEY_DATES_LIST_DATA,
        data
    }
}

export const SetKeyDatesCustomFilters = (customFilters) => {
    return {
        type: SET_KEY_DATES_CUSTOM_FILTERS,
        customFilters
    }
}


export const ToggleFilterSlider = (isActive) => {
    return {
        type: SET_KEY_DATES_TOGGLE_FILTER_SLIDER,
        isActive
    }
}
export const OpenEditKeyDateModal = (objInfo) => {
    return {
        type: OPEN_EDIT_KEY_DATES_MODAL,
        objInfo
    }
}

export const OpenKeyDateMapView = (data) => {
    return {
        type: KEY_DATE_MAP_VIEW_OPEN,
        data
    }
}