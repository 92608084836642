import {
    IS_EDITABLE_FIELD,
    PROJECT_ACTIVE_FIELDS_OPTIONS, PROJECT_SECTIONS,
    PROJECT_SECTION_RERENDER,
    SET_INTERLINKING_PROJECT_IDS,
    SET_PROJECT_DETAIL_FULL_INFO, SET_PROJECT_KPIS, SET_USER_SAVED_LAYOUT
} from "../action-types/ProjectDetailAction.types"


export const SetProjectActiveFieldOptions = (data) => {
    return {
        type: PROJECT_ACTIVE_FIELDS_OPTIONS,
        data
    }
}

export const SetProjectSections = (data) => {
    return {
        type: PROJECT_SECTIONS,
        data
    }
}

export const RerenderProjectSection = (isRender) => {
    return {
        type: PROJECT_SECTION_RERENDER,
        isRender
    }
}

export const SetProjectDetailFullInfo = (data) => {
    return {
        type: SET_PROJECT_DETAIL_FULL_INFO,
        data
    }
}


export const SetProjectKPIs = (data) => {
    return {
        type: SET_PROJECT_KPIS,
        data
    }
}

export const SetUserSavedLayout = (data) => {
    return {
        type: SET_USER_SAVED_LAYOUT,
        data
    }
}

export const SetIsEditableSections = (isEdit) => {
    return {
        type: IS_EDITABLE_FIELD,
        isEdit
    }
}

export const SetInterlinkingProjectIds = (projectIds) => {
    return {
        type: SET_INTERLINKING_PROJECT_IDS,
        projectIds
    }
}