import { COMPOSITE_ACTIVE_FIELDS_OPTIONS, OPEN_CREATE_BUDGET_TEMPLATE_MODAL, OPEN_CREATE_LANDLORD_SURVEY_TEMPLATE_MODAL, OPEN_CREATE_MARKET_SURVEY_TEMPLATE_MODAL, OPEN_FINANCIAL_MODAL, SET_CAPITAL_ACTIVE_VERSION_FINANCIAL_ANALYSIS, SET_LEASE_ACTIVE_VERSION_FINANCIAL_ANALYSIS, SITE_SELECTION_ACTIVE_TAB, SITE_SELECTION_DISTANT_METRICS } from "../action-types/CompositeAction.types"

const initialState = {
    activeInfoFields: [], // checked fields section in project detail
    financialModalOpen: {},
    openCreateBudgetTemplateModal: {},
    openCreateMarketSurveyTemplateModal: {},
    openCreateLandlordSurveyTemplateModal: {},
    siteSelectionActiveTab: "Long List",
    leaseActiveVersion: [],
    capitalActiveVersion: "",
    siteSelectionDistantMetrics: {}
}

const compositeReducer = (state = initialState, action) => {
    switch (action.type) {

        case COMPOSITE_ACTIVE_FIELDS_OPTIONS:
            return {
                ...state,
                activeInfoFields: action.data
            }
        case OPEN_FINANCIAL_MODAL:
            return {
                ...state,
                financialModalOpen: action?.data
            }
        case OPEN_CREATE_BUDGET_TEMPLATE_MODAL:
            return {
                ...state,
                openCreateBudgetTemplateModal: action?.data
            }
        case OPEN_CREATE_MARKET_SURVEY_TEMPLATE_MODAL:
            return {
                ...state,
                openCreateMarketSurveyTemplateModal: action?.data
            }
        case OPEN_CREATE_LANDLORD_SURVEY_TEMPLATE_MODAL:
            return {
                ...state,
                openCreateLandlordSurveyTemplateModal: action?.data
            }
        case SITE_SELECTION_ACTIVE_TAB:
            return {
                ...state,
                siteSelectionActiveTab: action?.tabName
            }
        case SET_LEASE_ACTIVE_VERSION_FINANCIAL_ANALYSIS:
            return {
                ...state,
                leaseActiveVersion: action?.versionId
            }
        case SET_CAPITAL_ACTIVE_VERSION_FINANCIAL_ANALYSIS:
            return {
                ...state,
                capitalActiveVersion: action?.versionId
            }
        case SITE_SELECTION_DISTANT_METRICS:
            return {
                ...state,
                siteSelectionDistantMetrics: action?.data
            }


        default:
            return state
    }

}

export default compositeReducer