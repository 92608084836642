import react, { Fragment } from "react"
import { Row, Col, Button, Form, Modal } from 'react-bootstrap';

const ConfirmDeleteMappingModal = ({ isModalOpen, onCloseModal, onSave }) => {

    return (
        <Fragment>
            <Modal show={isModalOpen} onHide={() => onCloseModal()}>
                <Modal.Header closeButton>
                    <Modal.Title as="h5">
                        Delete
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-md-12">
                        <h5 className="text-center">Are you sure? </h5>
                    </div>
                </Modal.Body>
                <Modal.Footer className="p-3">
                    <Button variant="danger" type="submit" onClick={onSave}>
                        Yes
                    </Button>

                </Modal.Footer>

            </Modal>
        </Fragment>
    )
}

export default ConfirmDeleteMappingModal