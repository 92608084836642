import AWS from 'aws-sdk';
import { S3_ACCESS_KEY, S3_BUCKET, S3_REGION, S3_SECRET_ACCESS_KEY } from "./envConstants";

const S3_FILE_HELPER = () => {

    AWS.config.update({
        accessKeyId: S3_ACCESS_KEY,
        secretAccessKey: S3_SECRET_ACCESS_KEY
    });

    const S3_BUCKET_SIGN = new AWS.S3({
        region: S3_REGION,
        signatureVersion: 'v4'
    });

    const GET_S3_IMAGE = async (fileKey, expiryInSecond = 7200) => {
        const params = {
            Bucket: S3_BUCKET,
            Key: fileKey,
            Expires: expiryInSecond // URL expires after 1 minute
        };

        return await new Promise(function (resolve, reject) {
            return S3_BUCKET_SIGN.getSignedUrl('getObject', params, function (err, url) { resolve(url); });
        });
    }

    const CREATE_EMPTY_FOLDER = async (folderName) => {
        const params = {
            Bucket: S3_BUCKET,
            Key: folderName + "/",
            Body: ''
        };

        return await new Promise(function (resolve, reject) {
            return S3_BUCKET_SIGN.putObject(params, function (err, data) { resolve(data); });
        })
    }

    const GET_ALL_IMAGES_BASED_ON_FOLDER = async (folderName) => {
        const params = {
            Bucket: S3_BUCKET,
            Delimiter: '/',
            Prefix: folderName + "/",
        };

        return await new Promise(function (resolve, reject) {
            return S3_BUCKET_SIGN.listObjects(params, function (err, data) { resolve(data); });
        })
    }

    const DELETE_S3_FILE = async (fileName) => {
        const params = {
            Bucket: S3_BUCKET,
            Key: fileName
        };
        return await new Promise(function (resolve, reject) {
            return S3_BUCKET_SIGN.deleteObject(params, function (err, data) { resolve(data); });
        })
    }

    const GET_ALL_IMAGES_PUBLIC_URL = async (folderName) => {
        const finalImagesArr = [];
        const result = await GET_ALL_IMAGES_BASED_ON_FOLDER(folderName)
        const imagesArr = result?.Contents;
        for (let i = 0; i < imagesArr?.length; i++) {
            const publicUrl = await GET_S3_IMAGE(imagesArr[i]["Key"])
            const fullInfo = {
                ...imagesArr[i],
                publicPath: publicUrl
            }
            finalImagesArr?.push(fullInfo)
        }
        return finalImagesArr
    }

    return { GET_S3_IMAGE, CREATE_EMPTY_FOLDER, GET_ALL_IMAGES_BASED_ON_FOLDER, GET_ALL_IMAGES_PUBLIC_URL, DELETE_S3_FILE }
}

export default S3_FILE_HELPER