import { OPEN_CHANGE_ORDER_MODAL, OPEN_EDIT_BUDGET_TRACKING_MODAL, OPEN_INVOICE_MODAL, OPEN_PURCHASE_ORDER_MODAL, SET_BUDGET_TRACKING_CUSTOM_FILTERS, SET_BUDGET_TRACKING_LIST_DATA, SET_BUDGET_TRACKING_TOGGLE_FILTER_SLIDER, SET_FILTERED_BUDGET_TRACKING_LIST_DATA } from "../action-types/budgetTrackingAction.types"

const initialState = {
    listData: [], // original without modified list data array
    filteredListResult: [], // after filter apply list data result
    customFilters: {}, //selected custom filter 
    toggleFilterSlider: false, // open and hide filter slider
    editBudgetTrackingModal: {},
    editPurchaseOrderModal: {},
    editChangeOrderModal: {},
    editInvoiceModal: {}
}

const budgetTrackingReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_BUDGET_TRACKING_LIST_DATA:
            return {
                ...state,
                listData: action.data
            }
        case SET_FILTERED_BUDGET_TRACKING_LIST_DATA:
            return {
                ...state,
                filteredListResult: action.data
            }

        case SET_BUDGET_TRACKING_CUSTOM_FILTERS:
            return {
                ...state,
                customFilters: action.customFilters
            }

        case SET_BUDGET_TRACKING_TOGGLE_FILTER_SLIDER:
            return {
                ...state,
                toggleFilterSlider: action?.isActive
            }
        case OPEN_EDIT_BUDGET_TRACKING_MODAL:
            return {
                ...state,
                editBudgetTrackingModal: action?.objInfo
            }
        case OPEN_PURCHASE_ORDER_MODAL:
            return {
                ...state,
                editPurchaseOrderModal: action?.objInfo
            }
        case OPEN_CHANGE_ORDER_MODAL:
            return {
                ...state,
                editChangeOrderModal: action?.objInfo
            }
        case OPEN_INVOICE_MODAL:
            return {
                ...state,
                editInvoiceModal: action?.objInfo
            }

        default:
            return state
    }

}

export default budgetTrackingReducer