import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Chip } from "@material-ui/core";
import { filter } from 'jszip';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

// this will be used for Multiple select with Search functionality
export default function CheckboxesTags({ limitTags = 2, placeHolder, optionsList, onChange, selectedOptions }) {

    const checkOption = (option) => {
        const check = selectedOptions.some((c) => c.value === option.value);
        return check;
    };

    const handleChange = (event, values) => {
        onChange(event, values)
    };

    const handleCheckBoxClick = (option) => {
        const isExist = selectedOptions?.some((key) => key?.value === option?.value)
        if (!!isExist) {
            const removed = selectedOptions?.filter((key) => key?.value !== option?.value)
            onChange(null, removed)
        } else {
            const merged = [...selectedOptions, option]
            onChange(null, merged)
        }
    }

    return (
        <Autocomplete
            multiple
            onChange={handleChange}
            limitTags={limitTags}
            id={placeHolder + "Select"}
            options={optionsList}
            disableCloseOnSelect
            value={selectedOptions}
            getOptionLabel={(option) => option.label}
            getOptionSelected={(option, value) => option.value == value.value}
            renderOption={(props, option, { selected }) => {
                return (
                    <li {...props}>
                        <Checkbox
                            onChange={() => handleCheckBoxClick(option)}
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={checkOption(option)}
                            {...props}
                        />
                        {option?.label}
                    </li>
                )
            }}
            renderInput={(params) => (
                <TextField {...params} label={placeHolder} placeholder={placeHolder} />
            )}
        />
    );
}

