import React, { useContext } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { ConfigContext } from '../../../../contexts/ConfigContext';
import * as actionType from '../../../../store/actions';

const Layout = () => {
  const configContext = useContext(ConfigContext);
  const { layoutType } = configContext.state;
  const { dispatch } = configContext;

  return (
    <React.Fragment>
      <h6>Layouts</h6>
      <div className="theme-color layout-type">
        <OverlayTrigger overlay={<Tooltip>Default Layout</Tooltip>}>
          <Link
            to="#"
            onClick={() =>
              dispatch({
                type: actionType.LAYOUT_TYPE,
                layoutType: 'menu-dark',
                headerBackColor: 'header-default',
                navBackColor: 'navbar-default',
                navBrandColor: 'brand-default'
              })
            }

            className={layoutType === 'menu-dark' ? 'active' : ''}
            data-value="menu-dark"
          >
            <span />
            <span />
          </Link>
        </OverlayTrigger>
        <OverlayTrigger overlay={<Tooltip>Light</Tooltip>}>
          <Link
            to="#"
            onClick={() =>
              dispatch({
                type: actionType.LAYOUT_TYPE,
                layoutType: 'menu-light',
                headerBackColor: 'header-default',
                navBackColor: 'navbar-default',
                navBrandColor: 'brand-default'
              })
            }

            className={layoutType === 'menu-light' ? 'active' : ''}
            data-value="menu-light"
          >
            <span />
            <span />
          </Link>
        </OverlayTrigger>
        <OverlayTrigger overlay={<Tooltip>Dark</Tooltip>}>
          <Link
            to="#"
            onClick={() =>
              dispatch({
                type: actionType.LAYOUT_TYPE,
                layoutType: 'dark',
                headerBackColor: 'header-dark',
                navBackColor: 'navbar-dark',
                navBrandColor: 'brand-dark'
              })
            }

            className={layoutType === 'dark' ? 'active' : ''}
            data-value="dark"
          >
            <span />
            <span />
          </Link>
        </OverlayTrigger>
        <OverlayTrigger overlay={<Tooltip>Reset</Tooltip>}>
          <Link
            to="#"
            onClick={() => dispatch({ type: actionType.RESET })}
            className={layoutType === 'reset' ? 'active' : ''}
            data-value="reset"
          >
            Reset to Default
          </Link>
        </OverlayTrigger>
      </div>
    </React.Fragment>
  );
};

export default Layout;
