export const SET_FIELD_COLUMNS_OPTIONS_LIST = "SET_FIELD_COLUMNS_OPTIONS_LIST"
export const SET_DATA_GRID_LIST_DATA = "SET_DATA_GRID_LIST_DATA"
export const SET_FILTERED_DATA_GRID_LIST_DATA = "SET_FILTERED_DATA_GRID_LIST_DATA"
export const SET_DATA_GRID_FILTER_STATE = "SET_DATA_GRID_FILTER_STATE"
export const SET_DATA_GRID_CUSTOM_FILTERS = "SET_DATA_GRID_CUSTOM_FILTERS"
export const SET_DATA_GRID_ADVANCED_FILTERS = "SET_DATA_GRID_ADVANCED_FILTERS"
export const SET_DATA_GRID_TOGGLE_FILTER_SLIDER = "SET_DATA_GRID_TOGGLE_FILTER_SLIDER"
export const SET_DATA_GRID_USER_FILTERED_LIST = "SET_DATA_GRID_USER_FILTERED_LIST"
export const SET_GRID_ACTIVE_USER_FILTER_DROPDOWN = "SET_GRID_ACTIVE_USER_FILTER_DROPDOWN"
export const EXCEL_EXPORT_DATA_GRID = "EXCEL_EXPORT_DATA_GRID"
export const MULTIPLE_EDIT_ENABLE_DATA_GRID = "MULTIPLE_EDIT_ENABLE_DATA_GRID"
export const RELOAD_API_DATA_GRID = "RELOAD_API_DATA_GRID"
export const SET_SAVED_SCHEMA_BY_USER = "SET_SAVED_SCHEMA_BY_USER"
export const SET_SCHEMA_DUAL_LIST_ORDER_MODAL = "SET_SCHEMA_DUAL_LIST_ORDER_MODAL"
export const SET_SCHEMA_DUAL_LIST_FILTER_MODAL = "SET_SCHEMA_DUAL_LIST_FILTER_MODAL"
export const SET_VIEWABLE_LIST_COLUMNS = "SET_VIEWABLE_LIST_COLUMNS" // list result to show the columns